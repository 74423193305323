import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";
import { PvLayerWMTS } from "./wmts";

export const IsLayerAerial = (
  layer: PlanViewerLayerDetails
): layer is PvLayerAerial => {
  return layer.type === "aerial";
};

export class PvLayerAerial extends PvLayer implements IPvLayerMap {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    if (!options.opacity) {
      options.opacity = 0.7;
    }
    const wmts_url = "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0";
    const wmts_layer_name = "2018_ortho25";
    const ol = PvLayerWMTS.wrap(this, wmts_url, wmts_layer_name, options);
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
