import { Ajax } from "../core/ajax";
import {
  AjaxResponse,
  IAjaxError,
  IAjaxOk,
  isAjaxError,
  isAjaxOk,
  isAjaxOkAny,
  isAjaxOkArray,
  isAjaxOkString,
  JsonArrayT,
  OkKey,
} from "../core/ajaxresponse";
import { Config } from "./config";
import { Bus } from "../core/bus";
import _ from "lodash";
import { MultiPolygon, Polygon } from "ol/geom";
import {
  getCoordinatesFromPolygonEwkt,
  getGeometryFromWkt,
  getGeometryFromWktWithoutReprojection,
  getPlanviewerGeometryFromGeometry,
  wktFromGeometry,
} from "./gis";
import { OauthTokenInfo } from "./oauth";
import { PvLayerTypeDescT } from "./layers/pvlayer";

/*
The methods are taken from the document that was provided by ICT Business Partners
*/

const BASE_URL = Config.MAPS_API_BASE_URL + "server/viewers";
const OPS_BASE_URL = Config.MAPS_API_BASE_URL + "ops";
const HEADERS: Record<string, string> = {};

if (
  Config.BASIC_AUTH_USER !== undefined &&
  Config.BASIC_AUTH_PASS !== undefined
) {
  HEADERS["Authorization"] =
    "Basic " + btoa(Config.BASIC_AUTH_USER + ":" + Config.BASIC_AUTH_PASS);
}

export type ViewerIdentifierType = string;
export type LayerIdentifierType = number;
export type OutlineType = Polygon | MultiPolygon;
export type FeatureIdentifierType = string;

export const FUNCTION_TYPE_KEY = "Functie";

export class PlanViewer {
  protected bus?: Bus;
  private _oauthTokenInfo?: OauthTokenInfo;

  constructor(bus?: Bus) {
    this.bus = bus;
  }

  set oauthTokenInfo(value: OauthTokenInfo) {
    this._oauthTokenInfo = value;
  }

  getHeaders(): Record<string, string> {
    const headers = { ...HEADERS };
    if (this._oauthTokenInfo) {
      headers["Authorization"] = "Bearer " + this._oauthTokenInfo.access_token;
    }
    return headers;
  }

  // noinspection JSMethodCanBeStatic
  protected createEmptyResponse(): AjaxResponse {
    return new AjaxResponse(this.bus ?? Bus.getInstance(), []);
  }

  /**** viewer ****/
  listViewers(
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerViewerDetails[] | undefined> {
    return new Promise<PlanViewerViewerDetails[] | undefined>(
      (resolve, reject) => {
        const response = ajaxResponse ?? this.createEmptyResponse();
        response.add_direct_cb((got) => {
          if (isAjaxOk(got)) {
            resolve(got.data.viewers as PlanViewerViewerDetails[]);
          } else if (isAjaxError(got)) {
            if (got.httpStatus === 404) {
              resolve(undefined);
            } else {
              reject(got.message);
            }
          } else {
            reject();
          }
        });

        const method = "GET";
        const url = `${BASE_URL}`;
        const ajax = new Ajax(url, response, method, this.getHeaders());
        ajax.exec();
      }
    );
  }

  getOutline(
    identifier: ViewerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<Polygon | MultiPolygon | undefined> {
    return new Promise<Polygon | MultiPolygon | undefined>(
      (resolve, reject) => {
        const response = ajaxResponse ?? this.createEmptyResponse();

        response.add_direct_cb((got) => {
          if (isAjaxOkString(got)) {
            const outline = got.data;
            resolve(getGeometryFromWkt(outline) as Polygon);
          } else if (isAjaxError(got)) {
            if (got.httpStatus === 404) {
              resolve(undefined);
            } else {
              reject(got.message);
            }
          } else {
            reject();
          }
        });

        const method = "GET";
        const url = `${BASE_URL}/${identifier}/outline`;
        const headers = { ...this.getHeaders(), Accept: "text/plain" };
        const ajax = new Ajax(url, response, method, headers);
        ajax.exec();
      }
    );
  }

  // this calls viewer_set_outline on the backend
  setOutline(
    identifier: ViewerIdentifierType,
    newOutline: Polygon | MultiPolygon | undefined,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOkAny(got)) {
          resolve();
        } else {
          reject((got as IAjaxError).message);
        }
      });

      if (!newOutline) {
        newOutline = new Polygon([]);
      }

      const geometryType = newOutline.getType().toString();
      const coordinates = getCoordinatesFromPolygonEwkt(
        getPlanviewerGeometryFromGeometry(newOutline)
      );

      const method = "POST";
      const url = `${BASE_URL}/${identifier}/set_outline`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec({
        type: geometryType,
        coordinates,
      });
    });
  }
  search(
    identifier: ViewerIdentifierType,
    data: Record<string /*'q'|'limit'|'within_outline'*/, string>,
    ajaxResponse?: AjaxResponse
  ): Promise<IFoundAddress[]> {
    return new Promise<IFoundAddress[]>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();
      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve(parseSearchResponse(got));
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "GET";
      const params = [];
      for (const key in data) {
        params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        );
      }
      let url = `${Config.MAPS_API_BASE_URL}embed/search/${identifier}`;
      if (params.length > 0) {
        url = `${url}?${params.join("&")}`;
      }
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec();
    });
  }

  /**** layer ****/

  // this calls layer_list_or_create on the backend
  getLayersAndCustomerBaseLayers(
    identifier: ViewerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerDetails[]> {
    return new Promise<PlanViewerLayerDetails[]>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve(parseGetLayersResponse(got as IAjaxOk));
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "GET";
      const url = `${BASE_URL}/${identifier}/all_layers`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec();
    });
  }

  getLayerDetails(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerDetails> {
    return new Promise<PlanViewerLayerDetails>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve((got.data as unknown) as PlanViewerLayerDetails);
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "GET";
      const url = `${BASE_URL}/${identifier}/layers/${layerId}`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec();
    });
  }

  // List all properties and geometry of a vector layer
  getLayerProperties(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerProperty[]> {
    return new Promise<PlanViewerLayerProperty[]>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOkArray(got)) {
          resolve(parseGetLayerPropertiesResponse(got));
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "GET";
      const url = `${BASE_URL}/${identifier}/layers/${layerId}/get_properties`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec();
    });
  }

  getLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    featureId: FeatureIdentifierType
  ): Promise<PlanViewerLayerProperty> {
    return new Promise<PlanViewerLayerProperty>((resolve, reject) => {
      this.getLayerProperties(identifier, layerId).then((properties) => {
        const property_ = _.find(
          properties,
          (obj) => obj.id.toString() === featureId.toString()
        );
        if (property_) {
          resolve(property_);
        } else {
          reject("feature not found");
        }
      });
    });
  }

  // Add feature info and a geometry to a vector layer
  addLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    geometry: string,
    properties: Record<string, unknown>,
    ajaxResponse?: AjaxResponse
  ): Promise<FeatureIdentifierType | undefined> {
    return new Promise<FeatureIdentifierType | undefined>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          const features = got.data["features"] as JsonArrayT | undefined;
          const feature = features?.[0];
          const featureId = feature?.id as FeatureIdentifierType | undefined;
          resolve(featureId);
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "POST";
      const url = `${BASE_URL}/${identifier}/layers/${layerId}/set_feature`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      const data = {
        geometry: geometry,
        properties: properties,
      };
      ajax.exec(data);
    }).then((featureId?) => {
      return featureId ?? this.guessAddedFeatureId(identifier, layerId);
    });
  }

  guessAddedFeatureId(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType
  ): Promise<FeatureIdentifierType> {
    return new Promise<FeatureIdentifierType>((resolve, reject) => {
      this.getLayerProperties(identifier, layerId)
        .then((features) => {
          const featureIds = features.map((f) => f.id).sort();
          const newestFeatureId = (_.last(featureIds) as
            | number
            | string).toString();
          resolve(newestFeatureId);
        })
        .catch((reason) => reject(reason));
    });
  }

  removeLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    featureId: unknown,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve();
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "DELETE";
      const url = `${BASE_URL}/${identifier}/layers/${layerId}/delete_properties/${featureId}`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      ajax.exec();
    });
  }

  // this calls layer_list_or_create on the backend
  createFeatureLayer(
    identifier: ViewerIdentifierType,
    data: any,
    ajaxResponse: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const method = "POST";
    const url = `${BASE_URL}/${identifier}/layers`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec(data);
  }

  // this calls layer_list_or_create on the backend
  createReferenceOutlineLayer(
    identifier: ViewerIdentifierType,
    data: any,
    ajaxResponse: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const method = "POST";
    const url = `${BASE_URL}/${identifier}/layers`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec(data);
  }

  // this calls layer_list_or_create on the backend
  createReferenceProxyLayer(
    identifier: ViewerIdentifierType,
    data: any,
    ajaxResponse: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const method = "POST";
    const url = `${BASE_URL}/${identifier}/layers`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec(data);
  }

  // this calls layer_get_feature_data on the backend
  refreshGmlWithUserFeatures(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    ajaxResponse: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const method = "GET";
    const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/features`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec();
  }

  /**** feature ****/
  getFeatureList(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    data: any,
    ajaxResponse?: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const response = ajaxResponse ?? this.createEmptyResponse();
    const method = "GET";
    const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/get_properties`;
    const ajax = new Ajax(url, response, method, this.getHeaders());
    ajax.exec(data);
  }

  updateFeature(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    featureId: FeatureIdentifierType,
    geometry: string,
    properties: Record<string, unknown>,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();

      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve();
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "POST";
      const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/update_feature`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      const data = {
        featureId: featureId,
        geometry: geometry,
        properties: properties,
      };
      ajax.exec(data);
    });
  }

  importFeaturesFromFile(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    filename: string,
    check_crs: boolean,
    b64EncodedBytes: string,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const response = ajaxResponse ?? this.createEmptyResponse();
      response.add_direct_cb((got) => {
        if (isAjaxOk(got)) {
          resolve();
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "POST"; //GET is not ready, it could be used to retrieve the progress of the upload
      const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/import.gml`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      const data = { file: { name: filename, content: b64EncodedBytes }, check_crs: check_crs };
      ajax.exec(data);
    });
  }

  /**** field maping ****/

  // this calls field_list_or_add_mappings on the backend
  listFieldMapping(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    ajaxResponse: AjaxResponse
  ): void {
    const method = "GET";
    const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/mappings`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec();
  }

  createFieldMapping(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    data: any,
    ajaxResponse: AjaxResponse
  ): void {
    throw new Error("Not implemented");
    const method = "POST";
    const url = `${BASE_URL}/${identifier}/layers/${layerNumber}/mappings`;
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec(data);
  }

  opsValidatePolygon(polygon: Polygon | MultiPolygon): Promise<OutlineType> {
    const empty = new Polygon([[[0,0],[0,0],[0,0],[0,0],[0,0]]]) // prettier-ignore
    return this.opsPolygonUnion([polygon, polygon])
  }

  opsPolygonUnion(polygons: (Polygon | MultiPolygon)[]): Promise<OutlineType> {
    return new Promise<OutlineType>((resolve, reject) => {
      const response = this.createEmptyResponse();
      response.add_direct_cb((got) => {
        if (isAjaxOkString(got)) {
          resolve(
            getGeometryFromWktWithoutReprojection(got.data) as OutlineType
          );
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "POST";
      const url = `${OPS_BASE_URL}/polygon_union/`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      const data = { polygons: polygons.map((p) => wktFromGeometry(p)) };
      ajax.exec(data);
    });
  }

  opsPolygonSubtract(
    polygons: (Polygon | MultiPolygon)[]
  ): Promise<OutlineType> {
    return new Promise<OutlineType>((resolve, reject) => {
      const response = this.createEmptyResponse();
      response.add_direct_cb((got) => {
        if (isAjaxOkString(got)) {
          resolve(getGeometryFromWktWithoutReprojection(got.data) as Polygon);
        } else {
          reject((got as IAjaxError).message);
        }
      });

      const method = "POST";
      const url = `${OPS_BASE_URL}/polygon_subtract/`;
      const ajax = new Ajax(url, response, method, this.getHeaders());
      const data = { polygons: polygons.map((p) => wktFromGeometry(p)) };
      ajax.exec(data);
    });
  }
}

export interface PlanViewerViewerDetails {
  identifier: ViewerIdentifierType;
  name: string;
  created_at: string;
  updated_at: string;
  outline?: string; //this is only set when the layers are collected and it is a outline layer
}

export interface PlanViewerLayerDetails {
  viewer: PlanViewerViewerDetails;
  id: LayerIdentifierType;
  name: string;
  type: PvLayerTypeDescT;
  base: boolean;
  consultable: boolean;
  show_layer: boolean;
  sort_order: number;
  filter_fields: unknown[];
  translations: unknown[];
  // "vector_source": {
  //   "has_sld": false,
  //   "type": "polygon",
  //   "uploadable": false,
  //   "drawable": false,
  // },
  created_at: string;
  updated_at: string;
  self_url: string;
  resolved?: PlanViewerLayerDetails | PlanViewerViewerDetails | null; // when the type=proxy and proxy_target_layer is valid
}

export interface PlanViewerLayerProperty {
  id: FeatureIdentifierType;
  geometry: string;
  properties: Record<string, unknown>;
}

export interface IFoundAddress {
  name: string;
  type: string;
  geometry: string;
}

export function parseSearchResponse(response: IAjaxOk): IFoundAddress[] {
  const data = response.data as Record<string, unknown>;
  return data.search as IFoundAddress[];
}

export function parseGetLayersResponse(
  response: IAjaxOk
): PlanViewerLayerDetails[] {
  const data = response.data as Record<string, unknown>;
  return data.layers as PlanViewerLayerDetails[];
}

export function parseGetLayerPropertiesResponse(
  response: IAjaxOk<JsonArrayT>
): PlanViewerLayerProperty[] {
  const properties = (response.data as unknown) as PlanViewerLayerProperty[];

  return _.map(properties, (property_) => {
    if (typeof property_.properties === "string") {
      property_.properties = JSON.parse(property_.properties);
    }

    return property_;
  });
}

// TODO deprecated
class InMemoryLayer {
  layer: PlanViewerLayerDetails;
  features: PlanViewerLayerProperty[];

  constructor(viewer: PlanViewerViewerDetails, id: LayerIdentifierType) {
    this.layer = {
      viewer,
      id: id,
      name: "osm",
      type: "osm",
      base: true,
      show_layer: true,
      consultable: true,
      sort_order: 1,
      filter_fields: [],
      translations: [],
      created_at: "",
      updated_at: "",
      self_url: "",
    };

    this.features = [];
  }
}

// TODO deprecated
export class InMemoryPlanViewer extends PlanViewer {
  private layers: Record<ViewerIdentifierType, Record<LayerIdentifierType, InMemoryLayer>>; // prettier-ignore
  private outlines: Record<ViewerIdentifierType, Polygon | MultiPolygon>;
  private static nextUniqueLayerId: LayerIdentifierType;
  private static nextUniqueFeatureId: LayerIdentifierType;

  static initialize(): void {
    InMemoryPlanViewer.nextUniqueLayerId = 1;
    InMemoryPlanViewer.nextUniqueFeatureId = 1;
  }

  constructor(bus?: Bus) {
    super(bus);

    const viewerId = Config.VIEWER_IDENTIFIER ?? "";
    const viewer = {
      identifier: viewerId,
      name: "dummy",
      created_at: "then",
      updated_at: "just now",
    };

    this.layers = {};
    this.layers[viewerId] = {};

    const base1 = new InMemoryLayer(
      viewer,
      InMemoryPlanViewer.nextUniqueLayerId++
    );
    base1.layer.base = true;
    base1.layer.name = "OSM";
    const base2 = new InMemoryLayer(
      viewer,
      InMemoryPlanViewer.nextUniqueLayerId++
    );
    base2.layer.base = true;
    base2.layer.name = "OpenTopo";
    const editable = new InMemoryLayer(
      viewer,
      InMemoryPlanViewer.nextUniqueLayerId++
    );
    editable.layer.base = false;
    editable.layer.name = "Functiegebieden";

    const feat1: PlanViewerLayerProperty = {
      id: (InMemoryPlanViewer.nextUniqueFeatureId++).toString(),
      geometry: "SRID=28992;POLYGON((91886.98346116832 438725.2943677891,91924.35734824932 438733.83922978275,91916.43072619519 438763.5653739255,91863.78215283867 438757.97375548386,91882.04032563666 438728.3010135819,91886.98346116832 438725.2943677891))", // prettier-ignore
      properties: {
        [FUNCTION_TYPE_KEY]: "grasveld",
      },
    };

    const feat2: PlanViewerLayerProperty = {
      id: (InMemoryPlanViewer.nextUniqueFeatureId++).toString(),
      geometry: "SRID=28992;POLYGON((91924.35740763413 438733.8396599778,91958.13130247536 438734.8852116793,91959.44929397845 438780.8717542848,91916.43078557315 438763.5658041425,91924.35740763413 438733.8396599778)) stratopomapviewer.tsx:150:14", // prettier-ignore
      properties: {
        [FUNCTION_TYPE_KEY]: "woonwijk",
      },
    };

    const feat3: PlanViewerLayerProperty = {
      id: (InMemoryPlanViewer.nextUniqueFeatureId++).toString(),
      geometry: "SRID=28992;POLYGON((91891.4577944663 438714.3810346514,91928.1334011993 438726.0629994836,91967.17773549908 438720.78606551606,91972.65806243502 438687.2262895261,91926.20008152738 438660.57803362235,91882.13454182893 438677.3277332104,91875.5991924202 438714.94933366927,91891.4577944663 438714.3810346514))", // prettier-ignore
      properties: {
        [FUNCTION_TYPE_KEY]: "winkels",
      },
    };

    editable.features.push(feat1);
    editable.features.push(feat2);
    editable.features.push(feat3);

    this.layers[viewerId][base1.layer.id] = base1; // prettier-ignore
    this.layers[viewerId][base2.layer.id] = base2; // prettier-ignore
    this.layers[viewerId][editable.layer.id] = editable; // prettier-ignore
    this.outlines = {};
    this.outlines[viewerId] = getGeometryFromWkt("SRID=28992;Polygon ((91856.54180581342370715 438766.69521589647047222, 91921.96118282392853871 438790.20344937755726278, 91986.31200376711785793 438787.35396653134375811, 91993.07952552681672387 438723.24060249200556427, 91996.87883598840562627 438666.25094556814292446, 91924.69193721820192877 438653.90318656800081953, 91866.51499577509821393 438664.11383343354100361, 91857.25417652497708332 438713.02995562646538019, 91856.54180581342370715 438766.69521589647047222))") as Polygon; // prettier-ignore
  }

  getOutline(
    identifier: ViewerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<Polygon | MultiPolygon> {
    return new Promise<Polygon | MultiPolygon>((resolve) => {
      const got = { status: OkKey, data: "" } as IAjaxOk<string>;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);
      resolve(this.outlines[identifier]);
    });
  }

  setOutline(
    identifier: ViewerIdentifierType,
    data: Polygon | MultiPolygon,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.outlines[identifier] = data;
      const got = { status: OkKey, data: {} } as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);
      resolve();
    });
  }

  getLayersAndCustomerBaseLayers(
    identifier: ViewerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerDetails[]> {
    return new Promise<PlanViewerLayerDetails[]>((resolve) => {
      const layers = _.map(this.layers[identifier], (value) => value.layer);
      const got = ({ status: OkKey, data: layers } as unknown) as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);
      resolve(layers);
    });
  }

  getLayerDetails(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerDetails> {
    return new Promise<PlanViewerLayerDetails>((resolve) => {
      const layer = this.layers[identifier][layerId]
        .layer as PlanViewerLayerDetails;

      const got = ({ status: OkKey, data: layer } as unknown) as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);
      resolve(layer);
    });
  }

  getLayerProperties(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<PlanViewerLayerProperty[]> {
    return new Promise<PlanViewerLayerProperty[]>((resolve, reject) => {
      const layer = this.layers[identifier]?.[layerId];

      if (layer) {
        const properties = [...this.layers[identifier][layerId].features];
        const got = ({ status: OkKey, data: properties } as unknown) as IAjaxOk;
        ajaxResponse?.bus_cb(got);
        ajaxResponse?.direct_cb(got);
        resolve(properties);
      } else {
        reject("viewer or layer not found");
      }
    });
  }

  getLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    featureId: FeatureIdentifierType
  ): Promise<PlanViewerLayerProperty> {
    return new Promise<PlanViewerLayerProperty>((resolve, reject) => {
      const property_ = _.find(
        this.layers[identifier][layerId].features,
        (obj) => obj.id === featureId
      );
      if (property_) {
        resolve(property_);
      } else {
        reject("feature not found");
      }
    });
  }

  addLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    geometry: string,
    properties: Record<string, unknown>,
    ajaxResponse?: AjaxResponse
  ): Promise<FeatureIdentifierType | undefined> {
    return new Promise<FeatureIdentifierType | undefined>((resolve) => {
      const newFeature: PlanViewerLayerProperty = {
        id: (InMemoryPlanViewer.nextUniqueFeatureId++).toString(),
        geometry: "",
        properties: _.clone(properties),
      };

      this.layers[identifier][layerId].features.push(newFeature);

      const got = { status: OkKey, data: {} } as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);
      resolve(newFeature.id);
    });
  }

  guessAddedFeatureId(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType
  ): Promise<FeatureIdentifierType> {
    return new Promise<FeatureIdentifierType>((resolve, reject) => {
      const featureId = _.last(this.layers[identifier][layerId].features)?.id;
      if (featureId === undefined) {
        reject("no features in layer");
      } else {
        resolve(featureId);
      }
    });
  }

  removeLayerFeature(
    identifier: ViewerIdentifierType,
    layerId: LayerIdentifierType,
    featureId: FeatureIdentifierType,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const index = this.layers[identifier][layerId].features.findIndex(
        (value) => value.id === featureId
      );

      const got = { status: OkKey, data: {} } as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);

      if (index !== -1) {
        this.layers[identifier][layerId].features.splice(index, 1);
        resolve();
      } else {
        reject("feature not found");
      }
    });
  }

  updateFeature(
    identifier: ViewerIdentifierType,
    layerNumber: LayerIdentifierType,
    featureId: FeatureIdentifierType,
    geometry: string,
    properties: Record<string, unknown>,
    ajaxResponse?: AjaxResponse
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const feature = this.layers[identifier][layerNumber].features.find(
        (value) => value.id === featureId
      );

      const got = { status: OkKey, data: {} } as IAjaxOk;
      ajaxResponse?.bus_cb(got);
      ajaxResponse?.direct_cb(got);

      if (feature) {
        feature.geometry = _.clone(geometry);
        feature.properties = _.clone(properties);
        resolve();
      } else {
        reject("feature not found");
      }
    });
  }
}

// TODO deprecated
InMemoryPlanViewer.initialize();
