import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";

export const IsLayerWMS = (
  layer: PlanViewerLayerDetails
): layer is PvLayerWMS => {
  return layer.type === "wms";
};

export class PvLayerWMS extends PvLayer implements IPvLayerMap {
  wms_url: string;
  wms_layer_name: string;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    wms_url: string,
    wms_layer_name: string
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.wms_url = wms_url;
    this.wms_layer_name = wms_layer_name;
  }

  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    const ol = PvLayerWMS.wrap(
      this,
      this.wms_url,
      this.wms_layer_name,
      options
    );
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }

  static wrap(
    layer: PvLayer,
    wms_url: string,
    wms_layer_name: string,
    options: Record<string, unknown>
  ): OlLayerTypeImplT {
    return new TileLayer({
      ...options,
      source: new TileWMS({
        url: wms_url,
        params: {
          LAYERS: wms_layer_name,
          SRS: "EPSG:3857",
          TILED: true,
        },
        crossOrigin: "anonymous",
      }),
    });
  }
}
