import * as SLDReader from "@nieuwlandgeo/sldreader";

import { getPointResolution } from "ol/proj";
import { Coordinate } from "ol/coordinate";
import { Map } from "ol";

import { BASE_URL, Connector } from "../core/connector";
import { AjaxResponse } from "../core/ajaxresponse";
import { Ajax, MethodTypes } from "../core/ajax";
import { OauthTokenInfo } from "../app/oauth";

export const extractStyles = (
  sld: SLDReader.SLDStyledLayerDescriptor,
  map: Map
): SLDReader.SLDOlStyleFn[] => {
  const out: SLDReader.SLDOlStyleFn[] = [];
  const viewProjection = map.getView().getProjection();

  for (const layerName of SLDReader.getLayerNames(sld)) {
    const sldLayer = SLDReader.getLayer(sld, layerName);
    const styleNames = SLDReader.getStyleNames(sldLayer);
    if (Array.isArray(styleNames)) {
      for (const styleName of styleNames) {
        const sldStyle = SLDReader.getStyle(sldLayer, styleName);
        if (sldStyle) {
          for (const featureTypeStyle of sldStyle.featuretypestyles) {
            const olStyle = SLDReader.createOlStyleFunction(featureTypeStyle, {
              // Use the convertResolution option to calculate a more accurate resolution.
              convertResolution: (viewResolution) => {
                const viewCenter = map.getView().getCenter() as Coordinate;
                return getPointResolution(
                  viewProjection,
                  viewResolution,
                  viewCenter
                );
              },
              // If you use point icons with an ExternalGraphic, you have to use imageLoadCallback to
              // to update the vector layer when an image finishes loading.
              // If you do not do this, the image will only become visible after the next pan/zoom of the layer.
              imageLoadedCallback: () => {
                //vectorLayer.changed();
              },
            });
            out.push(olStyle);
          }
        }
      }
    }
  }
  return out;
};

export class CustomerSldConnector extends Connector {
  customerId: number;
  private oauthTokenInfo?: OauthTokenInfo;

  constructor(customerId: number, oauthTokenInfo?: OauthTokenInfo) {
    // the list, read, save/update/del methods have still the id, although
    // for uniformity of the connector API's
    super(`${BASE_URL}/customer/${customerId}/sld`);
    this.customerId = customerId;
    this.oauthTokenInfo = oauthTokenInfo;
  }

  generateUrl(
    variant: "list" | "create" | "read" | "update" | "delete"
  ): [string, MethodTypes] {
    const lookup: Record<typeof variant, MethodTypes> = {
      list: "GET",
      create: "POST",
      read: "GET",
      update: "PATCH",
      delete: "DELETE",
    };
    const method = lookup[variant];
    if (variant === "list") {
      return [this.url, method];
    }

    return [`${this.url}`, method];
  }

  list(_ajaxResponse: AjaxResponse, _filters?: [string, unknown][]): void {
    throw new Error("not implemented on the backend");
  }

  create(props: Record<string, unknown>, ajaxResponse: AjaxResponse): void {
    this.save(this.customerId, props, ajaxResponse);
  }

  readForViewer(id: number, ajaxResponse: AjaxResponse): void {
    // @NOTE: the backend will return a file, not a IAjaxOk
    if (id !== this.customerId) {
      throw new Error("Should not hanppen, the id's should match");
    }

    const [url, method] = this.generateUrl("read");
    const headers = this.getOauthTokenHeaders();
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec();
  }

  read(id: number, ajaxResponse: AjaxResponse): void {
    // @NOTE: the backend will return a file, not a IAjaxOk
    if (id !== this.customerId) {
      throw new Error("Should not hanppen, the id's should match");
    }

    const [url, method] = this.generateUrl("read");
    const headers = this.getHeaders();
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec();
  }

  save(
    id: number | undefined,
    props: Record<string, unknown>,
    ajaxResponse: AjaxResponse
  ): void {
    if (id === undefined) {
      throw new Error("Should not happen, the id should exist");
    } else {
      this.update(id, props, ajaxResponse);
    }
  }

  update(
    id: number,
    props: Record<string, unknown>,
    ajaxResponse: AjaxResponse
  ): void {
    if (id !== this.customerId) {
      throw new Error("Should not happen, the id's should match");
    }
    const [url, method] = this.generateUrl("update");
    const headers = this.getHeaders();
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec(props);
  }

  del(id: number, ajaxResponse: AjaxResponse): void {
    if (id !== this.customerId) {
      throw new Error("Should not hanppen, the id's should match");
    }
    const [url, method] = this.generateUrl("delete");
    const headers = this.getHeaders();
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec();
  }

  private getOauthTokenHeaders(): Record<string, string> {
    const headers: Record<string, string> = {};
    if (this.oauthTokenInfo) {
      headers["Authorization"] = `Bearer ${this.oauthTokenInfo.access_token}`;
    }
    return headers;
  }
}
