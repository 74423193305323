//https://stackoverflow.com/questions/54416318/how-to-make-a-undo-redo-function

export interface UndoItem {
  undo: () => void;
  redo: () => void;
}

export class UndoManager<T extends UndoItem = UndoItem> {
  private items: T[];
  private pointer: number;
  private savePointer: number | undefined;

  constructor() {
    this.items = [];
    this.pointer = -1;
    this.savePointer = -1;

    this.isEmpty = this.isEmpty.bind(this);
    this.push = this.push.bind(this);
    this.removeItemsAfterPointer = this.removeItemsAfterPointer.bind(this);
    this.hasUndo = this.hasUndo.bind(this);
    this.hasRedo = this.hasRedo.bind(this);
    this.getCurrentItem = this.getCurrentItem.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.getAllItems = this.getAllItems.bind(this);
    this.getDoneItems = this.getDoneItems.bind(this);
  }

  push(value: T): void {
    this.removeItemsAfterPointer();
    this.unmarkSavePointerIfPushWillOverwrite();
    this.items.push(value);
    this.pointer++;
  }

  private removeItemsAfterPointer() {
    this.items.splice(this.pointer + 1);
  }

  private unmarkSavePointerIfPushWillOverwrite() {
    if (
      typeof this.savePointer !== "undefined" &&
      this.pointer < this.savePointer
    ) {
      this.savePointer = undefined;
    }
  }

  undo(): T {
    if (!this.hasUndo()) {
      throw "nothing to undo";
    }

    const item = this.getCurrentItem();
    item.undo();
    this.pointer--;
    return item;
  }

  redo(): T {
    if (!this.hasRedo()) {
      throw "nothing to redo";
    }

    this.pointer++;
    const item = this.getCurrentItem();
    item.redo();
    return item;
  }

  hasUndo(): boolean {
    return this.pointer > -1;
  }

  hasRedo(): boolean {
    return this.items.length - 1 > this.pointer;
  }

  isEmpty(): boolean {
    return this.items.length === 0;
  }

  getCurrentItem(): T {
    if (this.isEmpty()) {
      throw "no undo items";
    }

    return this.items[this.pointer];
  }

  getAllItems(): T[] {
    return [...this.items];
  }

  getDoneItems(): T[] {
    const all = this.getAllItems();
    all.splice(this.pointer + 1);
    return all;
  }

  atSavePoint(): boolean {
    return this.pointer === this.savePointer;
  }

  markSavePoint(): void {
    this.savePointer = this.pointer;
  }
}
