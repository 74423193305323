import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
  ViewerIdentifierType,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";
import { PvLayerEmpty } from "./empty";
import { castLayer } from "./layertype";

export const IsLayerProxy = (
  layer: PlanViewerLayerDetails
): layer is PvLayerProxy => {
  return layer.type === "proxy" && layer.resolved !== undefined; // @TODO: add checkif proxy_target_layer is set
};

export class PvLayerProxy extends PvLayer implements IPvLayerMap {
  proxy_target_layer: string;
  resolved: PlanViewerLayerDetails | null;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    proxy_target_layer: string,
    resolved: PlanViewerLayerDetails | null
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.proxy_target_layer = proxy_target_layer;
    this.resolved = resolved;
  }

  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    /* 
    The backend sets this field to None:
     - the layer is not found
     - the layer is in the same viewer
     - the layer is a proxy layer, to avoid recursion
     - the layer belongs to a viewer from another customer
     So when this resolve is set, it is a valid layer.
     */
    const casted =
      this.resolved === null || this.resolved === undefined
        ? new PvLayerEmpty(
            this.viewer,
            this.id,
            this.type,
            this.name,
            this.sort_order,
            this.base,
            this.consultable,
            this.show_layer,
            this.filter_fields,
            this.translations,
            this.created_at,
            this.updated_at,
            this.self_url
          )
        : castLayer(this.resolved);
    return casted.getOlLayer(options);
  }

  get viewer_identifier(): ViewerIdentifierType {
    if (this.resolved === null || this.resolved === undefined) {
      return this.viewer.identifier;
    }
    return this.resolved.viewer.identifier;
  }

  get layer_id(): LayerIdentifierType {
    if (this.resolved === null || this.resolved === undefined) {
      return this.id;
    }
    return this.resolved.id;
  }
}
