import { CHANNELS } from "./bus";
import { IModel } from "./model";
import {
  AjaxResponse,
  ErrorKey,
  IAjaxError,
  IAjaxOk,
  OkKey,
} from "./ajaxresponse";

export const AjaxStarted = "ajaxStarted";
export const AjaxEnded = "ajaxEnded";

export type MethodTypes = "GET" | "POST" | "PATCH" | "DELETE";

export class Ajax {
  private xhr: XMLHttpRequest;
  public method: MethodTypes;
  public url: string;
  public headers: Record<string, string>;
  private ajaxResponse: AjaxResponse;
  private _okStatus: number[];

  constructor(
    url: string,
    ajaxResponse: AjaxResponse,
    method?: MethodTypes,
    headers?: Record<string, string>,
    okStatus?: number[] | number
  ) {
    this.xhr = new XMLHttpRequest();
    this.url = url;
    this.ajaxResponse = ajaxResponse;
    this.method = method ?? "GET";
    this.headers = headers ?? {};
    this._okStatus =
      typeof okStatus === "number" ? [okStatus] : okStatus ?? [200, 201];
    this.xhr.onreadystatechange = this.process.bind(this);
  }

  exec(postdata?: IModel | Record<string, unknown>): void {
    try {
      this.xhr.open(this.method, this.url, true, null, null);
      for (const key in this.headers) {
        this.xhr.setRequestHeader(key, this.headers[key]);
      }
      if (["POST", "PATCH", "PUT"].indexOf(this.method) !== -1) {
        this.xhr.setRequestHeader(
          "Content-type",
          "application/json;charset=UTF-8"
        );
        if (postdata === undefined) {
          this.ajaxResponse.bus.pub([CHANNELS.ajax], { [AjaxStarted]: true });
          this.xhr.send();
        } else {
          this.ajaxResponse.bus.pub([CHANNELS.ajax], { [AjaxStarted]: true });
          this.xhr.send(JSON.stringify(postdata));
        }
      } else {
        if (postdata !== undefined) {
          console.warn("not a POST request; ignoring postdata");
          // @maybe uriencode in the url
        }
        this.ajaxResponse.bus.pub([CHANNELS.ajax], { [AjaxStarted]: true });
        this.xhr.send();
      }
    } catch (err) {
      this.ajaxResponse.bus.pub([CHANNELS.ajax], { [AjaxEnded]: true });
      this.ajaxResponse.bus_cb(
        Ajax.wrapError(
          `could not send the ajax request: ${err}`,
          this.getHttpStatus(),
          this.xhr.responseText
        )
      );
    }
  }

  private process(): void {
    if (this.xhr?.readyState == XMLHttpRequest.DONE) {
      this.ajaxResponse.bus.pub([CHANNELS.ajax], { [AjaxEnded]: true });
      // XMLHttpRequest.DONE == 4
      // TODO handle django rfw ViewSet cases
      if (this.isHttpOkStatus()) {
        try {
          const parsed = JSON.parse(this.xhr.responseText);

          if (
            (parsed.status === OkKey && parsed.data !== undefined) ||
            (parsed.status == ErrorKey && typeof parsed.message === "string")
          ) {
            this.ajaxResponse.bus_cb(parsed);
            this.ajaxResponse.direct_cb(parsed);
          } else if (parsed.status !== ErrorKey) {
            this.ajaxResponse.bus_cb(Ajax.wrapOk(parsed));
            this.ajaxResponse.direct_cb(Ajax.wrapOk(parsed));
          } else {
            this.ajaxResponse.bus_cb(
              Ajax.wrapError(
                "got unexpected data structure",
                this.getHttpStatus(),
                this.xhr.responseText
              )
            );
            this.ajaxResponse.direct_cb(
              Ajax.wrapError(
                parsed,
                this.getHttpStatus(),
                this.xhr.responseText
              )
            );
          }
        } catch (err) {
          if (err instanceof SyntaxError) {
            this.ajaxResponse.bus_cb(Ajax.wrapOkString(this.xhr.responseText));
            this.ajaxResponse.direct_cb(
              Ajax.wrapOkString(this.xhr.responseText)
            );
          } else {
            throw err;
          }
        }
      } else {
        this.ajaxResponse.bus_cb(
          Ajax.wrapError(
            `Invalid server status: ${this.xhr.statusText}`,
            this.getHttpStatus(),
            this.xhr.responseText
          )
        );
        this.ajaxResponse.direct_cb(
          Ajax.wrapError(
            `Invalid server status: ${this.xhr.statusText}`,
            this.getHttpStatus(),
            this.xhr.responseText
          )
        );
      }
    }
  }

  private isHttpOkStatus(): boolean {
    return this._okStatus.indexOf(this.xhr.status) !== -1;
  }

  private getHttpStatus(): number | undefined {
    if (this.xhr.status !== 0) {
      return this.xhr.status;
    }
  }

  static wrapError(
    message: string,
    httpStatus: number | undefined,
    content: string | undefined
  ): IAjaxError {
    return { status: ErrorKey, message, httpStatus, content };
  }

  static wrapOk(data: Record<string, unknown>): IAjaxOk {
    return { status: OkKey, data };
  }

  static wrapOkString(data: string): IAjaxOk<string> {
    return { status: OkKey, data };
  }
}
