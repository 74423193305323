export const Language = {
  English: "English",
  Dutch: "Nederlands",
};

export type LanguageKeyType = string; // @TODO improve this

export const allLanguageKeys = (): string[] => {
  return Object.keys(Language);
};

export const Phrase = {
  Started: {},
  Ended: {},
};

export type PhraseKeyType = string; // @TODO improve this

export const allPhraseKeys = (): string[] => {
  return Object.keys(Phrase);
};

export class Translate {
  private dictionary: Record<PhraseKeyType, Record<LanguageKeyType, string>>;
  public language: LanguageKeyType;
  private ignoreMissingPhraseKeys: boolean;

  constructor(
    dictionary: Record<PhraseKeyType, Record<LanguageKeyType, string>>,
    language?: LanguageKeyType
  ) {
    this.dictionary = dictionary;
    this.language = language || "English";
    this.ignoreMissingPhraseKeys = true;
  }

  go(phraseKey: PhraseKeyType, params?: Record<string, string>): string {
    let out = this.getRawTranslation(phraseKey);

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const pattern = "\\${" + key + "}";
        const regex = new RegExp(pattern, "g");
        const value = params[key];
        out = out.replace(regex, value);
      }
    }
    return out;
  }

  private getRawTranslation(phraseKey: string) {
    try {
      return this.dictionary[phraseKey][this.language];
    } catch (e: unknown) {
      if (e instanceof TypeError) {
        if (!this.ignoreMissingPhraseKeys) {
          throw e;
        }
      } else {
        throw e;
      }
    }
    console.warn(`Missing translation: '${phraseKey}'`);

    return phraseKey as string;
  }

  check(): boolean {
    for (const phrase of allPhraseKeys()) {
      if (this.dictionary[phrase] === undefined) {
        return false;
      }
      for (const lng of allLanguageKeys()) {
        if (this.dictionary[phrase][lng] === undefined) {
          return false;
        }
      }
    }
    return true;
  }
}
