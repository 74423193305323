import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";

export const IsLayerDKKVector = (
  layer: PlanViewerLayerDetails
): layer is PvLayerDKKVector => {
  return layer.type === "dkk_vector";
};

export class PvLayerDKKVector extends PvLayer implements IPvLayerMap {
  getOlLayer(_options: Record<string, unknown>): OlLayerTypeImplT {
    throw new Error(`Not implemented for type ${this.type}`);
  }
}
