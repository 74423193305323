import { Collection, Feature, Map } from "ol";
import { DragBox, Interaction, Modify, Select } from "ol/interaction";
import { never, platformModifierKeyOnly } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { SelectEvent } from "ol/interaction/Select";

import { ISelection } from "../stratopomapviewer";
import { EmbeddedMap } from "../embeddedmap";
import { StyleLike } from "ol/style/Style";
import { ModifyEvent } from "ol/interaction/Modify";
import _ from "lodash";

export interface SelectInteractionConfiguration {
  interactions: Interaction[];
  onDeactivate?: () => void;
}

type cbOnSelectionChangedT = (
  selection: ISelection,
  deselected: Feature<Geometry>[]
) => void;

type cbOnModifyT = (event: ModifyEvent) => void;

type cbUpdateModifyInteractionT = (
  oldModify: Modify,
  newModify: Modify
) => void;

export function addSingleSelectInteraction(
  map: Map,
  selection: ISelection,
  embeddedMap: EmbeddedMap,
  onSelectionChanged?: cbOnSelectionChangedT,
  style?: StyleLike | undefined,
  onModify?: cbOnModifyT,
  updateModifyInteraction?: cbUpdateModifyInteractionT
): SelectInteractionConfiguration {
  const source = selection.source;
  const initialFeatures = _.clone(selection.features);

  // a normal select interaction to handle click
  const select = new Select({
    layers: [selection.layer],
    features: new Collection(initialFeatures),
    multi: false,
    toggleCondition: never,
    style,
  });
  map.addInteraction(select);

  const selectedFeatures = select.getFeatures();

  // a DragBox interaction used to select features by drawing boxes
  const dragBox = new DragBox({
    condition: platformModifierKeyOnly,
  });

  map.addInteraction(dragBox);

  dragBox.on("boxend", function () {
    // features that intersect the box geometry are added to the
    // collection of selected features

    // if the view is not obliquely rotated the box geometry and
    // its extent are equalivalent so intersecting features can
    // be added directly to the collection
    const rotation = map.getView().getRotation();
    const oblique = rotation % (Math.PI / 2) !== 0;
    const candidateFeatures:
      | Feature<Geometry>[]
      | Collection<Feature<Geometry>> = oblique ? [] : selectedFeatures;
    const extent = dragBox.getGeometry().getExtent();
    source.forEachFeatureIntersectingExtent(extent, function (feature) {
      candidateFeatures.push(feature);
    });

    // when the view is obliquely rotated the box extent will
    // exceed its geometry so both the box and the candidate
    // feature geometries are rotated around a common anchor
    // to confirm that, with the box geometry aligned with its
    // extent, the geometries intersect
    if (oblique) {
      const anchor = [0, 0];
      const geometry = dragBox.getGeometry().clone();
      geometry.rotate(-rotation, anchor);
      const extent$1 = geometry.getExtent();
      candidateFeatures.forEach(function (feature) {
        const geometry = feature.getGeometry()?.clone();
        if (geometry) {
          geometry.rotate(-rotation, anchor);
          if (geometry.intersectsExtent(extent$1)) {
            selectedFeatures.push(feature);
          }
        }
      });
    }

    //console.log("selectedFeatures", selectedFeatures);
  });

  // clear selection when drawing a new box and when clicking on the map
  dragBox.on("boxstart", function () {
    selectedFeatures.clear();
  });

  const createModifyInteraction = (features: Collection<Feature>) => {
    const modify = new Modify({ features });
    if (onModify) {
      modify.on("modifyend", onModify);
    }
    return modify;
  };

  let currentModify = createModifyInteraction(
    new Collection<Feature>(_.clone(initialFeatures))
  );

  let currentSelected = new Collection<Feature>(_.clone(initialFeatures));

  select.on("select", (_evt: SelectEvent) => {
    const newSelection = new Collection<Feature>(
      _.clone(select.getFeatures().getArray())
    );
    const deselected = new Collection<Feature>([]);
    _.forEach(currentSelected.getArray(), (feature) => {
      const found = _.find(
        newSelection.getArray(),
        (other) => other.getId() === feature.getId()
      );

      if (found === undefined) {
        deselected.push(feature);
      }
    });
    const curSelection = Object.assign({}, selection, {
      features: newSelection.getArray(),
    });
    onSelectionChanged?.(curSelection, deselected.getArray());
    currentSelected = newSelection;

    const newModify = createModifyInteraction(newSelection);
    updateModifyInteraction?.(currentModify, newModify);
    currentModify = newModify;
  });

  if (initialFeatures.length > 0) {
    select.dispatchEvent("select");
  }

  const onDeactivate = () => {
    const curSelection = Object.assign({}, selection, { features: [] });
    onSelectionChanged?.(curSelection, currentSelected.getArray());
  };

  return {
    interactions: [select, dragBox, currentModify],
    onDeactivate,
  };
}

export function addSingleSelectWithoutModifyInteraction(
  map: Map,
  selection: ISelection,
  embeddedMap: EmbeddedMap,
  onSelectionChanged?: cbOnSelectionChangedT,
  style?: StyleLike | undefined
): SelectInteractionConfiguration {
  const source = selection.source;
  const initialFeatures = _.clone(selection.features);

  // a normal select interaction to handle click
  const select = new Select({
    layers: [selection.layer],
    features: new Collection(initialFeatures),
    multi: false,
    toggleCondition: never,
    style,
  });
  map.addInteraction(select);

  const selectedFeatures = select.getFeatures();

  let currentSelected = new Collection<Feature>(_.clone(initialFeatures));

  select.on("select", (_evt: SelectEvent) => {
    const newSelection = new Collection<Feature>(
      _.clone(select.getFeatures().getArray())
    );
    const deselected = new Collection<Feature>([]);
    _.forEach(currentSelected.getArray(), (feature) => {
      const found = _.find(
        newSelection.getArray(),
        (other) => other.getId() === feature.getId()
      );

      if (found === undefined) {
        deselected.push(feature);
      }
    });
    const curSelection = Object.assign({}, selection, {
      features: newSelection.getArray(),
    });
    onSelectionChanged?.(curSelection, deselected.getArray());
    currentSelected = newSelection;
  });

  if (initialFeatures.length > 0) {
    select.dispatchEvent("select");
  }

  const onDeactivate = () => {
    const curSelection = Object.assign({}, selection, { features: [] });
    onSelectionChanged?.(curSelection, currentSelected.getArray());
  };

  return {
    interactions: [select],
    onDeactivate,
  };
}
