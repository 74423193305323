import { Connector } from "./connector";
import { AjaxResponse } from "./ajaxresponse";

export interface IModel {
  pk: number | undefined;
  className: string;
  set_key(key: string, value: unknown): void;
  get_key(key: string): unknown;
  props: Record<string, unknown>;

  update(props: Record<string, unknown>, ajaxResponse: AjaxResponse): void;
  del(ajaxResponse: AjaxResponse): void;
  save(ajaxResponse: AjaxResponse): void;
}

export class Model implements IModel {
  protected connector: Connector;
  private pkField: string;
  private _className: string;
  private fields: string[];

  constructor(
    connector: Connector,
    pkField: string,
    className: string,
    fields: string[]
  ) {
    this.connector = connector;
    this.pkField = pkField;
    this._className = className;
    this.fields = fields;
  }

  get pk(): number | undefined {
    return this.get_key(this.pkField) as number | undefined;
  }

  get className(): string {
    return this._className;
  }

  set_key(key: string, value: unknown): void {
    if (key == this.pkField) {
      return;
    }
    if (this.fields.indexOf(key) !== -1) {
      (this as Record<string, unknown>)[key] = value;
    }
  }

  get_key(key: string): unknown {
    return (this as Record<string, unknown>)[key];
  }

  get props(): Record<string, unknown> {
    const out: Record<string, unknown> = {};
    for (const key of this.fields) {
      if (key in this) {
        out[key] = (this as Record<string, unknown>)[key];
      }
    }
    return out;
  }

  update(props: Record<string, unknown>, ajaxResponse: AjaxResponse): void {
    for (const key of this.fields) {
      this.set_key(key, props[key]);
    }
    if (this.pk === undefined) {
      return;
    }
    const conn = this.connector;
    conn.update(this.pk, this.props, ajaxResponse);
  }

  del(ajaxResponse: AjaxResponse): void {
    if (this.pk === undefined) {
      return;
    }
    const conn = this.connector;
    conn.del(this.pk, ajaxResponse);
  }

  save(ajaxResponse: AjaxResponse): void {
    if (this.pk === undefined) {
      return;
    }
    const conn = this.connector;
    conn.update(this.pk, this.props, ajaxResponse);
  }
}
