import { Tile as TileLayer } from "ol/layer";
import { OSM } from "ol/source";

import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";

export const IsLayerOSM = (
  layer: PlanViewerLayerDetails
): layer is PvLayerOSM => {
  return layer.type === "osm";
};

export class PvLayerOSM extends PvLayer implements IPvLayerMap {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    if (!options.opacity) {
      options.opacity = 0.7;
    }
    const ol = new TileLayer({
      ...options,
      source: new OSM({ crossOrigin: "anonymous" }),
    });
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
