import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";
import { PvLayerWFS } from "./wfs";

export const IsLayerBAG = (
  layer: PlanViewerLayerDetails
): layer is PvLayerBAG => {
  return layer.type === "bag";
};

export class PvLayerBAG extends PvLayer implements IPvLayerMap {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    if (!options.style) {
      options.style = new Style({ stroke: new Stroke({ color: "blue" }) });
    }
    const wfs_url = "https://geodata.nationaalgeoregister.nl/bag/wfs/v1_1";
    const wfs_layer_name = "bag:pand";
    const ol = PvLayerWFS.wrap(this, wfs_url, wfs_layer_name, options);
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
