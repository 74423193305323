import { Collection, Feature, Map } from "ol";
import { Vector as VectorSource } from "ol/source";
import { Modify } from "ol/interaction";

import { EmbeddedMap } from "../embeddedmap";

export function addModifyPolygonInteraction(
  map: Map,
  feature: Feature,
  _embeddedMap: EmbeddedMap
): Modify {
  const interaction = new Modify({
    features: new Collection([feature]),
  });

  map.addInteraction(interaction);

  return interaction;
}
