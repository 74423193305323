import * as React from "react";
import * as ReactDOM from "react-dom";

import _ from "lodash";

import { Vector as VectorSource } from "ol/source";
import { Collection, Feature, Map, MapBrowserEvent } from "ol";
import Style, { StyleLike } from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import { Coordinate } from "ol/coordinate";
import { Geometry, MultiPolygon, Polygon } from "ol/geom";
import { VectorSourceEvent } from "ol/source/Vector";
import {
  buffer,
  createEmpty,
  extend,
  Extent,
  getCenter,
  getHeight,
  getWidth,
} from "ol/extent";
import { ModifyEvent } from "ol/interaction/Modify";
import View, { ViewOptions } from "ol/View";
import {
  defaults as defaultInteractions,
  DragRotateAndZoom,
  Interaction,
  Modify,
  Snap,
} from "ol/interaction";
import { OverviewMap, ScaleLine } from "ol/control";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";

import {
  LayerIdentifierType,
  PlanViewer,
  PlanViewerLayerDetails,
  PlanViewerLayerProperty,
} from "./planviewer";
import { addMeasureInteraction } from "./interactions/measure";
import { addDeleteInteraction } from "./interactions/delete";
import { addDrawPolygonInteraction } from "./interactions/drawpolygon";
import { addSingleSelectWithoutModifyInteraction } from "./interactions/select";
import {
  isOutlineLayer,
  isProxyLayer,
  isVectorLayer,
  OlLayerTypeImplT,
} from "./layers/pvlayer";
import { castLayer, PvLayerImplT } from "./layers/layertype";
import { activateModal } from "./modals/Modal";
import { OutlineConst, ShowLayers } from "./modals/ShowLayers";
import { RenderTutorial } from "./modals/RenderTutorial";
import { ShowSearch } from "./modals/ShowSearch";
import { ShowUpload } from "./modals/ShowUpload";
import { ShowPrint } from "./modals/ShowPrint";
import {
  EDIT_LAYER_MODE,
  EDIT_OUTLINE_MODE,
  ISelection,
  SNAPSHOT_MODE,
  StratopoMapViewer,
} from "./stratopomapviewer";
import { netherlands3857 } from "./trivia";
import { addModifyPolygonInteraction } from "./interactions/modifypolygon";
import { UndoManager } from "../core/undoManager";
import { getExtentFromGeometryString, isValidExtent } from "./gis";
import {
  multiselectLine,
  multiselectPolygon,
} from "./interactions/multiselect";
import { EditFeatureController } from "./editFeatureController";
import { fromExtent } from "ol/geom/Polygon";

const LOOKUP_KEY = "lookup_key";

export enum Tool {
  PAN,
  DRAW_POLYGON,
  DELETE,
  MEASURE_LINE,
  MEASURE_AREA,
  SELECT_AND_MODIFY,
  SHOW_LAYERS,
  CENTER_MAP,
  TUTORIAL,
  UNDO,
  SAVE,
  PRINT,
  UPLOAD,
  SEARCH,
  OUTLINE_DRAW_POLYGON_AND_MERGE,
  OUTLINE_DRAW_POLYGON_AND_SUBTRACT,
  OUTLINE_MODIFY_POLYGON,
  OUTLINE_UPLOAD,
  OUTLINE_MULTISELECT_LINE_ADD,
  OUTLINE_MULTISELECT_LINE_SUBTRACT,
  OUTLINE_MULTISELECT_POLYGON_ADD,
  OUTLINE_MULTISELECT_POLYGON_SUBTRACT,
  CREATE_FEATURE,
}

export interface EmbeddedMapConfig {
  overrideShowLayers: boolean;
  initialSnappingEnabled: boolean;
}

export const defaultEmbeddedMapConfig: EmbeddedMapConfig = {
  overrideShowLayers: false,
  initialSnappingEnabled: true,
};

export class EmbeddedMap {
  removePlanViewerLayer(layer: PlanViewerLayerDetails): void {
    console.log("removePlanViewerLayer", layer);
  }
  addPlanViewerLayer(layer: PlanViewerLayerDetails): void {
    console.log("addPlanViewerLayer", layer);
  }
  private target: HTMLElement;
  private map!: Map;
  private editSource?: VectorSource;
  private editLayer?: VectorLayer;
  private outlineSource: VectorSource<Polygon | MultiPolygon>;
  private outlineLayer: VectorLayer;
  private outlineFeature?: Feature<Polygon | MultiPolygon>;
  private outlineDrawSource?: VectorSource;
  private outlineDrawLayer?: VectorLayer;
  private newFeature?: Feature<MultiPolygon>;
  readonly newFeatureSource: VectorSource<MultiPolygon>;
  readonly newFeatureLayer: VectorLayer;
  readonly newFeatureDrawSource: VectorSource<MultiPolygon>;
  readonly newFeatureDrawLayer: VectorLayer;
  private newFeatureController?: EditFeatureController;
  private updateFeatureController?: EditFeatureController;
  private measureLineSource!: VectorSource;
  private measureLineVector!: VectorLayer;
  private measureAreaSource!: VectorSource;
  private measureAreaVector!: VectorLayer;
  // TODO remove temporary source
  private tmpSourceToShowSelectedFeatures!: VectorSource<Polygon | MultiPolygon>; // prettier-ignore
  // TODO remove temporary layer
  private tmpLayerToShowSelectedFeatures!: VectorLayer;
  private customInteractions: Interaction[];
  private outlineUndoManager: UndoManager;
  private outlineBeforeModifying: Polygon | MultiPolygon | undefined;

  private snapOn: boolean;
  private snapInteractions: Snap[];
  private snapLayerNames: string[];

  private extraConfig: EmbeddedMapConfig;

  private initialTool: Tool;
  private activeTool: Tool;
  private activeToolOnDeactivate: (() => void) | undefined;
  private selectedFeatureStyle: StyleLike | undefined;
  public mainmapLayers: OlLayerTypeImplT[];
  private minimapLayers: OlLayerTypeImplT[];
  private multiselectSources: VectorSource<Polygon | MultiPolygon>[];
  private overviewMapControl?: OverviewMap;
  onSelectionChanged?: (
    selection: ISelection,
    deselected: Feature<Geometry>[]
  ) => void;
  onSelectModify?: (features: Feature<Geometry>[]) => void;
  onOutlineChange?: (newOutline: Polygon | MultiPolygon | undefined) => void;
  onSaveOutline?: () => void;
  onNewFeature?: (newFeature: Feature) => any;
  public stratopoMapViewer: StratopoMapViewer;
  public planViewer: PlanViewer;
  public scale?: ScaleLine;

  constructor(
    target: HTMLElement | string,
    outlineLayer: VectorLayer,
    outlineSource: VectorSource<Polygon | MultiPolygon>,
    stratopoMapViewer: StratopoMapViewer,
    initialTool?: Tool,
    extraConfig?: EmbeddedMapConfig
  ) {
    this.target =
      typeof target === "string"
        ? (document.getElementById(target) as HTMLElement)
        : target;
    this.customInteractions = [];

    this.extraConfig = { ...defaultEmbeddedMapConfig, ...(extraConfig ?? {}) };

    this.outlineLayer = outlineLayer;
    this.outlineSource = outlineSource;

    this.newFeatureSource = new VectorSource<MultiPolygon>();
    this.newFeatureLayer = new VectorLayer({ source: this.newFeatureSource });
    this.newFeatureDrawSource = new VectorSource<MultiPolygon>();
    this.newFeatureDrawLayer = new VectorLayer({
      source: this.newFeatureDrawSource,
    });

    this.stratopoMapViewer = stratopoMapViewer;
    this.planViewer = stratopoMapViewer.planviewer;

    this.snapLayerNames = [OutlineConst];
    this.snapInteractions = [];
    this.snapOn = this.extraConfig.initialSnappingEnabled;
    this.initialTool = initialTool ?? Tool.PAN;
    this.activeTool = this.initialTool;

    this.toggleSnapping = this.toggleSnapping.bind(this);
    this.applySnapping = this.applySnapping.bind(this);
    this.onSetLayerSnap = this.onSetLayerSnap.bind(this);
    this.onSetLayerOpacity = this.onSetLayerOpacity.bind(this);
    this.setOutlineLayerStyle = this.setOutlineLayerStyle.bind(this);

    this.activatePan = this.activatePan.bind(this);
    this.activateCenterMap = this.activateCenterMap.bind(this);
    this.activateTutorial = this.activateTutorial.bind(this);
    this.activateSearch = this.activateSearch.bind(this);
    this.activatePrint = this.activatePrint.bind(this);
    this.activateSelect = this.activateSelect.bind(this);
    this.updateModifyInteraction = this.updateModifyInteraction.bind(this);
    this.onModify = this.onModify.bind(this);
    this.activateDrawPolygon = this.activateDrawPolygon.bind(this);
    this.activateOutlineDrawPolygonAndMerge = this.activateOutlineDrawPolygonAndMerge.bind(
      this
    );
    this.activateOutlineDrawPolygonAndSubtract = this.activateOutlineDrawPolygonAndSubtract.bind(
      this
    );
    this.activateOutlineModifyPolygon = this.activateOutlineModifyPolygon.bind(
      this
    );
    this.activateUploadFile = this.activateUploadFile.bind(this);
    this.activateMeasureLine = this.activateMeasureLine.bind(this);
    this.activateMeasureArea = this.activateMeasureArea.bind(this);
    this.activateShowLayers = this.activateShowLayers.bind(this);
    this.activateDelete = this.activateDelete.bind(this);
    this.activateUndo = this.activateUndo.bind(this);
    this.activateSave = this.activateSave.bind(this);
    this.deactivateAllTools = this.deactivateAllTools.bind(this);
    this.removeCustomInteractions = this.removeCustomInteractions.bind(this);
    this.onSetLayerVisible = this.onSetLayerVisible.bind(this);
    this.handleAddFeatureOnOutlineDrawLayer = this.handleAddFeatureOnOutlineDrawLayer.bind(
      this
    );
    this.setOutlineFeature = this.setOutlineFeature.bind(this);
    this.handleNewOutline = this.handleNewOutline.bind(this);
    this.activateOutlineMultiSelectLineAdd = this.activateOutlineMultiSelectLineAdd.bind(
      this
    );
    this.activateOutlineMultiSelectLineSubtract = this.activateOutlineMultiSelectLineSubtract.bind(
      this
    );
    this.activateOutlineMultiSelectPolygonAdd = this.activateOutlineMultiSelectPolygonAdd.bind(
      this
    );
    this.activateOutlineMultiSelectPolygonSubtract = this.activateOutlineMultiSelectPolygonSubtract.bind(
      this
    );
    this.onFeaturesSelectedViaMultiselect = this.onFeaturesSelectedViaMultiselect.bind(
      this
    );
    this.activateCreateFeature = this.activateCreateFeature.bind(this);
    this.toPolygonArray = this.toPolygonArray.bind(this);
    this.zoomIn = this.zoomIn.bind(this); //maybe not needed
    this.zoomOut = this.zoomOut.bind(this); //maybe not needed

    this.mainmapLayers = [];
    this.minimapLayers = [];
    this.multiselectSources = [];

    this.outlineUndoManager = new UndoManager();
  }

  zoomIn() {
    const view = this.map.getView();
    const zoom = view.getZoom() ?? 1;
    view.animate({ zoom: zoom + 1, duration: 250 });
  }

  zoomOut() {
    const view = this.map.getView();
    const zoom = view.getZoom() ?? 1;
    view.animate({ zoom: zoom - 1, duration: 250 });
  }

  setEditableLayerAndSource(layer: VectorLayer, source: VectorSource): void {
    this.editLayer = layer;
    this.editSource = source;
  }

  onSetLayerVisible(
    mapType: "minimap" | "mainmap",
    layerNamey: string,
    active: boolean
  ): void {
    const layers =
      mapType === "minimap" ? this.minimapLayers : this.mainmapLayers;
    for (const layer of layers) {
      if (
        layer.getKeys().indexOf(LOOKUP_KEY) !== -1 &&
        layer.get(LOOKUP_KEY) === layerNamey
      ) {
        (layer as any).setVisible(active);
        (layer as any).set("show_layer", active);
        for (const lay of this.stratopoMapViewer.getLayers()) {
          if (lay.name === layer.get("name")) {
            //or .id === .id ?
            lay.show_layer = active;
            break;
          }
        }
        break;
      }
    }
  }

  onSetLayerOpacity(layerName: string, value: number): void {
    if (value < 0.0 || value > 1.0) {
      return;
    }
    for (const layers of [this.minimapLayers, this.mainmapLayers]) {
      for (const layer of layers) {
        if (
          (layer as any).getKeys().indexOf(LOOKUP_KEY) !== -1 &&
          (layer as any).get(LOOKUP_KEY) === layerName
        ) {
          (layer as any).setOpacity(value);
        }
      }
    }
  }

  embed(): Map {
    this.setOutlineLayerStyle();

    this.outlineDrawSource = new VectorSource();
    this.outlineDrawLayer = new VectorLayer({
      source: this.outlineDrawSource,
      style: new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
          lineDash: [2, 4],
        }),
      }),
    });

    this.outlineDrawSource.on(
      "addfeature",
      this.handleAddFeatureOnOutlineDrawLayer
    );

    this.measureLineSource = new VectorSource({ wrapX: false });

    this.measureLineVector = new VectorLayer({
      source: this.measureLineSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    });

    this.measureAreaSource = new VectorSource({ wrapX: false });

    this.measureAreaVector = new VectorLayer({
      source: this.measureAreaSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    });

    this.tmpSourceToShowSelectedFeatures = new VectorSource<
      Polygon | MultiPolygon
    >({ wrapX: false });
    this.tmpLayerToShowSelectedFeatures = new VectorLayer({
      source: this.tmpSourceToShowSelectedFeatures,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    });

    this.setZIndexForOutlineAndMeasureLayers();

    this.map = new Map({
      target: this.target,
      layers: this.mainmapLayers.concat([
        this.measureLineVector,
        this.measureAreaVector,
        this.tmpLayerToShowSelectedFeatures,
        this.newFeatureLayer,
        this.outlineLayer,
        this.outlineDrawLayer,
      ]),
      controls: [],
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
    });

    const pointerMoveHandler = (evt: MapBrowserEvent) => {
      const mayInteract = this.cursorInteractionAllowed(
        (evt as any).coordinate
      );
      if (mayInteract) {
        this.map.getViewport().style.cursor = "default";
      } else {
        this.map.getViewport().style.cursor = "not-allowed";
      }
    };
    this.map.on("pointermove", pointerMoveHandler);

    const view = this.map.getView();
    view.fit(netherlands3857);
    view.adjustZoom(-0.1);

    this.scale = EmbeddedMap.scaleControl(4, true, "scaleBar", "metric");
    this.map.addControl(this.scale);
    this.addMinimap();

    // Possibly resolves bug
    // https://trello.com/c/0NWdysAq/398-1-ictbp-sometimes-viewer-does-not-load-in-modal-until-after-windowresize
    setTimeout(() => {
      this.map.updateSize();
    }, 250);

    return this.map;
  }

  private setOutlineLayerStyle() {
    // TODO: this should come from the SLD

    if (this.stratopoMapViewer.getMode() === EDIT_OUTLINE_MODE) {
      this.outlineLayer.setStyle(
        new Style({
          stroke: new Stroke({
            color: "black",
            width: 3,
            lineDash: [2, 4],
          }),
          fill: new Fill({
            color: "rgba(62, 79, 179, 0.5)",
          }),
        })
      );
    } else {
      this.outlineLayer.setStyle(
        new Style({
          stroke: new Stroke({
            color: "black",
            width: 2,
            lineDash: [2, 4],
          }),
        })
      );
    }
  }

  activateInitialTool(tool?: Tool): void {
    const mapping = {
      [Tool.PAN]: this.activatePan,
      [Tool.DRAW_POLYGON]: this.activateDrawPolygon,
      [Tool.DELETE]: this.activateDelete,
      [Tool.MEASURE_LINE]: this.activateMeasureLine,
      [Tool.MEASURE_AREA]: this.activateMeasureArea,
      [Tool.SELECT_AND_MODIFY]: this.activateSelect,
      [Tool.SHOW_LAYERS]: this.activateShowLayers,
      [Tool.CENTER_MAP]: this.activateCenterMap,
      [Tool.TUTORIAL]: this.activateTutorial,
      [Tool.UNDO]: this.activateUndo,
      [Tool.SAVE]: this.activateSave,
      [Tool.PRINT]: this.activatePrint,
      [Tool.UPLOAD]: this.activateUploadFile,
      [Tool.SEARCH]: this.activateSearch,
      [Tool.OUTLINE_DRAW_POLYGON_AND_MERGE]: this
        .activateOutlineDrawPolygonAndMerge,
      [Tool.OUTLINE_DRAW_POLYGON_AND_SUBTRACT]: this
        .activateOutlineDrawPolygonAndSubtract,
      [Tool.OUTLINE_MODIFY_POLYGON]: this.activateOutlineModifyPolygon,
      [Tool.OUTLINE_UPLOAD]: this.activateUploadFile,
      [Tool.OUTLINE_MULTISELECT_LINE_ADD]: this
        .activateOutlineMultiSelectLineAdd,
      [Tool.OUTLINE_MULTISELECT_LINE_SUBTRACT]: this
        .activateOutlineMultiSelectLineSubtract,
      [Tool.OUTLINE_MULTISELECT_POLYGON_ADD]: this
        .activateOutlineMultiSelectPolygonAdd,
      [Tool.OUTLINE_MULTISELECT_POLYGON_SUBTRACT]: this
        .activateOutlineMultiSelectPolygonSubtract,
      [Tool.CREATE_FEATURE]: this.activateCreateFeature,
    };

    mapping[tool || this.initialTool]();
  }

  static scaleControl(
    scaleBarSteps: number,
    scaleBarText: boolean,
    scaleType: "scaleline" | "scaleBar",
    unitsType: "metric" | "degrees" | "imperial" | "us" | "nautical"
  ): ScaleLine {
    if (scaleType === "scaleline") {
      return new ScaleLine({
        units: unitsType,
        bar: scaleType !== "scaleline",
        steps: scaleBarSteps,
        text: scaleBarText,
      });
    }
    return new ScaleLine({
      units: unitsType,
      bar: true,
      steps: scaleBarSteps,
      text: scaleBarText,
      minWidth: 140,
    });
  }

  addMinimap(): void {
    if (this.stratopoMapViewer.getMode() !== SNAPSHOT_MODE) {
      const allow_rotation = true;
      this.overviewMapControl = new OverviewMap({
        // see in overviewmap-custom.html to see the custom CSS used
        className: "ol-overviewmap ol-custom-overviewmap",
        layers: [...this.minimapLayers, this.outlineLayer],
        collapseLabel: "\u00AB",
        label: "\u00BB",
        collapsed: true,
        tipLabel: this.stratopoMapViewer.translate.go("Activate minimap"),
        rotateWithView: allow_rotation,
      });
      this.map.addControl(this.overviewMapControl);
    }
  }

  navigateTo(geometry: string, bufFactor = 1.0): void {
    const view = this.map.getView();
    const ext = getExtentFromGeometryString(view, geometry);
    if (ext) {
      const width = getWidth(ext) || 2;
      const height = getHeight(ext) || 2;
      const range = buffer(ext, bufFactor * Math.max(width, height));
      view.fit(range); //, {padding: [170, 50, 30, 150], minResolution: 50});
    }
  }

  centerMap(): void {
    const view = this.map.getView();
    let extent: Extent | undefined;

    if (this.hasOutline) {
      extent = this.outlineFeature?.getGeometry()?.getExtent();
    }

    if (!extent) {
      extent = netherlands3857;
    }

    view.setRotation(0.0);
    view.fit(extent);
    view.adjustZoom(-0.1);
  }

  private setLayerProps(
    unique_layer_name: string,
    layer: PlanViewerLayerDetails
  ): [PvLayerImplT, TileLayer | VectorLayer] {
    const aLayer = castLayer(layer);
    const olLayer = aLayer.getOlLayer({});
    olLayer.set(LOOKUP_KEY, unique_layer_name);
    for (const unique_layer_name in layer) {
      olLayer.set(unique_layer_name, (layer as any)[unique_layer_name]);
    }

    if (isOutlineLayer(layer) || isVectorLayer(layer) || isProxyLayer(layer)) {
      layer.show_layer ||= this.extraConfig.overrideShowLayers;
    }

    olLayer.setVisible(layer.show_layer);

    return [aLayer, olLayer];
  }

  addLayer(rawLayer: PlanViewerLayerDetails): [PvLayerImplT, OlLayerTypeImplT] {
    const [aLayer, mainLayer] = this.setLayerProps(rawLayer.name, rawLayer);
    const [_ayer, miniLayer] = this.setLayerProps(rawLayer.name, rawLayer);

    // add some layers, so that the minimap will be updated on the fly
    // not sure if that is needed for proxy and outline though
    if (["vector", "proxy", "outline"].indexOf(rawLayer.type) !== -1) {
      miniLayer.setSource(mainLayer.getSource() as any);
    }

    if (["dkk"].indexOf(rawLayer.type) !== -1) {
      if (this.multiselectSources.length === 0) {
        const source = mainLayer.getSource() as VectorSource<
          Polygon | MultiPolygon
        >;
        this.multiselectSources.push(source);
      }
    }

    this.minimapLayers.push(miniLayer);
    this.mainmapLayers.push(mainLayer);
    this.map.addLayer(mainLayer);
    const defaultSnap = this.snapDefaults(rawLayer.type, false);
    if (defaultSnap) {
      this.onSetLayerSnap(rawLayer.name, defaultSnap);
    }
    this.map.getControls().forEach((control, _index, _arr) => {
      if ("ovmapDiv_" in control) {
        this.map.removeControl(control);
        this.addMinimap();
      }
    });

    return [aLayer, mainLayer];
  }

  removeAllLayers(): void {
    for (const layer of this.minimapLayers) {
      this.overviewMapControl?.getMap().removeLayer(layer);
    }

    for (const layer of this.mainmapLayers) {
      this.map.removeLayer(layer);
    }

    this.minimapLayers = [];
    this.mainmapLayers = [];
    this.multiselectSources = [];

    this.addMinimap();
  }

  /**
   *
   * @param event
   * @param listener
   */
  on(event: string, listener: (p0?: any, p1?: any) => any): void {
    if (event === "select:change") {
      this.onSelectionChanged = listener;
    } else if (event === "select:modify") {
      this.onSelectModify = listener;
    } else if (event === "outline:change") {
      this.onOutlineChange = listener;
    } else if (event === "tool:saveoutline") {
      this.onSaveOutline = listener;
    } else if (event === "newfeature") {
      this.onNewFeature = listener;
    }
  }

  getMap(): Map {
    return this.map;
  }

  getMultiSelectSources(): VectorSource<Polygon | MultiPolygon>[] {
    return this.multiselectSources;
  }

  activatePan(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.PAN;
  }

  activateCenterMap(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.CENTER_MAP;
    this.centerMap();
  }

  activateTutorial(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.TUTORIAL;
    const elementId = "mapviewer_tutorial";
    const trTutorial = this.stratopoMapViewer.translate.go("Tutorial");
    const trClose = this.stratopoMapViewer.translate.go("Close");

    const el = StratopoMapViewer.createElementFromHtml(
      `<div class="modal fade" id="mvTutorialModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="display: inline">${trTutorial}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="${elementId}"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">${trClose}</button>
      </div>
    </div>
  </div>
</div>`,
      document.body
    );

    const modalEl = (window as any).jQuery("#mvTutorialModal");
    modalEl.modal();

    const contentEl = document.getElementById(elementId)!;

    ReactDOM.render(
      <RenderTutorial translate={this.stratopoMapViewer.translate} />,
      contentEl
    );

    modalEl.modal("handleUpdate");

    modalEl.on("hidden.bs.modal", () => {
      modalEl.remove();
    });

    this.deactivateAllTools();
    this.activeTool = Tool.PAN;
    this.activeToolOnDeactivate = () => {
      modalEl.modal("hide");
    };
    this.stratopoMapViewer.renderButtons();
  }

  activateSearch(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.SEARCH;
    const element = document.createElement("div");
    const overlay = activateModal(this.map, element);
    ReactDOM.render(
      <ShowSearch
        map={this.getMap()}
        overlay={overlay}
        embeddedMap={this}
        stratopoMapViewer={this.stratopoMapViewer}
        within_outline={this.stratopoMapViewer.getMode() !== EDIT_OUTLINE_MODE}
      />,
      element
    );
  }

  activateSelect(initialSelection?: ISelection): void {
    if (this.stratopoMapViewer.getMode() === SNAPSHOT_MODE) {
      return;
    }

    this.deactivateAllTools();
    this.activeTool = Tool.SELECT_AND_MODIFY;

    const selections: ISelection[] = [];
    if (initialSelection === undefined) {
      let canEdit = false;
      if (
        this.stratopoMapViewer.getMode() === EDIT_LAYER_MODE &&
        this.stratopoMapViewer.editLayerId !== undefined &&
        this.stratopoMapViewer.editLayerLayer !== undefined &&
        this.stratopoMapViewer.editLayerSource !== undefined
      ) {
        canEdit = true;
        const editLayer = {
          layerId: this.stratopoMapViewer.editLayerId,
          layer: this.stratopoMapViewer.editLayerLayer,
          source: this.stratopoMapViewer.editLayerSource,
          features: [],
          editable: true,
        };
        selections.push(editLayer);
      }
      for (const layer of this.mainmapLayers) {
        const layerId = layer.get("id");
        // exclude dkk, @TODO: possible others as well
        if (
          layer.get("type") !== "dkk" &&
          (!canEdit ||
            (canEdit && layerId !== this.stratopoMapViewer.editLayerId)) &&
          layer.getVisible() &&
          "getSource" in layer
        ) {
          const alsoInteresting = {
            layerId,
            layer: layer as VectorLayer,
            source: layer.getSource() as VectorSource,
            editable: false,
            features: [],
          };
          selections.push(alsoInteresting);
        }
      }
    } else {
      selections.push(initialSelection);
    }

    const interactions: Interaction[] = [];

    const canOnlySelectOnce =
      this.stratopoMapViewer.getMode() === EDIT_LAYER_MODE;

    const onSelectionChanged = (
      selection: ISelection,
      deselected: Feature[]
    ) => {
      this.updateFeatureController?.destroy();
      if (selection.editable && selection.features.length > 0) {
        const feature = selection.features[0] as Feature<MultiPolygon>;
        this.updateFeatureController = new EditFeatureController(
          this.stratopoMapViewer,
          feature
        );
      }

      this.onSelectionChanged?.(selection, deselected);

      if (canOnlySelectOnce) {
        for (const interaction of interactions) {
          interaction.setActive(false);
        }
      }
    };

    const onDeactivates: (() => void)[] = [];

    for (const selection of selections) {
      const config = addSingleSelectWithoutModifyInteraction(
        this.map,
        selection,
        this,
        onSelectionChanged,
        this.selectedFeatureStyle
      );
      interactions.push(...config.interactions);
      this.customInteractions.push(...config.interactions);

      if (config.onDeactivate) {
        onDeactivates.push(config.onDeactivate);
      }
    }

    this.activeToolOnDeactivate = () => {
      for (const onDeactivate of onDeactivates) {
        onDeactivate();
      }
      this.updateFeatureController?.destroy();
      this.updateFeatureController = undefined;
    };

    if (canOnlySelectOnce) {
      const totalSelected = _.sum(
        selections.map((selection) => selection.features.length)
      );

      if (totalSelected > 0) {
        for (const interaction of interactions) {
          interaction.setActive(false);
        }
      }
    }
  }

  updateModifyInteraction(oldModify: Modify, newModify: Modify): void {
    const index = this.customInteractions.indexOf(oldModify);
    this.map.addInteraction(newModify);
    if (index !== -1) {
      this.map.removeInteraction(oldModify);
      this.customInteractions[index] = newModify;
    } else {
      this.customInteractions.push(newModify);
    }
    this.applySnapping();
  }

  onModify(event: ModifyEvent): void {
    this.onSelectModify?.(event.features.getArray());
  }

  activateUploadFile(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.SEARCH;
    const element = document.createElement("div");
    const overlay = activateModal(this.map, element);
    ReactDOM.render(
      <ShowUpload overlay={overlay} embeddedMap={this} />,
      element
    );
  }

  activateDrawPolygon(): void {
    if (!this.editSource) {
      return;
    }

    this.deactivateAllTools();
    const interaction = addDrawPolygonInteraction(
      this.map,
      this.editSource,
      this
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.DRAW_POLYGON;
  }

  activateOutlineDrawPolygonAndMerge(): void {
    this.deactivateAllTools();
    const interaction = addDrawPolygonInteraction(
      this.map,
      this.outlineDrawSource!,
      this
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_DRAW_POLYGON_AND_MERGE;
  }

  get canActivateOutlineDrawPolygonAndMerge(): boolean {
    return true;
  }

  activateOutlineDrawPolygonAndSubtract(): void {
    this.deactivateAllTools();
    const interaction = addDrawPolygonInteraction(
      this.map,
      this.outlineDrawSource!,
      this
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_DRAW_POLYGON_AND_SUBTRACT;
  }

  get canActivateOutlineDrawPolygonAndSubtract(): boolean {
    return this.hasOutline;
  }

  activateOutlineModifyPolygon(): void {
    if (!this.canActivateOutlineModifyPolygon) {
      return;
    }

    this.deactivateAllTools();

    const interaction = addModifyPolygonInteraction(
      this.map,
      this.outlineFeature!,
      this
    );

    interaction.on("modifystart", (_evt) => {
      this.outlineBeforeModifying = this.outlineFeature?.getGeometry()?.clone();
    });

    interaction.on("modifyend", (_evt) => {
      const newOutline = this.outlineFeature?.getGeometry()?.clone();
      this.handleNewOutline(this.outlineBeforeModifying, newOutline);
    });

    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_MODIFY_POLYGON;
  }

  get canActivateOutlineModifyPolygon(): boolean {
    return this.hasOutline;
  }

  get hasOutline(): boolean {
    return this.getOutlineArea() > 0;
  }

  activateOutlineMultiSelectLineAdd(): void {
    if (!this.canActivateOutlineMultiSelectLineAdd) {
      return;
    }

    this.deactivateAllTools();
    const config = multiselectLine(
      this.map,
      this.multiselectSources,
      this,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_MULTISELECT_LINE_ADD;
  }

  private onFeaturesSelectedViaMultiselect(
    features: Feature<Polygon | MultiPolygon>[]
  ): void {
    const polygons = this.toPolygonArray(features);
    this.planViewer.opsPolygonUnion(polygons).then((polygon) => {
      this.outlineDrawSource!.addFeature(new Feature(polygon));
    });
  }

  private toPolygonArray(features: Feature<Polygon | MultiPolygon>[]) {
    return features
      .map((feature) => feature.getGeometry())
      .filter((geometry) => geometry !== undefined) as (
      | Polygon
      | MultiPolygon
    )[];
  }

  get canActivateOutlineMultiSelectLineAdd(): boolean {
    return this.multiselectSources.length > 0;
  }

  activateOutlineMultiSelectLineSubtract(): void {
    if (!this.canActivateOutlineMultiSelectLineSubtract) {
      return;
    }

    this.deactivateAllTools();
    const config = multiselectLine(
      this.map,
      this.multiselectSources,
      this,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_MULTISELECT_LINE_SUBTRACT;
  }

  get canActivateOutlineMultiSelectLineSubtract(): boolean {
    return this.multiselectSources.length > 0;
  }

  activateOutlineMultiSelectPolygonAdd(): void {
    if (!this.canActivateOutlineMultiSelectPolygonAdd) {
      return;
    }

    this.deactivateAllTools();
    const config = multiselectPolygon(
      this.map,
      this.multiselectSources,
      this,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_MULTISELECT_POLYGON_ADD;
  }

  get canActivateOutlineMultiSelectPolygonAdd(): boolean {
    return this.multiselectSources.length > 0;
  }

  activateOutlineMultiSelectPolygonSubtract(): void {
    if (!this.canActivateOutlineMultiSelectPolygonSubtract) {
      return;
    }

    this.deactivateAllTools();
    const config = multiselectPolygon(
      this.map,
      this.multiselectSources,
      this,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = Tool.OUTLINE_MULTISELECT_POLYGON_SUBTRACT;
  }

  get canActivateOutlineMultiSelectPolygonSubtract(): boolean {
    return this.multiselectSources.length > 0;
  }

  getOutlineArea(): number {
    const feature = this.outlineFeature;
    const geometry = feature?.getGeometry();
    const getArea = geometry?.getArea;
    return getArea?.call(geometry) ?? 0;
  }

  activateOutlineUndo(): void {
    if (this.outlineUndoManager.hasUndo()) {
      this.outlineUndoManager.undo();
    }
  }

  get canActivateOutlineUndo(): boolean {
    return this.outlineUndoManager.hasUndo();
  }

  activateOutlineRedo(): void {
    if (this.outlineUndoManager.hasRedo()) {
      this.outlineUndoManager.redo();
    }
  }

  get canActivateOutlineRedo(): boolean {
    return this.outlineUndoManager.hasRedo();
  }

  activateOutlineSave(): void {
    this.outlineUndoManager.markSavePoint();
    this.onSaveOutline?.();
  }

  get canActivateOutlineSave(): boolean {
    return !this.outlineUndoManager.atSavePoint();
  }

  activateOutlineDeleteAll(): void {
    const currentOutline = this.outlineFeature?.getGeometry();
    const newOutline = undefined;
    this.handleNewOutline(currentOutline, newOutline);
  }

  get canActivateOutlineDeleteAll(): boolean {
    return this.hasOutline;
  }

  activateMeasureLine(): void {
    this.deactivateAllTools();
    const interaction: Interaction = addMeasureInteraction(
      this.map,
      this.measureLineSource,
      this,
      "line"
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.MEASURE_LINE;
  }

  activateMeasureArea(): void {
    this.deactivateAllTools();
    const interaction: Interaction = addMeasureInteraction(
      this.map,
      this.measureAreaSource,
      this,
      "area"
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.MEASURE_AREA;
  }

  activateShowLayers(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.SHOW_LAYERS;
    const element = document.createElement("div");
    const overlay = activateModal(this.map, element);
    ReactDOM.render(
      <ShowLayers
        layers={this.stratopoMapViewer.getLayers()}
        embeddedMap={this}
        overlay={overlay}
        snapLayerNames={this.snapLayerNames}
      />,
      element
    );
  }

  activateDelete(): void {
    if (!this.editLayer) {
      return;
    }

    this.deactivateAllTools();
    const interaction: Interaction = addDeleteInteraction(
      this.map,
      this.editLayer,
      this
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = Tool.DELETE;
  }

  activatePrint(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.PRINT;
    const element = document.createElement("div");
    const overlay = activateModal(this.map, element);
    ReactDOM.render(
      <ShowPrint map={this.getMap()} overlay={overlay} embeddedMap={this} />,
      element
    );
  }

  activateCreateFeature(): void {
    this.deactivateAllTools();
    this.activeTool = Tool.CREATE_FEATURE;
    this.newFeature = new Feature<MultiPolygon>(new MultiPolygon([[]]));
    this.newFeatureController = new EditFeatureController(
      this.stratopoMapViewer,
      this.newFeature
    );
    this.newFeatureSource.clear();
    this.newFeatureSource.addFeature(this.newFeature);
    this.onNewFeature?.(this.newFeature);
    this.activeToolOnDeactivate = () => {
      this.newFeatureController?.destroy();
      this.newFeatureController = undefined;
      this.newFeatureSource.clear();
      this.newFeatureDrawSource.clear();
      this.stratopoMapViewer.renderButtons();
      this.stratopoMapViewer.renderSidePane();
    };
  }

  getNewFeatureController(): EditFeatureController | undefined {
    return this.newFeatureController;
  }

  getUpdateFeatureController(): EditFeatureController | undefined {
    return this.updateFeatureController;
  }

  get canActivateCreateFeature() {
    // TODO
    return true;
  }

  activateUndo(): void {
    alert("not implemented yet");
  }

  activateSave(): void {
    alert("not implemented yet");
  }

  toggleSnapping(): void {
    this.snapOn = !this.snapOn;
    this.applySnapping();
  }

  isSnapEnabled(): boolean {
    return this.snapOn;
  }

  onSetLayerSnap(layerName: string, active: boolean): void {
    const position = this.snapLayerNames.indexOf(layerName);
    if (position === -1) {
      if (active) {
        this.snapLayerNames.push(layerName);
      }
    } else {
      if (!active) {
        this.snapLayerNames.splice(position, 1);
      }
    }
    this.applySnapping();
  }

  applySnapping(): void {
    // Remove existing snap interactions
    _.forEach(this.snapInteractions, (snap) =>
      this.map.removeInteraction(snap)
    );
    this.snapInteractions = [];

    if (!this.isSnapEnabled()) {
      return;
    }

    // Build snap interactions only for layers that want snapping
    if (this.snapLayerNames.indexOf(OutlineConst) !== -1) {
      const snap = new Snap({ source: this.outlineSource });
      // TODO possible optimization point, don't add snap if this is false
      snap.setActive(this.snapOn && this.outlineLayer.getVisible());
      this.snapInteractions.push(snap);
      this.map.addInteraction(snap);
    }
    _.forEach(this.mainmapLayers, (layer) => {
      const userWantsSnap =
        this.snapLayerNames.indexOf(layer.get("name")) !== -1;
      if (userWantsSnap) {
        if (layer instanceof VectorLayer) {
          const source = layer.getSource();
          if (source) {
            const snap = new Snap({ source });
            // TODO possible optimization point, don't add snap if this is false
            snap.setActive(this.snapOn && layer.getVisible());
            this.snapInteractions.push(snap);
            this.map.addInteraction(snap);
          }
        }
      }
    });

    const newFeature = this.newFeatureController?.getFeature();

    if (newFeature) {
      const snap = new Snap({ features: new Collection([newFeature]) });
      this.snapInteractions.push(snap);
      this.map.addInteraction(snap);
    }

    const updateFeature = this.updateFeatureController?.getFeature();

    if (updateFeature) {
      const snap = new Snap({ features: new Collection([updateFeature]) });
      this.snapInteractions.push(snap);
      this.map.addInteraction(snap);
    }
  }

  deactivateAllTools(): void {
    this.activeToolOnDeactivate?.();
    this.activeToolOnDeactivate = undefined;
    this.removeCustomInteractions();
    this.measureLineSource.clear();
    this.measureAreaSource.clear();
    this.map.getOverlays().clear();
  }

  private removeCustomInteractions() {
    _.forEach(this.customInteractions, (interaction) => {
      this.map.removeInteraction(interaction);
    });

    this.customInteractions = [];
  }

  getActiveTool(): Tool {
    return this.activeTool;
  }

  selectProperty(
    layerId: LayerIdentifierType,
    layer: VectorLayer,
    source: VectorSource,
    editable: boolean,
    property_: PlanViewerLayerProperty
  ): void {
    const selection = {
      layerId,
      layer,
      source,
      features: [source.getFeatureById(property_.id)],
      editable,
    };
    this.activateSelect(selection);
  }

  snapDefaults(layerType: string, checkable: boolean): boolean {
    // see https://docs.planviewer.nl/mapsapi/server_calls/application.html#get--maps_api-v2-server-layer_types
    let rasters = ["osm", "aerial", "empty"];
    if (checkable) {
      return rasters.indexOf(layerType) === -1;
    }
    rasters = rasters.concat(["wms", "wmts"]);
    return rasters.indexOf(layerType) === -1;
  }

  deselectAll(): void {
    this.activateSelect();
  }

  private setZIndexForOutlineAndMeasureLayers() {
    this.newFeatureLayer.setZIndex(980);
    this.newFeatureDrawLayer.setZIndex(990);
    this.outlineLayer.setZIndex(1000);
    this.outlineDrawLayer?.setZIndex(1010);
    this.tmpLayerToShowSelectedFeatures.setZIndex(1020);
    this.measureAreaVector.setZIndex(1100);
    this.measureLineVector.setZIndex(1100);
  }

  private handleAddFeatureOnOutlineDrawLayer(
    event: VectorSourceEvent<Polygon | MultiPolygon>
  ) {
    const userDrawnGeometry = event.feature.getGeometry();

    if (userDrawnGeometry) {
      if (
        this.activeTool === Tool.OUTLINE_DRAW_POLYGON_AND_MERGE ||
        this.activeTool === Tool.OUTLINE_MULTISELECT_LINE_ADD ||
        this.activeTool === Tool.OUTLINE_MULTISELECT_POLYGON_ADD
      ) {
        if (this.outlineFeature) {
          const currentOutlineGeometry = this.outlineFeature.getGeometry() as
            | Polygon
            | MultiPolygon;
          this.planViewer
            .opsPolygonUnion([
              currentOutlineGeometry,
              userDrawnGeometry,
            ] as Polygon[])
            .then((newOutline) => {
              this.handleNewOutline(currentOutlineGeometry, newOutline);
            });
        } else {
          this.handleNewOutline(undefined, userDrawnGeometry);
        }
      } else if (
        this.activeTool === Tool.OUTLINE_DRAW_POLYGON_AND_SUBTRACT ||
        this.activeTool === Tool.OUTLINE_MULTISELECT_LINE_SUBTRACT ||
        this.activeTool === Tool.OUTLINE_MULTISELECT_POLYGON_SUBTRACT
      ) {
        if (this.outlineFeature) {
          const currentOutlineGeometry = this.outlineFeature.getGeometry() as
            | Polygon
            | MultiPolygon;
          this.planViewer
            .opsPolygonSubtract([
              currentOutlineGeometry,
              userDrawnGeometry,
            ] as Polygon[])
            .then((newOutline) => {
              this.handleNewOutline(currentOutlineGeometry, newOutline);
            });
        }
      }
    }

    this.outlineDrawSource?.removeFeature(event.feature);
  }

  private handleNewOutline(
    currentOutline: Polygon | MultiPolygon | undefined,
    newOutline: Polygon | MultiPolygon | undefined
  ) {
    currentOutline = currentOutline?.clone();
    newOutline = newOutline?.clone();
    console.debug(
      "handleNewOutline",
      currentOutline?.getCoordinates(),
      newOutline?.getCoordinates()
    );
    this.updateOutlineGeometryOnMap(newOutline);
    this.outlineUndoManager.push({
      redo: () => {
        this.onOutlineChange?.(newOutline);
        this.updateOutlineGeometryOnMap(newOutline);
      },
      undo: () => {
        this.onOutlineChange?.(currentOutline);
        this.updateOutlineGeometryOnMap(currentOutline);
      },
    });
    this.onOutlineChange?.(newOutline);
  }

  private updateOutlineGeometryOnMap(
    outline: Polygon | MultiPolygon | undefined
  ) {
    console.debug("updateOutlineGeometryOnMap", outline);
    if (outline) {
      if (this.outlineFeature) {
        this.outlineFeature.setGeometry(outline);
      } else {
        this.outlineFeature = new Feature(outline);
        this.outlineSource.addFeature(this.outlineFeature);
      }
    } else {
      if (this.outlineFeature) {
        this.outlineFeature.setGeometry(new Polygon([]));
      }
    }
  }

  setOutlineFeature(outlineFeature: Feature<Polygon | MultiPolygon>): void {
    this.outlineFeature = outlineFeature;
    this.setMaximumExtentFromOutlineSource();
  }

  getNavigationalRange(): Extent {
    let extent = createEmpty();
    let part: Extent | undefined = this.outlineSource.getExtent();
    if (part !== undefined && isValidExtent(part)) {
      extent = extend(extent, part);
    }
    for (const layer of this.mainmapLayers) {
      part = layer.getExtent();
      if (part !== undefined && isValidExtent(part)) {
        extent = extend(extent, part);
      } else {
        const source = layer.getSource() as undefined | VectorSource<Geometry>;
        if (source !== undefined && "getFeatures" in source) {
          for (const feature of source.getFeatures()) {
            part = feature.getGeometry()?.getExtent();
            if (part !== undefined && isValidExtent(part)) {
              extent = extend(extent, part);
            }
          }
        }
      }
    }
    if (!isValidExtent(extent)) {
      extent = netherlands3857;
    }

    const rescaled: Polygon = fromExtent(extent);
    rescaled.scale(1.1);
    extent = rescaled.getExtent();

    return extent;
  }

  public setMaximumExtentFromOutlineSource(): void {
    const range = this.getNavigationalRange();

    const options: ViewOptions = {
      center: getCenter(range),
    };

    if (this.stratopoMapViewer.getMode() !== EDIT_OUTLINE_MODE) {
      options.extent = range;
      options.showFullExtent = true;
    }

    const view = new View(options);
    this.map.setView(view);
    view.fit(range);
  }

  cursorInsideOutline(coordinate: Coordinate): boolean {
    const found = this.outlineSource.getFeaturesAtCoordinate(coordinate);
    return found.length > 0;
  }

  cursorInteractionAllowed(coordinate: Coordinate): boolean {
    if (this.stratopoMapViewer.getMode() === EDIT_OUTLINE_MODE) {
      return true;
    } else {
      return this.cursorInsideOutline(coordinate);
    }
  }

  setSelectedFeatureStyle(style: StyleLike | undefined): void {
    this.selectedFeatureStyle = style;
  }

  isEverythingSaved(): boolean {
    return this.outlineUndoManager.atSavePoint();
  }
}
