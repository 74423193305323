import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAlt,
  faBook,
  faCrosshairs,
  faDrawPolygon,
  faEraser,
  faGripLines,
  faLayerGroup,
  faMagnet,
  faMinus,
  faMousePointer,
  faObjectUngroup,
  faPlus,
  faPrint,
  faRedo,
  faRuler,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { Tool } from "../embeddedmap";
import {
  EDIT_LAYER_MODE,
  EDIT_OUTLINE_MODE,
  StratopoMapViewer,
} from "../stratopomapviewer";
import { Feature } from "ol";
import {
  EditFeatureController,
  EditFeatureTool,
} from "../editFeatureController";

const ToolbarContext = React.createContext({
  refresh: (): void => {/*void*/},
  toggleSnapping: (): void => {/*void*/},
  isSnapEnabled: (): boolean => false,
});

interface ToolbarProps {
  stratopoMapViewer: StratopoMapViewer;
}

interface ButtonProps {
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  tooltip: string;
  icon: JSX.Element;
  iconSuperScript?: JSX.Element;
  name?: string;
}

const ToolButton: React.FC<ButtonProps> = (props) => {
  const toolbarContext = useContext(ToolbarContext);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    props.onClick();
    toolbarContext.refresh();
  };

  const className = classNames({
    button: true,
    active: props.active ?? false,
    disabled: props.disabled ?? false,
    [`mapviewer__btn-tool-${props.name}`]: props.name !== undefined,
  });

  return (
    <a className={className} onClick={onClick} href={"#"} title={props.tooltip}>
      <span className={"icon"}>{props.icon}</span>
      {props.iconSuperScript ? (
        <span className={"icon-super-script"}>{props.iconSuperScript}</span>
      ) : undefined}
    </a>
  );
};

interface SnapButtonProps {
  tooltip: string;
}

const SnapButton: React.FC<SnapButtonProps> = (props) => {
  const toolbarContext = useContext(ToolbarContext);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    toolbarContext.toggleSnapping();
    toolbarContext.refresh();
  };

  const enabled = toolbarContext.isSnapEnabled();
  const className = classNames("button", { active: enabled });

  return (
    <a onClick={onClick} className={className} href={"#"} title={props.tooltip}>
      <span className={"icon"}>
        <FontAwesomeIcon icon={faMagnet} />
      </span>
    </a>
  );
};

export class Toolbar extends React.Component<ToolbarProps> {
  constructor(props: ToolbarProps) {
    super(props);
  }

  componentDidMount(): void {
    return;
  }

  render(): JSX.Element {
    const stratopoMapViewer = this.props.stratopoMapViewer;
    const translate = stratopoMapViewer.translate;

    const refresh = () => {
      stratopoMapViewer.renderButtons();
      stratopoMapViewer.renderSidePane();
    };
    const toggleSnapping = () => {
      stratopoMapViewer.embeddedMap.toggleSnapping();
    };
    const isSnapEnabled = () => {
      return stratopoMapViewer.embeddedMap.isSnapEnabled();
    };

    const ZoomButton = ToolButton;

    const mode = stratopoMapViewer.getMode();

    const editOutlineGroup = () => {
      return (
        <div className={"group"}>
          <ToolButton
            name={"outlinemultiselectlineadd"}
            onClick={
              stratopoMapViewer.embeddedMap.activateOutlineMultiSelectLineAdd
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_MULTISELECT_LINE_ADD
            }
            tooltip={translate.go("Outline Multiselect Line Add")}
            icon={<FontAwesomeIcon icon={faGripLines} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineMultiSelectLineAdd
            }
          />
          <ToolButton
            name={"outlinemultiselectlinesubtract"}
            onClick={
              stratopoMapViewer.embeddedMap
                .activateOutlineMultiSelectLineSubtract
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_MULTISELECT_LINE_SUBTRACT
            }
            tooltip={translate.go("Outline Multiselect Line Subtract")}
            icon={<FontAwesomeIcon icon={faGripLines} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineMultiSelectLineSubtract
            }
          />
          <ToolButton
            name={"outlinemultiselectpolygonadd"}
            onClick={
              stratopoMapViewer.embeddedMap.activateOutlineMultiSelectPolygonAdd
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_MULTISELECT_POLYGON_ADD
            }
            tooltip={translate.go("Outline Multiselect Polygon Add")}
            icon={<FontAwesomeIcon icon={faObjectUngroup} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineMultiSelectPolygonAdd
            }
          />
          <ToolButton
            name={"outlinemultiselectpolygonsubtract"}
            onClick={
              stratopoMapViewer.embeddedMap
                .activateOutlineMultiSelectPolygonSubtract
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_MULTISELECT_POLYGON_SUBTRACT
            }
            tooltip={translate.go("Outline Multiselect Polygon Subtract")}
            icon={<FontAwesomeIcon icon={faObjectUngroup} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineMultiSelectPolygonSubtract
            }
          />
          <ToolButton
            name={"outlinedrawpolygonandmerge"}
            onClick={
              stratopoMapViewer.embeddedMap.activateOutlineDrawPolygonAndMerge
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_DRAW_POLYGON_AND_MERGE
            }
            tooltip={translate.go("Draw Polygon And Merge")}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineDrawPolygonAndMerge
            }
          />
          <ToolButton
            name={"outlinedrawpolygonandsubtract"}
            onClick={
              stratopoMapViewer.embeddedMap
                .activateOutlineDrawPolygonAndSubtract
            }
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_DRAW_POLYGON_AND_SUBTRACT
            }
            tooltip={translate.go("Draw Polygon And Subtract")}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={
              !stratopoMapViewer.embeddedMap
                .canActivateOutlineDrawPolygonAndSubtract
            }
          />
          <ToolButton
            name={"outlinemodifypolygon"}
            onClick={stratopoMapViewer.embeddedMap.activateOutlineModifyPolygon}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_MODIFY_POLYGON
            }
            tooltip={translate.go("Modify Polygon")}
            iconSuperScript={<FontAwesomeIcon icon={faCrosshairs} />}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            disabled={
              !stratopoMapViewer.embeddedMap.canActivateOutlineModifyPolygon
            }
          />
          <ToolButton
            name={"outlineundo"}
            onClick={() => stratopoMapViewer.embeddedMap.activateOutlineUndo()}
            tooltip={translate.go("Undo Edit Outline")}
            icon={<FontAwesomeIcon icon={faUndo} />}
            disabled={!stratopoMapViewer.embeddedMap.canActivateOutlineUndo}
          />
          <ToolButton
            name={"outlineredo"}
            onClick={() => stratopoMapViewer.embeddedMap.activateOutlineRedo()}
            tooltip={translate.go("Redo Edit Outline")}
            icon={<FontAwesomeIcon icon={faRedo} />}
            disabled={!stratopoMapViewer.embeddedMap.canActivateOutlineRedo}
          />
          <ToolButton
            name={"outlinesave"}
            onClick={() => stratopoMapViewer.embeddedMap.activateOutlineSave()}
            tooltip={translate.go("Save Outline")}
            icon={<FontAwesomeIcon icon={faSave} />}
            disabled={!stratopoMapViewer.embeddedMap.canActivateOutlineSave}
          />
          <ToolButton
            name={"outlinedelete"}
            onClick={() =>
              stratopoMapViewer.embeddedMap.activateOutlineDeleteAll()
            }
            tooltip={translate.go("Delete Outline")}
            icon={<FontAwesomeIcon icon={faEraser} />}
            disabled={
              !stratopoMapViewer.embeddedMap.canActivateOutlineDeleteAll
            }
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateUploadFile}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.OUTLINE_UPLOAD
            }
            tooltip={translate.go("Upload file")}
            icon={<FontAwesomeIcon icon={faUpload} />}
          />
        </div>
      );
    };

    const editLayerGroup = () => {
      return (
        <div className={"group"}>
          {/*<ToolButton*/}
          {/*  name={"drawpolygon"}*/}
          {/*  onClick={stratopoMapViewer.embeddedMap.activateDrawPolygon}*/}
          {/*  active={*/}
          {/*    stratopoMapViewer.embeddedMap.getActiveTool() ===*/}
          {/*    Tool.DRAW_POLYGON*/}
          {/*  }*/}
          {/*  tooltip={translate.go("Draw Polygon")}*/}
          {/*  icon={<FontAwesomeIcon icon={faDrawPolygon} />}*/}
          {/*/>*/}
          <ToolButton
            name={"createfeature"}
            onClick={stratopoMapViewer.embeddedMap.activateCreateFeature}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.CREATE_FEATURE
            }
            tooltip={translate.go("Create Feature")}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={!stratopoMapViewer.embeddedMap.canActivateCreateFeature}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateUploadFile}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.UPLOAD
            }
            tooltip={translate.go("Upload file")}
            icon={<FontAwesomeIcon icon={faUpload} />}
          />
        </div>
      );
    };

    const editGroup = () => {
      if (mode === EDIT_OUTLINE_MODE) {
        return editOutlineGroup();
      } else if (mode === EDIT_LAYER_MODE) {
        return editLayerGroup();
      } else {
        return <div />;
      }
    };

    return (
      <ToolbarContext.Provider
        value={{ refresh, toggleSnapping, isSnapEnabled }}
      >
        <div className={"group"}>
          <ZoomButton
            onClick={stratopoMapViewer.embeddedMap.zoomIn}
            tooltip={translate.go("Zoom In")}
            icon={<FontAwesomeIcon icon={faSearchPlus} />}
          />
          <ZoomButton
            onClick={stratopoMapViewer.embeddedMap.zoomOut}
            tooltip={translate.go("Zoom Out")}
            icon={<FontAwesomeIcon icon={faSearchMinus} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateCenterMap}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.CENTER_MAP
            }
            tooltip={translate.go("Center Map")}
            icon={<FontAwesomeIcon icon={faCrosshairs} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activatePrint}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.PRINT
            }
            tooltip={translate.go("Print")}
            icon={<FontAwesomeIcon icon={faPrint} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateTutorial}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.TUTORIAL
            }
            tooltip={translate.go("Tutorial")}
            icon={<FontAwesomeIcon icon={faBook} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateSearch}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.SEARCH
            }
            tooltip={translate.go("Search")}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activatePan}
            active={stratopoMapViewer.embeddedMap.getActiveTool() === Tool.PAN}
            tooltip={translate.go("Pan")}
            icon={<FontAwesomeIcon icon={faArrowsAlt} />}
          />
          <ToolButton
            name={"select"}
            onClick={() => stratopoMapViewer.embeddedMap.activateSelect()}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.SELECT_AND_MODIFY
            }
            tooltip={translate.go("Select")}
            icon={<FontAwesomeIcon icon={faMousePointer} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateMeasureLine}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.MEASURE_LINE
            }
            tooltip={translate.go("Measure line")}
            icon={<FontAwesomeIcon icon={faRuler} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateMeasureArea}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() ===
              Tool.MEASURE_AREA
            }
            tooltip={translate.go("Measure area")}
            icon={<FontAwesomeIcon icon={faRulerCombined} />}
          />
          <ToolButton
            onClick={stratopoMapViewer.embeddedMap.activateShowLayers}
            active={
              stratopoMapViewer.embeddedMap.getActiveTool() === Tool.SHOW_LAYERS
            }
            tooltip={translate.go("Show Layers")}
            icon={<FontAwesomeIcon icon={faLayerGroup} />}
          />
          <SnapButton tooltip={translate.go("Snap")} />
        </div>
        {editGroup()}
      </ToolbarContext.Provider>
    );
  }
}

interface EditFeatureToolbarProps {
  stratopoMapViewer: StratopoMapViewer;
  feature: Feature;
  controller: EditFeatureController;
}

export class EditFeatureToolbar extends React.Component<EditFeatureToolbarProps> {
  render() {
    const stratopoMapViewer = this.props.stratopoMapViewer;
    const translate = stratopoMapViewer.translate;
    const edit = this.props.controller;
    const active = edit.getActiveTool();
    const refresh = () => {
      stratopoMapViewer.renderButtons();
      stratopoMapViewer.renderSidePane();
    };
    const toggleSnapping = () => {
      stratopoMapViewer.embeddedMap.toggleSnapping();
    };
    const isSnapEnabled = () => {
      return stratopoMapViewer.embeddedMap.isSnapEnabled();
    };

    return (
      <div className={"mapviewer__leftsidepane-buttons"}>
        <ToolbarContext.Provider value={{refresh, toggleSnapping, isSnapEnabled}}>
          <ToolButton
            name={"featuremultiselectlineadd"}
            onClick={edit.activateMultiSelectLineAdd}
            active={active === EditFeatureTool.MULTISELECT_LINE_ADD}
            tooltip={translate.go("Multiselect Line Add")}
            icon={<FontAwesomeIcon icon={faGripLines} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={!edit.canActivateMultiSelectLineAdd}
          />
          <ToolButton
            name={"featuremultiselectlinesubtract"}
            onClick={edit.activateMultiSelectLineSubtract}
            active={active === EditFeatureTool.MULTISELECT_LINE_SUBTRACT}
            tooltip={translate.go("Multiselect Line Subtract")}
            icon={<FontAwesomeIcon icon={faGripLines} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={!edit.canActivateMultiSelectLineSubtract}
          />
          <ToolButton
            name={"featuremultiselectpolygonadd"}
            onClick={edit.activateMultiSelectPolygonAdd}
            active={active === EditFeatureTool.MULTISELECT_POLYGON_ADD}
            tooltip={translate.go("Multiselect Polygon Add")}
            icon={<FontAwesomeIcon icon={faObjectUngroup} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={!edit.canActivateMultiSelectPolygonAdd}
          />
          <ToolButton
            name={"featuremultiselectpolygonsubtract"}
            onClick={edit.activateMultiSelectPolygonSubtract}
            active={active === EditFeatureTool.MULTISELECT_POLYGON_SUBTRACT}
            tooltip={translate.go("Multiselect Polygon Subtract")}
            icon={<FontAwesomeIcon icon={faObjectUngroup} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={!edit.canActivateMultiSelectPolygonSubtract}
          />
          <ToolButton
            name={"featuredrawpolygonandmerge"}
            onClick={edit.activateDrawPolygonAndMerge}
            active={active === EditFeatureTool.DRAW_POLYGON_AND_MERGE}
            tooltip={translate.go("Draw Polygon And Merge")}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            iconSuperScript={<FontAwesomeIcon icon={faPlus} />}
            disabled={!edit.canActivateDrawPolygonAndMerge}
          />
          <ToolButton
            name={"featuredrawpolygonandsubtract"}
            onClick={edit.activateDrawPolygonAndSubtract}
            active={active === EditFeatureTool.DRAW_POLYGON_AND_SUBTRACT}
            tooltip={translate.go("Draw Polygon And Subtract")}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            iconSuperScript={<FontAwesomeIcon icon={faMinus} />}
            disabled={!edit.canActivateDrawPolygonAndSubtract}
          />
          <ToolButton
            name={"featuremodifypolygon"}
            onClick={edit.activateModifyPolygon}
            active={active === EditFeatureTool.MODIFY_POLYGON}
            tooltip={translate.go("Modify Polygon")}
            iconSuperScript={<FontAwesomeIcon icon={faCrosshairs} />}
            icon={<FontAwesomeIcon icon={faDrawPolygon} />}
            disabled={!edit.canActivateModifyPolygon}
          />
          <SnapButton tooltip={translate.go("Snap")} />
        </ToolbarContext.Provider>
      </div>
    );
  }
}
