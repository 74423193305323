import { StratopoMapViewer } from "../stratopomapviewer";
import { ISub } from "../../core/bus";
import { AjaxEnded, AjaxStarted } from "../../core/ajax";
import { Config } from "../config";

export class SpinnerWidget implements ISub {
  running_ajax_requests: number;
  state: () => void;
  imgEl: HTMLImageElement;

  constructor(parentEl: HTMLElement) {
    this.running_ajax_requests = 0;
    this.state = this.idle;
    this.imgEl = StratopoMapViewer.createElement("img", undefined, parentEl, {
      src: Config.MAPVIEWER_JS_STATIC_URL + "/img/spinner.gif",
      alt: "spinner",
      class: "spinner",
    }) as HTMLImageElement;
    this.imgEl.style.visibility = "hidden";
  }

  should_spin(): boolean {
    return this.running_ajax_requests > 0;
  }

  /* State machine *******************************
    idle     -> spinning
    spinning -> idle
    stopped  -> stopped
  */

  idle(): void {
    if (this.state !== this.idle) {
      throw new Error("invalid state");
    }
    if (this.should_spin()) {
      this.imgEl.style.visibility = "visible";
      this.state = this.spinning;
    }
  }

  spinning(): void {
    if (this.state !== this.spinning) {
      throw new Error("invalid state");
    }
    if (!this.should_spin()) {
      this.imgEl.style.visibility = "hidden";
      this.state = this.idle;
    }
  }

  stopped(): void {
    if (this.state !== this.stopped) {
      throw new Error("invalid state");
    }
    // unchanged
  }

  /* ISub traits *********************************
     init   -> idle
     next   -> {idle, spinning}
     deinit -> stopped
  */
  init(): void {
    this.running_ajax_requests = 0;
    this.state = this.idle;
  }

  next(data: unknown): void {
    if ((data as any)[AjaxStarted] !== undefined) {
      this.running_ajax_requests += 1;
    } else if ((data as any)[AjaxEnded] !== undefined) {
      this.running_ajax_requests -= 1;
    }
    this.state();
  }

  deinit(): void {
    this.running_ajax_requests = 0;
    this.state = this.stopped;
  }
}
