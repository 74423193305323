import View from "ol/View";
import { Feature } from "ol";
import { WKT } from "ol/format";
import { Geometry, LineString, MultiPolygon, Polygon } from "ol/geom";
import { Extent } from "ol/extent";

import { LayerIdentifierType, PlanViewerLayerProperty } from "./planviewer";

export const OL_PLANVIEWER_PROPERTY_KEY = "__planviewer_property";

function withoutArea(val: string): string {
  const regex = /;AREA=([0-9\\.,]+)/;
  return val.replace(regex, "");
}

function withoutSrid(val: string): string {
  const regex = /^SRID=[0-9]+;/;
  return val.replace(regex, "");
}

export function getFeatureFromPlanviewerProperty(
  layerId: LayerIdentifierType,
  property_: PlanViewerLayerProperty
): Feature {
  let geometry: string = property_.geometry;

  let srid = "28992";
  const regex = /^SRID=([0-9]+);/;
  const match = regex.exec(geometry);
  if (match !== null) {
    srid = match[1];
  }

  geometry = geometry.replace(regex, "");
  geometry = withoutArea(geometry);

  const wkt = new WKT();
  const options = {
    dataProjection: "EPSG:" + srid,
    featureProjection: "EPSG:3857",
  };

  const feature = wkt.readFeature(geometry, options);
  feature.setId(property_.id);

  const properties: Record<string, unknown> = { layer_id: layerId };
  properties[OL_PLANVIEWER_PROPERTY_KEY] = property_;

  feature.setProperties(properties);

  return feature;
}

export function getPlanviewerGeometryFromFeature(feature: Feature): string {
  const parser = new WKT();
  const options = {
    dataProjection: "EPSG:28992",
    featureProjection: "EPSG:3857",
  };
  const wkt = parser.writeFeature(feature, options);
  return "SRID=28992;" + (wkt ? wkt : "POLYGON EMPTY");
}

export function getPlanviewerGeometryFromGeometry(geometry: Geometry): string {
  const feature = new Feature(geometry);
  return getPlanviewerGeometryFromFeature(feature);
}

export function wktFromFeature(feature: Feature): string {
  return new WKT().writeFeature(feature);
}

export function wktFromGeometry(geometry: Geometry): string {
  return wktFromFeature(new Feature(geometry));
}

export function getCoordinatesFromPolygonEwkt(ewkt: string): string {
  let coords = ewkt;
  coords = withoutSrid(coords);
  coords = withoutArea(coords);
  coords = coords.trim();

  if (coords.endsWith("EMPTY")) {
    coords = "EMPTY";
  } else {
    const firstParen = coords.indexOf("(");
    const lastParen = coords.lastIndexOf(")");
    coords = coords.substring(firstParen + 1, lastParen);
  }

  return coords;
}

export function getGeometryFromWkt(geometry: string): Geometry {
  let srid = "28992";
  const regex = /^SRID=([0-9]+);/;
  const match = regex.exec(geometry);
  if (match !== null) {
    srid = match[1];
  }

  geometry = geometry.replace(regex, "");
  geometry = withoutArea(geometry);

  const wkt = new WKT();
  const options = {
    dataProjection: "EPSG:" + srid,
    featureProjection: "EPSG:3857",
  };

  return wkt.readGeometry(geometry, options);
}

export function getGeometryFromWktWithoutReprojection(
  geometry: string
): Geometry {
  return new WKT().readGeometry(geometry);
}

export function getExtentFromGeometryString(
  view: View,
  geometry: string
): Extent | undefined {
  const options = {
    featureProjection: view.getProjection(),
  };
  if (geometry.indexOf(";") !== -1) {
    const parts = geometry.split(";");
    geometry = parts[1];
    if (parts[0].indexOf("=") !== -1) {
      const srid = parts[0].split("=")[1];
      (options as any).dataProjection = `EPSG:${srid}`;
    }
  }
  const feature = new WKT().readFeature(geometry, options);
  const ext = feature.getGeometry()?.getExtent();
  return ext;
}

/**
 * Returns true iff extent contains no Infinity/NaN values.
 * @param extent
 */
export function isValidExtent(extent: Extent): boolean {
  return (
    isFinite(extent[0]) &&
    isFinite(extent[1]) &&
    isFinite(extent[2]) &&
    isFinite(extent[3])
  );
}

export function reprojectPolygonInPlace3857to28992(polygon: Polygon | MultiPolygon): void {
  polygon.transform("EPSG:3857", "EPSG:28992");
}

export function reprojectLineStringInPlace3857to28992(line: LineString): void {
  line.transform("EPSG:3857", "EPSG:28992");
}
