import * as React from "react";

import Overlay from "ol/Overlay";

import "./ShowUpload.scss";
import { Bus } from "../../core/bus";
import { AjaxResponse, AjaxResponseT, isAjaxOk } from "../../core/ajaxresponse";
import { Modal } from "./Modal";
import { EmbeddedMap } from "../embeddedmap";
import { EDIT_LAYER_MODE, EDIT_OUTLINE_MODE } from "../stratopomapviewer";

interface ShowUploadProps {
  embeddedMap: EmbeddedMap;
  overlay: Overlay;
}

interface ShowUploadState {
  file: File | null;
  check_crs: boolean;
}
const AllowedExtensions = ["geojson", "json", "gpkg", "shp", "zip"];

export class ShowUpload extends React.Component<
  ShowUploadProps,
  ShowUploadState
> {
  constructor(props: ShowUploadProps) {
    super(props);
    this.state = { file: null, check_crs: true };
    this.selectFileCb = this.selectFileCb.bind(this);
    this.startUploadCb = this.startUploadCb.bind(this);
    this.completedUploadCb = this.completedUploadCb.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.toggleCheckCrs = this.toggleCheckCrs.bind(this);
  }

  selectFileCb(event: React.ChangeEvent<HTMLInputElement>): void {
    const files = event?.target?.files as FileList;
    if (files.length > 0) {
      this.setState((oldState) => {
        const file = files[0] as File;
        return { ...oldState, file };
      });
    }
  }

  completedUploadCb(response: AjaxResponseT): void {
    if (isAjaxOk(response)) {
      const stratopoMapViewer = this.props.embeddedMap.stratopoMapViewer;
      const mode = stratopoMapViewer.getMode();
      if (mode === EDIT_OUTLINE_MODE) {
        stratopoMapViewer.addOutlineToMap();
      } else if (mode === EDIT_LAYER_MODE) {
        stratopoMapViewer.loadLayers();
        this.props.embeddedMap.centerMap();
      }
    } else {
      const trSomethingWentWrong = "something went wrong";
      console.error(trSomethingWentWrong);
    }
  }

  toggleCheckCrs(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prevState) => {
      return {
        ...prevState,
        check_crs: !prevState.check_crs,
      };
    });
  }

  startUploadCb(event: React.MouseEvent<HTMLButtonElement>): void {
    const translate = this.props.embeddedMap.stratopoMapViewer.translate;
    const trSelectFileFirst = translate.go("Select file first");
    const trInvalidExtension = translate.go("Invalid extension");
    const trInvalidLayer = translate.go("Invalid layer");
    const trCantReadFile = translate.go(
      "Cannot read file or does not contain usable layers. Make sure it's a valid Shapefile/GeoPackage/GeoJSON using SRID 28992."
    );
    const check_crs = this.state.check_crs;

    if (this.state.file === null) {
      alert(trSelectFileFirst);
      return;
    }
    const parts = this.state.file.name.split(".");
    const extension = parts[parts.length - 1];
    if (AllowedExtensions.indexOf(extension) === -1) {
      alert(trInvalidExtension);
      return;
    }
    const stratopoMapViewer = this.props.embeddedMap.stratopoMapViewer;
    if (
      stratopoMapViewer.getMode() === EDIT_LAYER_MODE &&
      stratopoMapViewer.editLayerId === undefined
    ) {
      alert(trInvalidLayer);
      return;
    }
    let filename =
      extension === "geojson"
        ? this.state.file.name.replace(/\.geojson$/, ".json")
        : this.state.file.name;
    const now = new Date();
    filename = `upload ${now.toISOString()} ${filename}`;
    const reader = new FileReader();
    try {
      const layerId =
        stratopoMapViewer.getMode() === EDIT_OUTLINE_MODE ||
        stratopoMapViewer.editLayerId === undefined
          ? 0
          : stratopoMapViewer.editLayerId;
      const bus = Bus.getInstance();
      const response = new AjaxResponse(bus, [], this.completedUploadCb);
      reader.addEventListener("load", (_event) => {
        const plainWithData = reader.result as string;
        const encoded = plainWithData.substring(plainWithData.indexOf(",") + 1);
        stratopoMapViewer.planviewer
          .importFeaturesFromFile(
            stratopoMapViewer.identifier,
            layerId,
            filename,
            check_crs,
            encoded,
            response
          )
          .then((value) => {
            this.closePopup();
          })
          .catch((reason) => {
            alert(trCantReadFile);
          });
      });
      reader.readAsDataURL(this.state.file);
    } catch (e) {
      alert(trCantReadFile);
    }
  }

  closePopup() {
    document.getElementById("popup-closer")?.click();
  }

  render(): JSX.Element {
    const translate = this.props.embeddedMap.stratopoMapViewer.translate;
    const trShowUpload = translate.go("Show Upload");
    const trSelectFile = translate.go("Select File");
    const tr28992Hint = translate.go("28992 Hint");
    const trFunctieHint = translate.go("Function Hint");
    const trStartUpload = translate.go("Upload");
    const trForOutline = translate.go("For outline");
    const trForFeature = translate.go("For feature");
    const tr28992NoCheck = translate.go("28992 No check");
    const exts: string[] = [];
    const title =
      this.props.embeddedMap.stratopoMapViewer.getMode() === EDIT_LAYER_MODE
        ? trForFeature
        : trForOutline;
    for (const ext of AllowedExtensions) {
      exts.push(`.${ext}`);
    }
    const Content = () => {
      return (
        <div className={"show-upload-content"}>
          <table className={"tablestyle"}>
            <tbody>
              <tr></tr>
              <tr>
                <td>
                  <label htmlFor={"file"}>{trSelectFile}</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    name={"file"}
                    id={"file"}
                    type={"file"}
                    placeholder={"SomeLayer.gpkg"}
                    accept={exts.join(",")}
                    multiple={false}
                    onChange={this.selectFileCb}
                  />
                  <br></br>
                  <label>
                    <input
                      type={"checkbox"}
                      name={"check_crs"}
                      id={"check_crs"}
                      onChange={this.toggleCheckCrs}
                      checked={this.state.check_crs}
                    />&nbsp;
                    {tr28992NoCheck}
                  </label>
                </td>
              </tr>
              <tr>
                <p className={"notitie"}>{tr28992Hint}</p>
                <p className={"notitie"}>{trFunctieHint}</p>
              </tr>
              <tr>
                <td colSpan={2} style={{textAlign: "right"}}>
                  <button onClick={this.startUploadCb} className={"btn btn-primary"}>
                    {trStartUpload + " " + title}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    };
    return (
      <Modal
        className={"showlayerinfo__contents"}
        title={trShowUpload}
        content={Content()}
        modal={this}
      />
    );
  }
}
