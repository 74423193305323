import * as ReactDOM from "react-dom";
import * as React from "react";

import _ from "lodash";

import * as SLDReader from "@nieuwlandgeo/sldreader";

import { Geometry, MultiPolygon, Polygon } from "ol/geom";
import { Feature, Map } from "ol";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource, { VectorSourceEvent } from "ol/source/Vector";
import { FeatureLike } from "ol/Feature";
import { StyleFunction } from "ol/style/Style";
import { Fill, Stroke, Style } from "ol/style";

import {
  FUNCTION_TYPE_KEY,
  InMemoryPlanViewer,
  LayerIdentifierType,
  PlanViewer,
  PlanViewerLayerDetails,
  PlanViewerLayerProperty,
  ViewerIdentifierType,
} from "./planviewer";
import {
  getFeatureFromPlanviewerProperty,
  getPlanviewerGeometryFromFeature,
  OL_PLANVIEWER_PROPERTY_KEY,
} from "./gis";
import {
  AjaxResponse,
  AjaxResponseT,
  isAjaxOk,
  OkKey,
} from "../core/ajaxresponse";
import { loadDictionary } from "./translations";
import { Translate } from "../core/translate";
import { Bus, CHANNELS } from "../core/bus";
import { CustomerSldConnector, extractStyles } from "../models/sld";
import { CustomerModel, ICustomer } from "../models/customer";
import { SpinnerWidget } from "./ui/spinner_widget";
import { MessageType, ToastWidget } from "./ui/toast_widget";
import { SidePanel } from "./components/SidePanel";
import { Toolbar } from "./components/Toolbar";
import { Config } from "./config";
import { EmbeddedMap, EmbeddedMapConfig, Tool } from "./embeddedmap";
import oauth, { OauthTokenInfo } from "./oauth";
import { olDefaultStyle } from "./styling/defaults";
import {
  createTemporaryDkkLayer,
  isBaseLayer,
  isDkkLayer,
} from "./layers/pvlayer";

export const SNAPSHOT_MODE = "snapshot";
export const VIEWER_MODE = "viewer";
export const EDIT_LAYER_MODE = "edit_layer";
export const EDIT_OUTLINE_MODE = "edit_outline";

type Mode =
  | typeof SNAPSHOT_MODE
  | typeof VIEWER_MODE
  | typeof EDIT_LAYER_MODE
  | typeof EDIT_OUTLINE_MODE;
const MODES = [SNAPSHOT_MODE, VIEWER_MODE, EDIT_LAYER_MODE, EDIT_OUTLINE_MODE];

export interface ISelection {
  layerId: LayerIdentifierType;
  layer: VectorLayer;
  source: VectorSource;
  features: Feature<Geometry>[];
  editable: boolean;
}

export interface StratopoMapViewerConfig {
  overrideShowLayers: boolean;
  initialSnappingEnabled: boolean;
}

const defaultStratopoMapViewerConfig: StratopoMapViewerConfig = {
  overrideShowLayers: false,
  initialSnappingEnabled: true,
};

const cleanStratopoMapViewerConfig = (
  raw: StratopoMapViewerConfig
): StratopoMapViewerConfig => {
  // noinspection PointlessBooleanExpressionJS
  return {
    overrideShowLayers: !!raw.overrideShowLayers,
    initialSnappingEnabled: !!raw.initialSnappingEnabled,
  };
};

export class StratopoMapViewer {
  public readonly element: HTMLElement;
  public readonly identifier: ViewerIdentifierType;
  public editLayerId?: LayerIdentifierType;
  public spinnerContainer: HTMLElement;
  public toastsContainer: HTMLElement;
  public buttonsContainer: HTMLElement;
  public leftSidePanelContainer: HTMLElement;
  public translate: Translate;
  public bus: Bus;
  public embeddedMap!: EmbeddedMap;
  public map!: Map;
  public planviewer: PlanViewer;
  public editLayerLayer?: VectorLayer;
  public editLayerSource?: VectorSource;
  private outlineLayer: VectorLayer;
  private outlineSource: VectorSource<Polygon | MultiPolygon>;
  private outlineFeature?: Feature<Polygon | MultiPolygon>;
  private readonly mode: Mode;
  private oauthTokenInfo?: OauthTokenInfo;
  private layers: PlanViewerLayerDetails[];
  private customer?: CustomerModel;
  private sldStyles: SLDReader.SLDOlStyleFn[];
  private lastSavedGeometryLookup: Record<any, Geometry | undefined>;
  private lastSavedPropertiesLookup: Record<any, { [key: string]: any }>;
  private selectedFeatures?: ISelection;
  private debouncedUpdateAreasInSidePane: (p0: any) => void;
  private extraConfig: StratopoMapViewerConfig;

  constructor(
    element: HTMLElement,
    mode: Mode,
    viewerId: ViewerIdentifierType,
    layerId?: LayerIdentifierType,
    extraConfig?: StratopoMapViewerConfig
  ) {
    console.clear();
    this.element = element;
    this.mode = mode;
    this.identifier = viewerId;
    this.editLayerId = layerId;
    this.layers = [];
    this.customer = undefined;
    this.sldStyles = [];
    this.extraConfig = {
      ...defaultStratopoMapViewerConfig,
      ...cleanStratopoMapViewerConfig(
        extraConfig ?? defaultStratopoMapViewerConfig
      ),
    };

    this.validateConfiguration();

    this._isCompletelyLoaded = false;

    if (this.mode === EDIT_LAYER_MODE && layerId === undefined) {
      throw "missing layer id";
    }

    this.removeContainerElementChildren();
    this.normalizeContainerElement();

    this.spinnerContainer = StratopoMapViewer.createElementFromHtml(
      '<div class="mapviewer__spinner"></div>',
      element
    );
    this.toastsContainer = StratopoMapViewer.createElementFromHtml(
      '<div class="body__mapviewer__toasts"></div>',
      document.body
    );

    if (this.mode === SNAPSHOT_MODE) {
      this.toastsContainer.style.display = "none";
    }

    this.buttonsContainer = StratopoMapViewer.createElementFromHtml(
      '<div class="mapviewer__buttons"></div>',
      element
    );
    this.leftSidePanelContainer = StratopoMapViewer.createElementFromHtml(
      '<div class="mapviewer__leftsidepane"></div>',
      element
    );
    this.translate = new Translate(loadDictionary(), "Dutch");
    this.bus = Bus.getInstance();

    this.loadWidgets();
    this.bus.pub([CHANNELS.toast], {
      status: MessageType.Info,
      message: this.translate.go("Started"),
    });

    // TODO deprecated
    if (Config.USE_INMEMORYPLANVIEWER) {
      this.planviewer = new InMemoryPlanViewer(this.bus);
    } else {
      this.planviewer = new PlanViewer(this.bus);
    }

    this.loadStyle(Config.MAPVIEWER_JS_STATIC_URL + "/vendor/openlayers/ol.css"); // prettier-ignore
    this.loadStyle(Config.MAPVIEWER_JS_STATIC_URL + "/css/mapviewer.css");

    this.outlineSource = new VectorSource<Polygon | MultiPolygon>();
    this.outlineLayer = new VectorLayer({ source: this.outlineSource });

    this.selectedFeatures = undefined;
    this.debouncedUpdateAreasInSidePane = _.debounce(
      this.updateAreasInSidePane
    ).bind(this);

    this.cbGetCustomer = this.cbGetCustomer.bind(this);
    this.cbGetCustomerSld = this.cbGetCustomerSld.bind(this);
    this.addLayerProperties = this.addLayerProperties.bind(this);
    this.installLayerListeners = this.installLayerListeners.bind(this);
    this.installOutlineLayerListeners = this.installOutlineLayerListeners.bind(
      this
    );
    this.saveCurrentOutlineToApi = this.saveCurrentOutlineToApi.bind(this);
    this.selectProperty = this.selectProperty.bind(this);
    this.addLayers = this.addLayers.bind(this);
    this.saveOutlineToApi = this.saveOutlineToApi.bind(this);
    this.onOutlineChange = this.onOutlineChange.bind(this);
    this.renderSidePane = this.renderSidePane.bind(this) // prettier-ignore
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onNewFeature = this.onNewFeature.bind(this);
    this.getFeatureById = this.getFeatureById.bind(this);
    this.onSelectModify = this.onSelectModify.bind(this);
    this.getLastSavedGeometry = this.getLastSavedGeometry.bind(this);
    this.setLastSavedGeometry = this.setLastSavedGeometry.bind(this);
    this.getLastSavedProperties = this.getLastSavedProperties.bind(this);
    this.setLastSavedProperties = this.setLastSavedProperties.bind(this);
    this.updateAreasInSidePane = this.updateAreasInSidePane.bind(this);
    this.lastSavedGeometryLookup = {};
    this.lastSavedPropertiesLookup = {};
    oauth
      .getOauthAccessTokenViaIframe(element, this.identifier)
      .then((value) => {
        this.planviewer.oauthTokenInfo = this.oauthTokenInfo = value;
        this.constructorAfterAuthentication();
      })
      .catch((reason) => {
        console.error("OAuth failed, cannot show map", reason);
      });
  }

  private validateConfiguration() {
    if (!MODES.find((obj) => this.mode === obj)) {
      throw "unknown mode, please use 'viewer', 'edit_layer', or 'edit_outline'";
    }

    if (typeof this.editLayerId !== "undefined") {
      const intEditLayerId = parseInt(this.editLayerId as any);
      if (isNaN(intEditLayerId)) {
        throw "invalid value for layerId, must pass parseInt(layerId)";
      }
      this.editLayerId = intEditLayerId;
    }
  }

  constructorAfterAuthentication() {
    this.addMap();
    this.renderButtons();

    this.loadCustomerForViewer();

    this.addOutlineToMap();
  }

  private _isCompletelyLoaded: boolean;

  // noinspection JSUnusedGlobalSymbols
  public isCompletelyLoaded(): boolean {
    return this._isCompletelyLoaded;
  }

  public isEverythingSaved(): boolean {
    return this.embeddedMap.isEverythingSaved();
  }

  private addLayers(layers: PlanViewerLayerDetails[]): void {
    const trNoSldFound = this.translate.go("No SLD available");
    const trParcels = this.translate.go("Parcels");

    const byBase = (layer: PlanViewerLayerDetails) =>
      isBaseLayer(layer) ? 1 : 2;
    const byDkk = (layer: PlanViewerLayerDetails) =>
      isDkkLayer(layer) ? 2 : 1;
    const bySortOrder = (layer: PlanViewerLayerDetails) => layer.sort_order;

    this.layers = [...layers];

    if (this.mode === EDIT_LAYER_MODE || this.mode === EDIT_OUTLINE_MODE) {
      const hasDkk = _.some(this.layers, isDkkLayer);
      if (!hasDkk) {
        this.layers.push(createTemporaryDkkLayer(trParcels, this.identifier));
      }
    }

    _.sortBy(this.layers, [byBase, byDkk, bySortOrder]);

    if (this.sldStyles.length === 0) {
      console.debug(trNoSldFound);
    }

    for (const layer of this.layers) {
      const editable =
        this.mode === EDIT_LAYER_MODE && layer.id === this.editLayerId;
      this.addLayerToMap(layer, editable);
    }

    this.layers.reverse();
  }

  loadLayers(): void {
    this.embeddedMap.removeAllLayers();
    this.editLayerLayer = undefined;
    this.editLayerSource = undefined;

    this.planviewer
      .getLayersAndCustomerBaseLayers(this.identifier)
      .then((layers) => this.addLayers(layers))
      .then(() => this.embeddedMap.setMaximumExtentFromOutlineSource())
      .then(() => this.renderButtons())
      .then(() => this.embeddedMap.activateInitialTool())
      .then(() => {
        this.embeddedMap.getMap().once("rendercomplete", (_event) => {
          this._isCompletelyLoaded = true;
        });
      });
  }

  getLayers(): PlanViewerLayerDetails[] {
    return this.layers;
  }

  addLayerToMap(layer: PlanViewerLayerDetails, editable: boolean): void {
    const [aLayer, olLayer] = this.embeddedMap.addLayer(layer);

    if (editable) {
      if (this.editLayerLayer) {
        console.warn(
          "Cannot add another editable layer, because one layer is already marked as editable"
        );
      } else if (layer.type !== "vector") {
        console.warn(
          `Only layer type 'vector' can be editable, not type '${layer.type}'`
        );
      } else {
        this.editLayerLayer = olLayer as VectorLayer;
        this.editLayerSource = olLayer.getSource() as VectorSource;
        this.embeddedMap.setEditableLayerAndSource(
          this.editLayerLayer,
          this.editLayerSource
        );
      }
    }
    this.planviewer
      .getLayerProperties(aLayer.viewer_identifier, aLayer.layer_id)
      .then((layerProperties) => {
        const source = olLayer.getSource();
        if (source && "addFeature" in source) {
          // @TODO: there must be a better check to get only the Vectorlayers
          this.addLayerProperties(
            aLayer.layer_id,
            source as VectorSource,
            layerProperties
          );
          this.installLayerListeners(
            aLayer.layer_id,
            olLayer as VectorLayer,
            source as VectorSource,
            editable
          );
        }
      })
      .catch((reason) => {
        if (reason === "Invalid server status: Not Found") {
          // this layer has no properties, it could be osm, aerial, ..
        } else {
          console.error("getLayerProperties", reason);
        }
      });
  }

  cbGetCustomerSld(response: AjaxResponseT): void {
    if (response.status === OkKey) {
      const xmlStr = response.data as string;
      const sld = SLDReader.Reader(xmlStr);
      this.sldStyles = extractStyles(sld, this.map);
      this.tellEmbeddedMapToAlsoUseSldForSelectedFeatures();
    }

    this.loadLayers();
  }

  private tellEmbeddedMapToAlsoUseSldForSelectedFeatures() {
    const mostLikelySldStyleForFeatures = _.first(this.sldStyles);

    const widerStrokeThanNormal: StyleFunction = (
      p0: FeatureLike,
      p1: number
    ): any => {
      if (mostLikelySldStyleForFeatures) {
        let styles = mostLikelySldStyleForFeatures(p0, p1);
        if (styles) {
          styles = Array.isArray(styles) ? styles : [styles];

          // Fallback if zero styles defined
          if (styles.length === 0) {
            // @TODO instead of copypaste, use `getEditingStyles` from OpenLayers
            styles.push(
              new Style({
                fill: new Fill({
                  color: [255, 255, 255, 0.5],
                }),
                stroke: new Stroke({
                  color: [0, 153, 255, 1],
                  width: 3,
                }),
              })
            );
          }

          for (let i = 0; i < styles.length; i++) {
            styles[i] = _.cloneDeep(styles[i]);

            // Change outline into a fat, blue line.
            styles[i]["stroke_"]["color_"] = [0, 153, 255, 1];
            styles[i]["stroke_"]["width_"] = "3";

            // Put it on the foreground
            styles[i]["zIndex_"] = 10000;
          }

          return styles;
        }
      }
    };

    this.embeddedMap.setSelectedFeatureStyle(widerStrokeThanNormal);
  }

  loadSld(): void {
    if (this.customer && this.customer.id) {
      const response = new AjaxResponse(this.bus, [], this.cbGetCustomerSld);
      new CustomerSldConnector(
        this.customer.id,
        this.oauthTokenInfo!
      ).readForViewer(this.customer.id, response);
    }
  }

  private cbGetCustomer(response: AjaxResponseT): void {
    if (isAjaxOk(response)) {
      if ("customer" in response.data) {
        this.customer = CustomerModel.create(
          response.data.customer as ICustomer
        );
        this.loadSld();
      }
    }
  }

  loadCustomerForViewer(): void {
    const response = new AjaxResponse(this.bus, [], this.cbGetCustomer);
    CustomerModel.readForViewer(
      this.identifier,
      response,
      this.oauthTokenInfo!
    );
  }

  addOutlineToMap(): void {
    this.planviewer.getOutline(this.identifier).then((outline) => {
      outline = outline ?? new Polygon([]);
      this.outlineFeature = new Feature(outline);
      this.outlineSource.addFeature(this.outlineFeature);
      this.embeddedMap.setOutlineFeature(this.outlineFeature);
      this.embeddedMap.centerMap();
      this.installOutlineLayerListeners();
      this.embeddedMap.setMaximumExtentFromOutlineSource();
    });
  }

  style(feature: Feature, changedProperties?: Record<string, unknown>): void {
    // Copy feature.props.planviewer.props into feature.props, otherwise
    // the styling function cannot access them.
    const properties =
      changedProperties ??
      feature.getProperties()[OL_PLANVIEWER_PROPERTY_KEY]["properties"];
    _.map(properties, (value, key) => feature.set(key, value));

    const mostLikelySldStyleForFeatures: StyleFunction | undefined = _.first(
      this.sldStyles
    );

    const styleWithFallback = (p0: FeatureLike, p1: number) => {
      let style: Style | Style[] | undefined = mostLikelySldStyleForFeatures?.(
        p0,
        p1
      );

      if (!Array.isArray(style) && style) {
        style = [style as Style];
      }

      if (!style || style.length === 0) {
        style = olDefaultStyle;
      }

      return style;
    };

    feature.setStyle(styleWithFallback);
  }

  // @TODO: eventualy rename this to FeatureMappings for consistency
  addLayerProperties(
    layerId: LayerIdentifierType,
    source: VectorSource,
    layerProperties: PlanViewerLayerProperty[]
  ): void {
    _.forEach(layerProperties, (layerProperty) => {
      const feature = getFeatureFromPlanviewerProperty(layerId, layerProperty);
      this.style(feature);
      source.addFeature(feature);
    });
  }

  installLayerListeners(
    layerId: LayerIdentifierType,
    layer: VectorLayer,
    source: VectorSource,
    editable: boolean
  ): void {
    if (this.mode === SNAPSHOT_MODE) {
      return;
    }
    if (editable) {
      source.on("addfeature", (event: VectorSourceEvent) => {
        const feature: Feature = event.feature;
        const geometry = getPlanviewerGeometryFromFeature(feature);

        let properties = feature.getProperties();
        properties = _.omitBy(
          properties,
          (value, key) => key === feature.getGeometryName()
        );
        properties = {
          [FUNCTION_TYPE_KEY]: "",
          ...properties,
        };

        console.debug("addfeature", feature.getProperties(), properties);
        this.planviewer
          .addLayerFeature(this.identifier, layerId, geometry, properties)
          .then((featureId) => {
            if (featureId) {
              feature.setId(featureId);

              this.planviewer
                .getLayerFeature(this.identifier, layerId, featureId)
                .then((property_: PlanViewerLayerProperty) => {
                  const newProperties = { ...feature.getProperties() };
                  newProperties[OL_PLANVIEWER_PROPERTY_KEY] = property_;
                  feature.setProperties(newProperties);
                  this.style(feature);
                  this.selectProperty(
                    layerId,
                    layer,
                    source,
                    editable,
                    property_
                  );
                });
            }
          });
      });

      source.on("removefeature", (event: VectorSourceEvent) => {
        const feature: Feature = event.feature;
        const response = new AjaxResponse(this.bus, []);
        this.planviewer.removeLayerFeature(
          this.identifier,
          layerId,
          feature.getId(),
          response
        );
      });
    }
  }

  saveCurrentOutlineToApi() {
    const geometry = this.outlineFeature?.getGeometry();
    this.saveOutlineToApi(geometry);
  }

  saveOutlineToApi(outline: Polygon | MultiPolygon | undefined) {
    this.planviewer.setOutline(this.identifier, outline);
  }

  installOutlineLayerListeners(): void {
    if (this.mode !== EDIT_OUTLINE_MODE) {
      return;
    }
    this.embeddedMap.on("outline:change", this.onOutlineChange);
  }

  onOutlineChange(_newOutline: Polygon | MultiPolygon | undefined): void {
    if (!this.outlineFeature) {
      this.outlineSource.getFeatures()[0];
    }
    this.renderButtons();
  }

  selectProperty(
    layerId: LayerIdentifierType,
    layer: VectorLayer,
    source: VectorSource,
    editable: boolean,
    property_: PlanViewerLayerProperty
  ): void {
    this.embeddedMap.selectProperty(
      layerId,
      layer,
      source,
      editable,
      property_
    );
    this.renderButtons();
  }

  deleteFeature(feature: Feature<Geometry>) {
    this.editLayerSource?.removeFeature(feature);
    this.embeddedMap.deselectAll();
    this.renderButtons();
  }

  createFeature(feature: Feature<Geometry>) {
    console.debug("createFeature", feature.getProperties());
    const clone = feature.clone();
    if (this.editLayerSource) {
      this.editLayerSource?.addFeature(clone);
      this.embeddedMap.activateSelect({
        source: this.editLayerSource!,
        editable: true,
        layer: this.editLayerLayer!,
        layerId: this.editLayerId!,
        features: [feature],
      });
      this.renderButtons();
    }
  }

  loadWidgets(): void {
    this.bus.subscribe(
      [CHANNELS.ajax],
      new SpinnerWidget(this.spinnerContainer)
    );
    this.bus.subscribe([CHANNELS.toast], new ToastWidget(this.toastsContainer));
  }

  loadStyle(href: string): void {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = href;
    document.head.append(link);
  }

  public static createElement(
    tag: "div" | "button" | "img" | "span" | "h2" | "a",
    inner?: string | HTMLElement,
    parent?: HTMLElement,
    attrs?: Record<string, unknown>
  ): HTMLElement {
    const element = document.createElement(tag);
    if (attrs !== undefined) {
      for (const key in attrs) {
        element.setAttribute(key, attrs[key] as string);
      }
    }
    if (inner !== undefined) {
      if (typeof inner === "string") {
        element.innerHTML = inner;
      } else {
        element.appendChild(inner);
      }
    }
    if (parent !== undefined) {
      parent.appendChild(element);
    }

    return element;
  }

  public static createElementFromHtml(
    html: string,
    parent?: HTMLElement
  ): HTMLElement {
    const template = document.createElement("template");
    html = html.trim();
    template.innerHTML = html;
    const elem = template.content.firstChild as HTMLElement;

    if (parent !== undefined) {
      parent.appendChild(elem);
    }

    return elem;
  }

  addMap(): void {
    const openlayersContainerId = "mapviewer__openlayers";
    const openlayersContainer = StratopoMapViewer.createElement(
      "div",
      undefined,
      this.element,
      {
        id: openlayersContainerId,
        class: "mapviewer__openlayers",
        tabindex: "0",
      }
    ) as HTMLDivElement;

    const mapRootElement = StratopoMapViewer.createElement(
      "div",
      undefined,
      openlayersContainer,
      {
        class: "mapviewer__openlayers",
      }
    );

    const embeddedMapConfig: EmbeddedMapConfig = {
      overrideShowLayers: this.extraConfig.overrideShowLayers,
      initialSnappingEnabled: this.extraConfig.initialSnappingEnabled,
    };

    const initialTool =
      this.mode === EDIT_LAYER_MODE ? Tool.SELECT_AND_MODIFY : Tool.PAN;

    this.embeddedMap = new EmbeddedMap(
      mapRootElement,
      this.outlineLayer,
      this.outlineSource,
      this,
      initialTool,
      embeddedMapConfig
    );

    this.embeddedMap.on("select:change", this.onSelectionChanged);
    this.embeddedMap.on("select:modify", this.onSelectModify);
    this.embeddedMap.on("tool:saveoutline", () => {
      this.saveCurrentOutlineToApi();
      this.renderButtons();
    });
    this.embeddedMap.on("newfeature", this.onNewFeature);

    this.map = this.embeddedMap.embed();

    this.embeddedMap.activateInitialTool();
  }

  onNewFeature(feature: Feature<MultiPolygon>) {
    console.debug(feature, feature.getGeometry()?.getCoordinates());
    this.selectedFeatures = {
      features: [feature],
      layerId: this.editLayerId!,
      layer: this.editLayerLayer!,
      source: this.editLayerSource!,
      editable: true,
    };
    this.renderSidePane();
  }

  onSelectionChanged(
    selection: ISelection,
    deselected: Feature<Geometry>[]
  ): void {
    deselected.map((feature) => {
      const properties = this.getLastSavedProperties(feature);
      if (properties) {
        feature.setProperties(properties);
        this.style(feature);
      }

      const geometry = this.getLastSavedGeometry(feature)?.clone();
      feature.setGeometry(geometry);
      // @TODO: check if there are no memory leaks because this seems odd.
      feature.getGeometry()?.un("change", this.debouncedUpdateAreasInSidePane);
    });

    selection.features.map((feature) => {
      this.setLastSavedGeometry(feature);
      this.setLastSavedProperties(feature);
      feature.getGeometry()?.on("change", this.debouncedUpdateAreasInSidePane);
    });

    this.selectedFeatures = selection;
    this.renderSidePane();
  }

  private updateAreasInSidePane(event_: any) {
    this.renderSidePane();
  }

  private getFeatureById(id: string | number | undefined): Feature | undefined {
    if (id !== undefined && this.editLayerSource) {
      return this.editLayerSource.getFeatureById(id);
    }
  }

  setLastSavedGeometry(feature: Feature): void {
    const featureId = feature.getId();
    if (featureId !== undefined) {
      this.lastSavedGeometryLookup[featureId] = feature.getGeometry()?.clone();
    }
  }

  getLastSavedGeometry(feature: Feature): Geometry | undefined {
    const featureId = feature.getId();
    if (featureId !== undefined) {
      return this.lastSavedGeometryLookup[featureId];
    }
  }

  setLastSavedProperties(feature: Feature): void {
    const featureId = feature.getId();
    if (featureId !== undefined) {
      this.lastSavedPropertiesLookup[featureId] = _.cloneDeep(
        feature.getProperties()
      );
    }
  }

  getLastSavedProperties(feature: Feature): { [key: string]: any } | undefined {
    const featureId = feature.getId();
    if (featureId !== undefined) {
      return this.lastSavedPropertiesLookup[featureId];
    }
  }

  onSelectModify(_features: Feature[]): void {
    // TODO give this function something to do or remove it
    return;
  }

  renderSidePane(): void {
    if (this.selectedFeatures === undefined) {
      ReactDOM.unmountComponentAtNode(this.leftSidePanelContainer);
      return;
    }

    ReactDOM.render(
      <SidePanel selection={this.selectedFeatures} stratopoMapViewer={this} />,
      this.leftSidePanelContainer
    );
  }

  public renderButtons(): void {
    if (this.mode === SNAPSHOT_MODE) {
      return;
    }
    ReactDOM.render(
      <Toolbar stratopoMapViewer={this} />,
      this.buttonsContainer
    );
  }

  private removeContainerElementChildren() {
    let child = this.element.firstChild;
    while (child) {
      this.element.removeChild(child);
      child = this.element.firstChild;
    }
  }

  private normalizeContainerElement() {
    if (getComputedStyle(this.element).zIndex === "auto") {
      this.element.style.zIndex = "1";
    }
    if (getComputedStyle(this.element).position === "static") {
      this.element.style.position = "relative";
    }
  }

  getMode(): Mode {
    return this.mode;
  }
}
