import { StratopoMapViewer } from "../stratopomapviewer";
import { ISub } from "../../core/bus";
import { OkKey, ErrorKey } from "../../core/ajaxresponse";

export const MessageType = {
  Ok: typeof OkKey,
  Info: "info",
  Warning: "warning",
  Error: typeof ErrorKey,
};

type MessageTypeKeys = typeof OkKey | "info" | "warning" | typeof ErrorKey; // todo keyof MessageType;

const ToastTimers = {
  [OkKey]: 1000,
  info: 1000,
  warning: 2000,
  [ErrorKey]: 5000,
  default: 1000,
};

export class ToastWidget implements ISub {
  parentEl: HTMLElement;

  constructor(parentEl: HTMLElement) {
    this.parentEl = parentEl;
  }

  toast(status: MessageTypeKeys, message: string) {
    const toastTime = ToastTimers[status] || ToastTimers.default;
    const toastEl = StratopoMapViewer.createElement(
      "div",
      undefined,
      this.parentEl,
      {
        class: `toast toast-${status}`,
      }
    ) as HTMLDivElement;
    const xEl = StratopoMapViewer.createElement("span", "x", toastEl, {
      class: `toast-x`,
    }) as HTMLDivElement;
    xEl.onclick = () => {
      toastEl.remove();
    };
    window.setTimeout(() => {
      toastEl.remove();
    }, toastTime);
    /*const textEl = */ StratopoMapViewer.createElement(
      "div",
      message,
      toastEl,
      {
        class: `toast-text`,
      }
    ) as HTMLDivElement;
  }

  init(): void {
    /* pass */
  }

  next(data: unknown): void {
    if (
      (data as any).status !== undefined &&
      (data as any).message !== undefined
    ) {
      this.toast((data as any).status, (data as any).message);
    }
  }

  deinit(): void {
    /* pass */
  }
}
