import { MultiPolygon, Polygon } from "ol/geom";
import { Feature, Map } from "ol";
import { StratopoMapViewer } from "./stratopomapviewer";
import { addDrawPolygonInteraction } from "./interactions/drawpolygon";
import { Interaction } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { PlanViewer } from "./planviewer";
import _ from "lodash";
import {
  multiselectLine,
  multiselectPolygon,
} from "./interactions/multiselect";
import { addModifyPolygonInteraction } from "./interactions/modifypolygon";

export enum EditFeatureTool {
  DRAW_POLYGON_AND_MERGE,
  DRAW_POLYGON_AND_SUBTRACT,
  MODIFY_POLYGON,
  MULTISELECT_LINE_ADD,
  MULTISELECT_LINE_SUBTRACT,
  MULTISELECT_POLYGON_ADD,
  MULTISELECT_POLYGON_SUBTRACT,
}

export class EditFeatureController {
  private activeTool: EditFeatureTool | undefined;
  private feature: Feature<MultiPolygon>;
  private stratopoMapViewer: StratopoMapViewer;
  private customInteractions: Interaction[];
  private drawSource: VectorSource<MultiPolygon>;
  private drawLayer: VectorLayer;
  private planviewer: PlanViewer;
  private map: Map;
  private activeToolOnDeactivate: (() => void) | undefined;
  private geometryBeforeModifying?: MultiPolygon;
  private readonly alwaysAllowModifying: boolean;

  constructor(
    stratopoMapViewer: StratopoMapViewer,
    feature?: Feature<MultiPolygon>
  ) {
    this.stratopoMapViewer = stratopoMapViewer;
    this.map = stratopoMapViewer.embeddedMap.getMap();
    this.customInteractions = [];

    this.planviewer = this.stratopoMapViewer.planviewer;

    this.drawSource = this.stratopoMapViewer.embeddedMap.newFeatureDrawSource;
    this.drawLayer = this.stratopoMapViewer.embeddedMap.newFeatureDrawLayer;
    this.feature = feature ?? new Feature<MultiPolygon>();

    this.deactivateAllTools = this.deactivateAllTools.bind(this); // prettier-ignore
    this.activateMultiSelectLineAdd = this.activateMultiSelectLineAdd.bind(this); // prettier-ignore
    this.activateMultiSelectLineSubtract = this.activateMultiSelectLineSubtract.bind(this); // prettier-ignore
    this.activateMultiSelectPolygonAdd = this.activateMultiSelectPolygonAdd.bind(this); // prettier-ignore
    this.activateMultiSelectPolygonSubtract = this.activateMultiSelectPolygonSubtract.bind(this); // prettier-ignore
    this.activateDrawPolygonAndMerge = this.activateDrawPolygonAndMerge.bind(this); // prettier-ignore
    this.activateDrawPolygonAndSubtract = this.activateDrawPolygonAndSubtract.bind(this); // prettier-ignore
    this.activateModifyPolygon = this.activateModifyPolygon.bind(this); // prettier-ignore
    this.applySnapping = this.applySnapping.bind(this); // prettier-ignore
    this.handleDrawnFeature = this.handleDrawnFeature.bind(this); // prettier-ignore
    this.activateMultiSelectLineAdd = this.activateMultiSelectLineAdd.bind(this); // prettier-ignore
    this.onFeaturesSelectedViaMultiselect = this.onFeaturesSelectedViaMultiselect.bind(this); // prettier-ignore
    this.toPolygonArray = this.toPolygonArray.bind(this); // prettier-ignore
    this.onAddFeature = this.onAddFeature.bind(this); // prettier-ignore

    this.drawSource.on("addfeature", this.onAddFeature);

    this.alwaysAllowModifying = false;
  }

  getFeature(): Feature {
    return this.feature;
  }

  onAddFeature(evt: any) {
    this.handleDrawnFeature(evt.feature as Feature<MultiPolygon>);
  }

  getActiveTool(): EditFeatureTool | undefined {
    return this.activeTool;
  }

  activateMultiSelectLineAdd() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    this.activeTool = EditFeatureTool.MULTISELECT_LINE_ADD;

    this.deactivateAllTools();
    const config = multiselectLine(
      this.map,
      this.stratopoMapViewer.embeddedMap.getMultiSelectSources(),
      this.stratopoMapViewer.embeddedMap,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = EditFeatureTool.MULTISELECT_LINE_ADD;
  }

  private onFeaturesSelectedViaMultiselect(
    features: Feature<Polygon | MultiPolygon>[]
  ): void {
    const polygons = this.toPolygonArray(features);
    this.planviewer.opsPolygonUnion(polygons).then((polygon) => {
      this.drawSource!.addFeature(new Feature<MultiPolygon>(polygon));
    });
  }

  private toPolygonArray(features: Feature<Polygon | MultiPolygon>[]) {
    return features
      .map((feature) => feature.getGeometry())
      .filter((geometry) => geometry !== undefined) as (
      | Polygon
      | MultiPolygon
    )[];
  }
  get canActivateMultiSelectLineAdd() {
    return true;
  }
  activateMultiSelectLineSubtract() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    const config = multiselectLine(
      this.map,
      this.stratopoMapViewer.embeddedMap.getMultiSelectSources(),
      this.stratopoMapViewer.embeddedMap,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = EditFeatureTool.MULTISELECT_LINE_SUBTRACT;
  }
  get canActivateMultiSelectLineSubtract() {
    return true;
  }
  activateMultiSelectPolygonAdd() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    const config = multiselectPolygon(
      this.map,
      this.stratopoMapViewer.embeddedMap.getMultiSelectSources(),
      this.stratopoMapViewer.embeddedMap,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = EditFeatureTool.MULTISELECT_POLYGON_ADD;
  }
  get canActivateMultiSelectPolygonAdd() {
    return true;
  }
  activateMultiSelectPolygonSubtract() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    const config = multiselectPolygon(
      this.map,
      this.stratopoMapViewer.embeddedMap.getMultiSelectSources(),
      this.stratopoMapViewer.embeddedMap,
      this.onFeaturesSelectedViaMultiselect
    );
    this.customInteractions.push(...config.interactions);
    this.activeToolOnDeactivate = config.onDeactivate;
    this.applySnapping();
    this.activeTool = EditFeatureTool.MULTISELECT_POLYGON_SUBTRACT;
  }
  get canActivateMultiSelectPolygonSubtract() {
    return true;
  }
  activateDrawPolygonAndMerge() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    const interaction = addDrawPolygonInteraction(
      this.stratopoMapViewer.embeddedMap.getMap(),
      this.stratopoMapViewer.embeddedMap.newFeatureDrawSource,
      this.stratopoMapViewer.embeddedMap
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = EditFeatureTool.DRAW_POLYGON_AND_MERGE;
  }
  get canActivateDrawPolygonAndMerge() {
    return true;
  }
  activateDrawPolygonAndSubtract() {
    this.deactivateAllTools();
    if (this.alwaysAllowModifying) {
      this.addModifyInteraction();
    }
    const interaction = addDrawPolygonInteraction(
      this.stratopoMapViewer.embeddedMap.getMap(),
      this.stratopoMapViewer.embeddedMap.newFeatureDrawSource,
      this.stratopoMapViewer.embeddedMap
    );
    this.customInteractions.push(interaction);
    this.applySnapping();
    this.activeTool = EditFeatureTool.DRAW_POLYGON_AND_SUBTRACT;
  }
  get canActivateDrawPolygonAndSubtract() {
    return true;
  }
  activateModifyPolygon() {
    this.deactivateAllTools();
    this.addModifyInteraction();
    this.applySnapping();
    this.activeTool = EditFeatureTool.MODIFY_POLYGON;
  }

  private addModifyInteraction() {
    const interaction = addModifyPolygonInteraction(
      this.map,
      this.feature,
      this.stratopoMapViewer.embeddedMap
    );

    interaction.on("modifystart", (_evt) => {
      this.geometryBeforeModifying = this.feature
        .getGeometry()
        ?.clone() as MultiPolygon;
    });

    interaction.on("modifyend", (_evt) => {
      const newGeometry = this.feature?.getGeometry()?.clone() as MultiPolygon;

      this.planviewer.opsValidatePolygon(newGeometry).then((validPolygon) => {
        this.handleNewGeometry(this.geometryBeforeModifying, validPolygon);
      });
    });

    this.customInteractions.push(interaction);
  }

  get canActivateModifyPolygon() {
    return true;
  }

  private applySnapping() {
    this.stratopoMapViewer.embeddedMap.applySnapping();
  }

  private handleDrawnFeature(feature: Feature<MultiPolygon>) {
    const currentGeometry = this.feature.getGeometry() as MultiPolygon;
    const userDrawnGeometry = feature.getGeometry() as Polygon | MultiPolygon;

    const isCurrentlyEmpty =
      !currentGeometry || currentGeometry.getArea() === 0;

    if (userDrawnGeometry) {
      if (
        this.activeTool === EditFeatureTool.DRAW_POLYGON_AND_MERGE ||
        this.activeTool === EditFeatureTool.MULTISELECT_LINE_ADD ||
        this.activeTool === EditFeatureTool.MULTISELECT_POLYGON_ADD
      ) {
        if (isCurrentlyEmpty) {
          this.planviewer
            .opsValidatePolygon(userDrawnGeometry)
            .then((validPolygon) => {
              this.handleNewGeometry(currentGeometry, validPolygon);
            });
        } else {
          this.planviewer
            .opsPolygonUnion([currentGeometry, userDrawnGeometry])
            .then((newGeometry) => {
              this.handleNewGeometry(currentGeometry, newGeometry);
            });
        }
      } else if (
        this.activeTool === EditFeatureTool.DRAW_POLYGON_AND_SUBTRACT ||
        this.activeTool === EditFeatureTool.MULTISELECT_LINE_SUBTRACT ||
        this.activeTool === EditFeatureTool.MULTISELECT_POLYGON_SUBTRACT
      ) {
        if (!isCurrentlyEmpty) {
          this.planviewer
            .opsPolygonSubtract([currentGeometry, userDrawnGeometry])
            .then((newGeometry) => {
              this.handleNewGeometry(currentGeometry, newGeometry);
            })
            .catch((reason) => {
              const trCannotSubtract =
                "Cannot subtract polygon, possibly due to invalid geometry";
              console.info(trCannotSubtract);
            });
        }
      }
    }

    this.drawSource?.removeFeature(feature);
  }

  private handleNewGeometry(
    currentGeometry: Polygon | MultiPolygon | undefined,
    newGeometry: Polygon | MultiPolygon
  ) {
    this.feature.setGeometry(newGeometry as MultiPolygon);
  }

  deactivateAllTools(): void {
    this.activeToolOnDeactivate?.();
    this.activeToolOnDeactivate = undefined;
    this.removeCustomInteractions();
  }

  private removeCustomInteractions() {
    _.forEach(this.customInteractions, (interaction) => {
      this.map.removeInteraction(interaction);
    });

    this.customInteractions = [];
  }

  destroy() {
    this.drawSource.un("addfeature", this.onAddFeature);
    this.deactivateAllTools();
  }
}
