import * as React from "react";

import _ from "lodash";

import { Geometry, MultiPolygon, Polygon } from "ol/geom";

import "./FeatureInfo.scss";
import { formatArea } from "../interactions/measure";
import { FeatureBox } from "./FeatureBox";
import { StratopoMapViewer } from "../stratopomapviewer";
import { Bus } from "../../core/bus";
import { AjaxResponse, AjaxResponseT, isAjaxOk } from "../../core/ajaxresponse";
import { reprojectPolygonInPlace3857to28992 } from "../gis";
import { Feature } from "ol";

interface IFieldMapping {
  name: string;
}

interface IFieldMappingType {
  name: string;
  title: string;
  id: number;
  type: "loose_enum" | "string";
  type_options: string[] | { values: string[] };
}

interface FeatureInfoProps {
  stratopoMapViewer: StratopoMapViewer;
  featureBox: FeatureBox;
}

interface FeatureInfoState {
  fieldMappings: Record<string, IFieldMapping[]>;
  ignores: Record<string, string[]>;
}

export class FeatureInfo extends React.Component<
  FeatureInfoProps,
  FeatureInfoState
> {
  private debouncedOnGeometryChange: () => void;
  private feature: Feature;
  private geometry?: Geometry;

  constructor(props: FeatureInfoProps) {
    super(props);
    this.state = {
      fieldMappings: { Functie: [] },
      ignores: { Functie: ["Projectgrens"] },
    };
    this.onChange = this.onChange.bind(this);
    this.loadFieldMappingsCb = this.loadFieldMappingsCb.bind(this);
    this.formatArea = this.formatArea.bind(this);
    this.setFeaturePropertyFunctieToDefaultIfEmpty = this.setFeaturePropertyFunctieToDefaultIfEmpty.bind(this); // prettier-ignore
    this.firstAvailableFieldMapping = this.firstAvailableFieldMapping.bind(this); // prettier-ignore
    this.onGeometryChange = this.onGeometryChange.bind(this);
    this.debouncedOnGeometryChange = _.debounce(this.onGeometryChange);

    this.feature = props.featureBox.props.feature;
    this.geometry = this.feature.getGeometry();
  }

  componentDidMount(): void {
    this.loadFieldMappings();
    this.feature.on("change", this.debouncedOnGeometryChange);
    this.geometry?.on("change", this.debouncedOnGeometryChange);
  }

  componentWillUnmount() {
    this.feature.un("change", this.debouncedOnGeometryChange);
    this.geometry?.un("change", this.debouncedOnGeometryChange);
  }

  private onGeometryChange() {
    this.props.stratopoMapViewer.renderSidePane();
  }

  loadFieldMappingsCb(response: AjaxResponseT): void {
    /*
        There are many ways how fieldmappings can be used but ICT BP told us that
        they settled to use the following structure for when they create the function types.
        Please note that type_options_values becomes type_options: {values []}
        '''
        {
        "title": "Functie",
        "name": "Functie",
        "type": "loose_enum",
        "type_options_values": [
            "Projectgrens",
            "Buiten beschouwing",
            ...
            ]
        }
        @TODO: also handle the 'type_options string[]' variant
        '''
    */
    if (isAjaxOk(response)) {
      _.forEach(
        response.data.mappings as IFieldMappingType[],
        (fieldMapping) => {
          if (
            fieldMapping.type === "loose_enum" &&
            fieldMapping.type_options.values &&
            Array.isArray(fieldMapping.type_options.values)
          ) {
            this.setState(
              (oldState) => {
                const title = fieldMapping.title;
                const fieldMappings = this.state.fieldMappings;
                const ignores = this.state.ignores;
                if (!fieldMappings[title]) {
                  fieldMappings[title] = [];
                }
                if (!ignores[title]) {
                  ignores[title] = [];
                }
                if (!this.props.featureBox.state.properties[title]) {
                  this.props.featureBox.changeProperty(title, undefined);
                }
                for (const value of fieldMapping.type_options
                  .values as string[]) {
                  fieldMappings[title].push({ name: value });
                }
                return { ...oldState, fieldMappings, ignores };
              },
              () => {
                this.setFeaturePropertyFunctieToDefaultIfEmpty();
              }
            );
          }
        }
      );
    }
  }

  private setFeaturePropertyFunctieToDefaultIfEmpty() {
    const currentFunctieValue =
      this.props.featureBox.props.feature.getProperties()["Functie"] ?? "";

    if (currentFunctieValue === "") {
      this.onChange(
        "Functie",
        this.firstAvailableFieldMapping("Functie") ?? ""
      );
    }
  }

  private firstAvailableFieldMapping(key: string): string | undefined {
    const fieldmappings = this.state.fieldMappings[key] ?? [];
    const ignoredValues = this.state.ignores[key] ?? [];
    const notIgnoredValues = fieldmappings.filter(
      (mapping) => ignoredValues.indexOf(mapping.name) === -1
    );
    return notIgnoredValues[0].name;
  }

  loadFieldMappings(): void {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.loadFieldMappingsCb);
    this.props.stratopoMapViewer.planviewer.listFieldMapping(
      this.props.stratopoMapViewer.identifier,
      this.props.featureBox.props.layerId,
      response
    );
  }

  onChange(key: string, value: string): void {
    if (!this.props.featureBox.props.editable) {
      return;
    }

    this.props.featureBox.changeProperty(key, value);
  }

  formatArea(geometry: Geometry | undefined): string {
    if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
      const clone = geometry.clone();
      reprojectPolygonInPlace3857to28992(clone);
      return formatArea(clone);
    } else {
      return "";
    }
  }

  render(): JSX.Element {
    const stratopoMapViewer = this.props.stratopoMapViewer;
    const translate = stratopoMapViewer.translate;
    const trName = translate.go("Name");
    const trStatus = translate.go("Status");
    const trReference = translate.go("Reference");
    const trArea = translate.go("Surface area");
    const trEditableLayer = translate.go("Editable");
    const trViewOnlyLayer = translate.go("Readonly");

    const orgGeom = stratopoMapViewer.getLastSavedGeometry(
      this.props.featureBox.props.feature
    );
    const orgAreaFmt = this.formatArea(orgGeom);

    const layerInfo = this.props.featureBox.props.editable
      ? trEditableLayer
      : trViewOnlyLayer;
    // @TODO get the name of the current layer, (it could be another layer then this.editLayerId

    let modAreaFmt = orgAreaFmt;
    const id = this.props.featureBox.props.feature.getId();
    if (id) {
      //&& stratopoMapViewer.editLayerId == this.props.featureBox.props.layerId && stratopoMapViewer.editLayerSource !== undefined) {
      //const f = stratopoMapViewer.editLayerSource.getFeatureById(id);
      const f = this.props.featureBox.props.feature;
      const modGeom = f.getGeometry();
      modAreaFmt = this.formatArea(modGeom);
    }
    const areaBlock = () => {
      if (modAreaFmt === orgAreaFmt) {
        return (
          <span
            id={"area"}
            dangerouslySetInnerHTML={{ __html: orgAreaFmt }}
          ></span>
        );
      }
      return (
        <span>
          <span
            id={"area"}
            style={{ textDecorationLine: "line-through" }}
            dangerouslySetInnerHTML={{ __html: orgAreaFmt }}
          ></span>
          <br/>
          <span
            id={"mod_area"}
            dangerouslySetInnerHTML={{ __html: modAreaFmt }}
          ></span>
        </span>
      );
    };
    const layerId = this.props.featureBox.props.layer.get("id");

    return (
      <table className={"table"}>
        <tbody>
          <tr>
            <td>{trArea}</td>
            <td>{areaBlock()}</td>
          </tr>

          {_.map(
            this.props.featureBox.state.properties,
            (value: string, key) => {
              const valueElement = () => {
                if (this.state.fieldMappings[key] === undefined) {
                  return (
                    <input
                      type={"text"}
                      id={"feature_type" + key}
                      className={"form-control"}
                      name={"feature_type"}
                      disabled={true}
                      value={value}
                    />
                  );
                } else {
                  return (
                    <select
                      id={"feature_type" + key}
                      className={"form-control"}
                      name={"feature_type"}
                      disabled={!this.props.featureBox.props.editable}
                      value={
                        this.props.featureBox.state.properties[key] as string
                      }
                      onChange={(evt) => this.onChange(key, evt.target.value)}
                    >
                      {_.map(this.state.fieldMappings[key], (item) => {
                        const ignoreList = this.state.ignores[key];
                        const disabled = ignoreList.indexOf(item.name) !== -1;
                        return (
                          <option
                            key={item.name}
                            value={item.name}
                            disabled={disabled}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  );
                }
              };
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{valueElement()}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    );
  }
}
