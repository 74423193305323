import * as React from "react";
import { Map } from "ol";

import Overlay from "ol/Overlay";
import OverlayPositioning from "ol/OverlayPositioning";

import "./Modal.scss";

interface IModalProps {
  className: string;
  title: string;
  content: JSX.Element;
  modal: { props: any };
}

export function Modal(props: IModalProps): JSX.Element {
  function onCloserClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    props.modal.props.overlay.setPosition(undefined);
    (event.target as any).blur();
    props.modal.props.embeddedMap.activatePan(); // todo: set the last toolstate and restore it
    props.modal.props.embeddedMap
      .getMap()
      .removeOverlay(props.modal.props.overlay);
    props.modal.props.embeddedMap.deactivateAllTools();
    props.modal.props.embeddedMap.stratopoMapViewer.renderButtons();
  }

  return (
    <div className={"showlayers__contents"}>
      <div id={"popup"} className={"ol-popup"}>
        <a
          href={"#"}
          id={"popup-closer"}
          className={"ol-popup-closer"}
          onClick={onCloserClick}
        ></a>
        <h1 className={"modalTitle"}>{props.title}</h1>
        <div id={"popup-content"} className={"popup-content"}>{props.content}</div>
      </div>
    </div>
  );
}
export function activateModal(map: Map, element: HTMLDivElement): Overlay {
  // The overlay needs to be created before the content is added.
  // Apperently, the react event's don't get fired otherwise.
  const overlay = new Overlay({
    element: element,
    autoPan: true,
    offset: [15, 0],
    positioning: OverlayPositioning.CENTER_CENTER,
    autoPanAnimation: {
      duration: 25,
    },
    className: "fillScreen",
  });
  const center = map.getView().getCenter();
  overlay.setPosition(center);
  map.addOverlay(overlay);

  return overlay;
}
