import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import { createXYZ } from "ol/tilegrid";
import { tile } from "ol/loadingstrategy";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";

export const IsLayerWFS = (
  layer: PlanViewerLayerDetails
): layer is PvLayerWFS => {
  return layer.type === "wfs";
};

export class PvLayerWFS extends PvLayer implements IPvLayerMap {
  wfs_url: string;
  wfs_layer_name: string;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    wfs_url: string,
    wfs_layer_name: string
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.wfs_url = wfs_url;
    this.wfs_layer_name = wfs_layer_name;
  }

  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    if (!options.style) {
      options.style = new Style({ stroke: new Stroke({ color: "green" }) });
    }
    const ol = PvLayerWFS.wrap(
      this,
      this.wfs_url,
      this.wfs_layer_name,
      options
    );
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }

  static wrap(
    layer: PvLayer,
    wfs_url: string,
    wfs_layer_name: string,
    options: Record<string, unknown>
  ): OlLayerTypeImplT {
    let parts = wfs_url.split("?");
    const baseUrl = parts[0];
    const paramString = parts.length === 1 ? "" : parts[1];
    const pairs: Record<string, string> = {
      service: "WFS",
      version: "1.1.0",
      request: "GetFeature",
      typeName: wfs_layer_name,
      count: "10000",
      startIndex: "0",
      outputFormat: "json",
      srsname: "EPSG:3857",
    };
    let hasBbox = false;
    for (const pair of paramString.split("=")) {
      const key = pair[0];
      if (key !== undefined) {
        if (key === "bbox") {
          hasBbox = true;
        }
        pairs[key] = pair[1] || "";
      }
    }
    parts = [];
    for (const key in pairs) {
      parts.push(`${key}=${pairs[key]}`);
    }
    const paramUrl = `${baseUrl}?${parts.join("&")}`;
    const url = hasBbox
      ? paramUrl
      : (
          extent: unknown[] /*Extend*/,
          _resolution: number,
          _projection: unknown /*Projection*/
        ) => {
          return paramUrl + "&bbox=" + extent.join(",") + ",EPSG:3857";
        };
    if (!options.style) {
      options.style = new Style({ stroke: new Stroke({ color: "black" }) });
    }
    return new VectorLayer({
      ...options,
      source: new VectorSource({
        format: new GeoJSON(),
        url,
        strategy: tile(createXYZ({ maxZoom: 20 })),
      }),
    });
  }
}
