import { Bus, CHANNELS } from "./bus";

export const ErrorKey = "error";
export const OkKey = "ok";
export type JsonObjectT = Record<string, unknown>;
export type JsonArrayT = Record<string, unknown>[];
export type AjaxResponseT =
  | IAjaxOk
  | IAjaxOk<JsonArrayT>
  | IAjaxOk<string>
  | IAjaxError;
export type AjaxResponseCbT = (got: AjaxResponseT) => void;

export interface IAjaxOk<T = JsonObjectT> {
  status: typeof OkKey;
  data: T;
}

export interface IAjaxError {
  status: typeof ErrorKey;
  message: string;
  httpStatus?: number;
  content?: string;
}

export class AjaxResponse {
  bus: Bus;
  bus_cb: AjaxResponseCbT;
  direct_cb: AjaxResponseCbT;

  constructor(bus: Bus, channels: string[], direct_cb?: AjaxResponseCbT) {
    this.bus = bus;
    this.bus_cb = (response): void => {
      if (response.status === OkKey) {
        bus.pub(channels, response.data);
      } else {
        bus.pub([CHANNELS.error, CHANNELS.toast], response.message);
      }
    };

    this.direct_cb =
      direct_cb ??
      (() => {
        /* pass */
      });
  }

  add_direct_cb(additional: AjaxResponseCbT): void {
    const existing = this.direct_cb;
    this.direct_cb = (got) => {
      existing(got);
      additional(got);
    };
  }
}

export function isAjaxOk(
  response: AjaxResponseT
): response is IAjaxOk<JsonObjectT> {
  return response.status === OkKey && typeof response.data === "object";
}

export function isAjaxOkArray(
  response: AjaxResponseT
): response is IAjaxOk<JsonArrayT> {
  return response.status === OkKey && Array.isArray(response.data);
}

export function isAjaxOkString(
  response: AjaxResponseT
): response is IAjaxOk<string> {
  return response.status === OkKey && typeof response.data === "string";
}

export function isAjaxOkAny(response: AjaxResponseT): response is IAjaxOk<any> {
  return (
    response.status === OkKey &&
    Object.prototype.hasOwnProperty.call(response, "data")
  );
}

export function isAjaxError(response: AjaxResponseT): response is IAjaxError {
  return response.status === ErrorKey;
}
