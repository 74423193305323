import { ViewerIdentifierType } from "./planviewer";

export const toBoolean = (value: string | undefined): boolean => {
  value = value ?? "false";
  value = value.trim().toLowerCase();
  const untrue = ["", "0", "no", "false", "nil", "null", "undefined"];
  return untrue.indexOf(value.trim().toLowerCase()) === -1;
};

type W = typeof window & {
  MAPVIEWER_JS_STATIC_URL?: string;
  MAPS_API_BASE_URL?: string;
  ADMINPANEL_API_BASE_URL?: string;
  CUSTOMER_PANEL_AJAX_TOKEN?: string;
};

export class Config {
  static get MAPS_API_BASE_URL(): string {
    return (
      (window as W).MAPS_API_BASE_URL ??
      process.env.FRONTEND_MAPS_API_BASE_URL ??
      "http://localhost:8000/maps_api/v2/"
    );
  }

  static embed_url(mode: string, identifier: ViewerIdentifierType): string {
    // @TODO: solve dependency hell and change `mode: string` to `mode: Mode`
    return `${this.MAPS_API_BASE_URL}embed/${mode}/${identifier}`;
  }

  static get ADMINPANEL_API_BASE_URL(): string {
    return (
      (window as W).ADMINPANEL_API_BASE_URL ??
      process.env.FRONTEND_ADMINPANEL_API_BASE_URL ??
      "http://localhost:8000/adminpanel_api/v1"
    );
  }

  // TODO deprecated
  static get BASIC_AUTH_USER(): string | undefined {
    return process.env.FRONTEND_BASIC_AUTH_USER;
  }

  // TODO deprecated
  static get BASIC_AUTH_PASS(): string | undefined {
    return process.env.FRONTEND_BASIC_AUTH_PASS;
  }

  static get VIEWER_IDENTIFIER(): string | undefined {
    return process.env.FRONTEND_VIEWER_IDENTIFIER;
  }

  static get VIEWER_LAYER_ID(): number | undefined {
    if (process.env.FRONTEND_VIEWER_LAYER_ID !== undefined) {
      return parseInt(process.env.FRONTEND_VIEWER_LAYER_ID);
    }
  }

  // TODO deprecated
  static get USE_INMEMORYPLANVIEWER(): boolean {
    return toBoolean(process.env.FRONTEND_USE_INMEMORYPLANVIEWER);
  }

  static get MAPVIEWER_JS_STATIC_URL(): string {
    return (
      (window as W).MAPVIEWER_JS_STATIC_URL ??
      process.env.FRONTEND_MAPVIEWER_JS_STATIC_URL ??
      "/static"
    );
  }

  static get CUSTOMER_PANEL_AJAX_TOKEN(): string | undefined {
    return (window as W).CUSTOMER_PANEL_AJAX_TOKEN;
  }
}
