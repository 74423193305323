import { Feature, Map } from "ol";
import VectorSource from "ol/source/Vector";
import Draw, { DrawEvent } from "ol/interaction/Draw";
import GeometryType from "ol/geom/GeometryType";
import { LineString, MultiPolygon, Polygon } from "ol/geom";
import { Interaction } from "ol/interaction";

import { getAllIntersectingFeatures } from "../gis/intersects";
import { EmbeddedMap } from "../embeddedmap";
import { registerKeyboardEvents } from './keyboard';

export interface MultiselectLineInteractionConfiguration {
  interactions: Interaction[];
  onDeactivate?: () => void;
}

export function multiselectLine(
  map: Map,
  sources: VectorSource<Polygon | MultiPolygon>[],
  embeddedMap: EmbeddedMap,
  onSelectFeatures?: (features: Feature<Polygon | MultiPolygon>[]) => void
): MultiselectLineInteractionConfiguration {
  const interaction = new Draw({
    type: GeometryType.LINE_STRING,
  });
  registerKeyboardEvents(interaction, embeddedMap);

  return multiselect(interaction, map, sources, embeddedMap, onSelectFeatures);
}

export function multiselectPolygon(
  map: Map,
  sources: VectorSource<Polygon | MultiPolygon>[],
  embeddedMap: EmbeddedMap,
  onSelectFeatures?: (features: Feature<Polygon | MultiPolygon>[]) => void
): MultiselectLineInteractionConfiguration {
  const interaction = new Draw({
    type: GeometryType.POLYGON,
  });
  registerKeyboardEvents(interaction, embeddedMap);

  return multiselect(interaction, map, sources, embeddedMap, onSelectFeatures);
}

function multiselect(
  interaction: Draw,
  map: Map,
  sources: VectorSource<Polygon | MultiPolygon>[],
  embeddedMap: EmbeddedMap,
  onSelectFeatures?: (features: Feature<Polygon | MultiPolygon>[]) => void
) {
  interaction.on("drawend", (event: DrawEvent) => {
    const line = event.feature.getGeometry() as LineString;
    const features = getAllIntersectingFeatures(sources, line);
    onSelectFeatures?.(features);
  });
  registerKeyboardEvents(interaction, embeddedMap);

  map.addInteraction(interaction);

  return {
    interactions: [interaction],
    onDeactivate: undefined,
  };
}
