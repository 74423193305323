import * as React from "react";

import classNames from "classnames";

import { StratopoMapViewer, ISelection } from "../stratopomapviewer";
import { FeatureBox } from "./FeatureBox";

interface SidePanelProps {
  selection: ISelection;
  stratopoMapViewer: StratopoMapViewer;
}

export class SidePanel extends React.Component<SidePanelProps, unknown> {
  constructor(props: SidePanelProps) {
    super(props);
    this.state = { dummy: 0 };
    this.closeClick = this.closeClick.bind(this);
  }

  closeClick() {
    this.props.stratopoMapViewer.embeddedMap.activateSelect();
  }

  render(): JSX.Element {
    const translate = this.props.stratopoMapViewer.translate;
    const trFeature = translate.go("Feature");

    return (
      <div
        className={classNames("sidepane", {
          hidden: this.props.selection.features.length === 0,
        })}
      >
        <h3 className={"functionTitle h5"}>{trFeature}<a
            className={"ol-popup-closer"}
            onClick={this.closeClick}
            href={"#"}
          /></h3>
        {this.props.selection.features.map((feature) => (
          <div key={feature.getId() ?? "newfeature"}>
            <FeatureBox
              stratopoMapViewer={this.props.stratopoMapViewer}
              layerId={this.props.selection.layerId}
              layer={this.props.selection.layer}
              source={this.props.selection.source}
              editable={this.props.selection.editable}
              feature={feature}
              newFeatureContoller={this.props.stratopoMapViewer.embeddedMap.getNewFeatureController()}
              updateFeatureController={this.props.stratopoMapViewer.embeddedMap.getUpdateFeatureController()}
            />
          </div>
        ))}
      </div>
    );
  }
}
