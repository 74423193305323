import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";

import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";

export const IsLayerEmpty = (
  layer: PlanViewerLayerDetails
): layer is PvLayerEmpty => {
  return layer.type === "empty";
};

export class PvLayerEmpty extends PvLayer implements IPvLayerMap {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    const ol = new VectorLayer(
      Object.assign(options, { source: new VectorSource({}) })
    );
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
