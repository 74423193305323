import _ from "lodash";
import { Config } from "./config";

export function getOauthAccessTokenViaIframe(
  container: HTMLElement,
  identifier: string
): Promise<OauthTokenInfo> {
  return new Promise<OauthTokenInfo>((resolve, _reject) => {
    const iframe: HTMLIFrameElement = document.createElement("iframe");
    iframe.src = `${Config.MAPS_API_BASE_URL}oauth/url/${identifier}/redirect`;
    iframe.frameBorder = "0";
    iframe.style.border = "0";
    iframe.classList.add("mapviewer-oauth-iframe");

    const listener = (event: MessageEvent) => {
      if (isOauthMessage(event.data)) {
        window.removeEventListener("message", listener);
        container.removeChild(iframe);
        resolve(event.data.payload);
      }
    };

    window.addEventListener("message", listener);

    container.appendChild(iframe);
  });
}

// TODO delete once we're sure we won't need the popup
function getOauthAccessTokenViaPopup(identifier: string): Promise<OauthTokenInfo> {
  return new Promise<OauthTokenInfo>((resolve, _reject) => {
    const listener = (event: MessageEvent) => {
      if (isOauthMessage(event.data)) {
        window.removeEventListener("message", listener);
        resolve(event.data.payload);
      }
    };

    window.addEventListener("message", listener);

    const _ref = window.open(
      `${Config.MAPS_API_BASE_URL}oauth/url/${identifier}/redirect`,
      "Authenticatie",
      "resizeable,width=400,height=600"
    );
  });
}

export interface OauthTokenInfo {
  access_token: string;
  token_type: string;
  expires_in: string;
  refresh_token: string;
}

interface OauthMessage {
  type: string;
  payload: OauthTokenInfo;
}

function isOauthMessage(data: {
  type: string;
  payload: any;
}): data is OauthMessage {
  return (
    data["type"] === "oauth_callback_active_window" &&
    isOauthPayload(data["payload"])
  );
}

function isOauthPayload(data: Partial<OauthTokenInfo>): data is OauthTokenInfo {
  return _.every(
    ["access_token", "token_type", "expires_in", "refresh_token"],
    (value) => _.has(data, value)
  );
}

const main = {
  getOauthAccessTokenViaIframe,
};

export default main;
