import forEach from "lodash/forEach";

export const CHANNELS = {
  ajax: "ajax",
  toast: "toast",
  error: "error",
  admin: "admin",
};

export interface ISub {
  init(): void;
  next(data: unknown): void;
  deinit(): void;
}

export class Bus {
  private nextId;
  private channels: Record<string, number[]>;
  private subs: Record<number, ISub>;

  private constructor() {
    this.channels = {};
    this.subs = {};
    this.nextId = 1;
  }

  private static instance?: Bus;

  public static getInstance(): Bus {
    return this.instance ?? (this.instance = new this());
  }

  subscribe(channels: string[], sub: ISub): number {
    const id = this.nextId;
    this.subs[id] = sub;
    this.nextId += 1;
    for (const channel of channels) {
      if (this.channels[channel] === undefined) {
        this.channels[channel] = [];
      }
      this.channels[channel].push(id);
    }
    sub.init();
    return id;
  }

  pub(channels: string[], data: unknown): void {
    for (const channel of channels) {
      if (channel in this.channels) {
        for (const id of this.channels[channel]) {
          const sub = this.subs[id];
          sub.next(data);
        }
      }
    }
  }

  unsubscribe(id: number): void {
    forEach(this.channels, (channel) => {
      const pos = channel.indexOf(id);
      if (pos !== -1) {
        channel.splice(pos, 1);
      }
    });

    const sub = this.subs[id];
    sub.deinit();
    delete this.subs[id];
  }
}
