import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";

export const IsLayerVector = (
  layer: PlanViewerLayerDetails
): layer is PvLayerVector => {
  return layer.type === "vector";
};

export class PvLayerVector extends PvLayer implements IPvLayerMap {
  label_layer: string[];
  label_fields: string[];
  as_wms: boolean;
  vector_uploadable?: boolean;
  vector_type?: "polygon" | "line" | "point";
  vector_drawable?: boolean;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    label_layer: string[],
    label_fields: string[],
    as_wms: boolean,
    vector_uploadable?: boolean,
    vector_type?: "polygon" | "line" | "point",
    vector_drawable?: boolean
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.label_layer = label_layer;
    this.label_fields = label_fields;
    this.as_wms = as_wms;

    this.vector_uploadable = vector_uploadable;
    this.vector_type = vector_type;
    this.vector_drawable = vector_drawable;
  }

  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    const ol = new VectorLayer({ ...options, source: new VectorSource({}) });
    ol.setVisible(this.show_layer || this.consultable);
    return ol;
  }
}
