import { MultiPolygon, Polygon } from "ol/geom";
import { Feature } from "ol";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
  ViewerIdentifierType,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";
import { PvLayerEmpty } from "./empty";
import { getGeometryFromWkt } from "../gis";

export const IsLayerOutline = (
  layer: PlanViewerLayerDetails
): layer is PvLayerOutline => {
  return layer.type === "outline" && layer.resolved !== undefined; // @TODO: add checkif outline_target_viewer is set
};

export class PvLayerOutline extends PvLayer implements IPvLayerMap {
  outline_target_viewer: string;
  outline_clickable: boolean;
  resolved: PlanViewerViewerDetails | null;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    outline_target_viewer: string,
    outline_clickable: boolean,
    resolved: PlanViewerViewerDetails | null
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.outline_target_viewer = outline_target_viewer;
    this.outline_clickable = outline_clickable;
    this.resolved = resolved;
  }
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    /* 
    The backend sets this field to None when:
     - the viewer is not found
     - the viewer is the same viewer
     - the viewer belongs to a viewer from another customer
     So when this resolve property is set, it is a valid Viewer.
     */
    // there is a field 'has_outline', but let's ignore that now
    const outlineMissing =
      this.resolved === null ||
      this.resolved === undefined ||
      this.resolved.outline === undefined;
    if (outlineMissing) {
      const ol = new PvLayerEmpty(
        this.viewer,
        this.id,
        this.type,
        this.name,
        this.sort_order,
        this.base,
        this.consultable,
        this.show_layer,
        this.filter_fields,
        this.translations,
        this.created_at,
        this.updated_at,
        this.self_url
      );

      return ol.getOlLayer(options);
    }
    const outline = getGeometryFromWkt(
      this.resolved?.outline as string
    ) as Polygon;
    const outlineFeature = new Feature(outline);
    const outlineSource = new VectorSource<Polygon | MultiPolygon>();
    outlineSource.addFeature(outlineFeature);
    if (!options.style) {
      options.style = new Style({
        stroke: new Stroke({
          color: "blue",
          width: 3,
          lineDash: [3, 6],
        }),
      });
    }
    const outlineLayer = new VectorLayer(
      Object.assign(options, { source: outlineSource })
    );

    return outlineLayer;
  }

  get viewer_identifier(): ViewerIdentifierType {
    return this.viewer.identifier;
  }

  get layer_id(): LayerIdentifierType {
    return this.id;
  }
}
