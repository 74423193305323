import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";

export const IsLayerIMRO = (
  layer: PlanViewerLayerDetails
): layer is PvLayerIMRO => {
  return layer.type === "imro";
};

export class PvLayerIMRO extends PvLayer implements IPvLayerMap {
  getOlLayer(_options: Record<string, unknown>): OlLayerTypeImplT {
    throw new Error(`Not implemented for type ${this.type}`);
  }
}
