import { Tile as TileLayer } from "ol/layer";
import { get as getProjection } from "ol/proj";
import { getTopLeft, getWidth } from "ol/extent";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import { WMTS } from "ol/source";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
} from "../planviewer";
import {
  IPvLayerMap,
  OlLayerTypeImplT,
  PvLayer,
  PvLayerTypeDescT,
} from "./pvlayer";

export const IsLayerWMTS = (
  layer: PlanViewerLayerDetails
): layer is PvLayerWMTS => {
  return layer.type === "wmts";
};

export class PvLayerWMTS extends PvLayer implements IPvLayerMap {
  wmts_url: string;
  wmts_layer_name: string;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string,
    wmts_url: string,
    wmts_layer_name: string
  ) {
    super(
      viewer,
      id,
      type,
      name,
      sort_order,
      base,
      consultable,
      show_layer,
      filter_fields,
      translations,
      created_at,
      updated_at,
      self_url
    );
    this.wmts_url = wmts_url;
    this.wmts_layer_name = wmts_layer_name;
  }

  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    return PvLayerWMTS.wrap(this, this.wmts_url, this.wmts_layer_name, options);
  }

  static wrap(
    layer: PvLayer,
    wmts_url: string,
    wmts_layer_name: string,
    options: Record<string, any>
  ): OlLayerTypeImplT {
    const projection = getProjection("EPSG:3857");
    const projectionExtent = projection.getExtent();
    const size = getWidth(projectionExtent) / 256;
    const resolutions = new Array(20);
    const matrixIds = new Array(20);
    for (let z = 0; z < 20; ++z) {
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = z;
    }

    const ol = new TileLayer(
      Object.assign(options, {
        source: new WMTS({
          url: wmts_url,
          layer: wmts_layer_name,
          matrixSet: "EPSG:3857",
          format: "image/png",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: "default",
          wrapX: true,
          crossOrigin: "anonymous",
        }),
      })
    );
    ol.setVisible(layer.show_layer || layer.consultable);
    return ol;
  }
}
