import {
  BASE_URL,
  Connector,
  getCustomerPanelAjaxTokenHeaders,
} from "../core/connector";
import { Config } from "../app/config";
import { AjaxResponse, isAjaxError, isAjaxOk } from "../core/ajaxresponse";
import { Ajax } from "../core/ajax";
import { Model } from "../core/model";
import {
  PlanViewerViewerDetails,
  ViewerIdentifierType,
} from "../app/planviewer";
import { OauthTokenInfo } from "../app/oauth";
import { Bus } from "../core/bus";

export class CustomerConnector extends Connector {
  constructor() {
    super(`${BASE_URL}/customer`);
  }
}
export interface ICustomer {
  id: undefined | number;
  name: string;
}

export class CustomerModel extends Model implements ICustomer {
  id: undefined | number;
  name: string;

  constructor(id: number | undefined, name: string) {
    super(new CustomerConnector(), "id", "Customer", ["id", "name"]);
    this.id = id;
    this.name = name;
  }

  getUrl(): string {
    //todo investigate, if the trailing_slash parameter is needed
    return new CustomerConnector().generateUrl("read", this.pk, true)[0];
  }

  public static default(): CustomerModel {
    const customer = new CustomerModel(0, "");
    return customer;
  }

  public static create(props: ICustomer): CustomerModel {
    const customer = new CustomerModel(
      props.id as number | undefined,
      props.name as string
    );
    return customer;
  }

  public set_value(field: string, value: unknown): void {
    if (
      field === "id" &&
      typeof value === "number" &&
      (this.id === 0 || this.id === undefined)
    ) {
      this.id = value;
    }
    if (field === "name" && typeof value === "string") {
      this.name = value;
    }
  }

  public listViewers(): Promise<PlanViewerViewerDetails[] | undefined> {
    return new Promise<PlanViewerViewerDetails[] | undefined>(
      (resolve, reject) => {
        const response = new AjaxResponse(Bus.getInstance(), []);

        response.add_direct_cb((got) => {
          if (isAjaxOk(got)) {
            resolve(got.data.viewers as PlanViewerViewerDetails[]);
          } else if (isAjaxError(got)) {
            if (got.httpStatus === 404) {
              resolve(undefined);
            } else {
              reject(got.message);
            }
          } else {
            reject();
          }
        });

        const method = "GET";
        const url = `${BASE_URL}/customer/${this.id}/viewers`;
        const ajax = new Ajax(
          url,
          response,
          method,
          getCustomerPanelAjaxTokenHeaders()
        );
        ajax.exec();
      }
    );
  }

  public static readForViewer(
    identifier: ViewerIdentifierType,
    ajaxResponse: AjaxResponse,
    oauthTokenInfo: OauthTokenInfo
  ): void {
    //todo: reuse this.connector
    const url = `${Config.MAPS_API_BASE_URL}server/viewers/${identifier}/customer`;
    const method = "GET";
    const headers = {
      Authorization: "Bearer " + oauthTokenInfo.access_token,
    };
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec();
  }
}
