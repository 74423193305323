import * as React from "react";

import _ from "lodash";

import { Map } from "ol";
import { intersects } from "ol/extent";
import Overlay from "ol/Overlay";

import "./ShowSearch.scss";
import { Modal } from "./Modal";
import { EmbeddedMap } from "../embeddedmap";
import { StratopoMapViewer } from "../stratopomapviewer";
import { IFoundAddress } from "../planviewer";
import { getExtentFromGeometryString } from "../gis";

interface ShowSearchProps {
  map: Map;
  embeddedMap: EmbeddedMap;
  overlay: Overlay;
  stratopoMapViewer: StratopoMapViewer;
  within_outline: boolean;
}

interface ShowSearchState {
  searchAddress: string;
  searchResults: IFoundAddress[];
}

export class ShowSearch extends React.Component<
  ShowSearchProps,
  ShowSearchState
> {
  private searchInput?: HTMLInputElement | null;

  constructor(props: ShowSearchProps) {
    super(props);
    this.state = {
      searchAddress: "",
      searchResults: [],
    };
    this.searchFieldCb = this.searchFieldCb.bind(this);
    this.addSearchResultsCb = this.addSearchResultsCb.bind(this);
    this.navigateToCb = this.navigateToCb.bind(this);
    this.debouncedSearch = this.debouncedSearch.bind(this);
    this.debouncedSearch = _.debounce(this.debouncedSearch, 250);
  }

  componentDidMount() {
    this.searchInput?.focus();
  }

  navigateToCb(found: IFoundAddress): void {
    const view = this.props.map.getView();
    const geometry = found.geometry;
    const addressExt = getExtentFromGeometryString(view, geometry);
    const scopeExt = this.props.embeddedMap.getNavigationalRange();
    const mayNavigate = addressExt && intersects(scopeExt, addressExt);
    if (mayNavigate) {
      this.props.embeddedMap.navigateTo(geometry, 100);
      this.props.embeddedMap.activatePan();
      this.props.stratopoMapViewer.renderButtons();
    } else {
      this.setState((oldState) => {
        const searchResults: IFoundAddress[] = [];
        return { ...oldState, searchResults };
      });
    }
  }

  addSearchResultsCb(searchResults: IFoundAddress[]): void {
    this.setState((oldState) => {
      return { ...oldState, searchResults };
    });
  }

  searchFieldCb(event: React.ChangeEvent<HTMLInputElement>): void {
    const query = event?.target?.value;
    this.debouncedSearch(query);
  }

  debouncedSearch(query: string): void {
    this.setState((oldState) => {
      const searchAddress = query;
      const searchResults: IFoundAddress[] = [];
      return { ...oldState, searchAddress, searchResults };
    });

    // @TODO, eventually send the outline, and let the backend limit the search
    this.props.stratopoMapViewer.planviewer
      .search(this.props.stratopoMapViewer.identifier, {
        q: query,
        limit: "20",
        within_outline: this.props.within_outline ? "1" : "",
      })
      .then((founds: IFoundAddress[]) => this.addSearchResultsCb(founds));
  }

  render(): JSX.Element {
    const trShowSearch = "Zoeken";
    const trResults = "Zoek resultaten";
    const trNoResults = "";
    const Content = () => {
      return (
        <div className={"show-search-content"}>
          <input
            className={"form-control search-input"}
            type={"text"}
            placeholder={"Amsterdam, Prinsengracht 263"}
            onChange={this.searchFieldCb}
            ref={(el) => {
              this.searchInput = el;
            }}
          />
          <h2 className={"modalSubTitle"}>
            {this.state.searchResults.length === 0 ? trNoResults : trResults}
          </h2>
          {_.map(this.state.searchResults, (found, i) => {
            return (
              <div
                key={i}
                data-geometry={found.geometry}
                className={"address"}
                onClick={() => {
                  this.navigateToCb(found);
                }}
              >
                <span data-geometry={found.geometry}>{found.name}</span>
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <Modal
        className={"showsearch__contents"}
        title={trShowSearch}
        content={Content()}
        modal={this}
      />
    );
  }
}
