import { PlanViewerLayerDetails } from "../planviewer";
import { IPvLayerMap, OlLayerTypeImplT, PvLayer } from "./pvlayer";

export const IsLayerBAGVector = (
  layer: PlanViewerLayerDetails
): layer is PvLayerBAGVector => {
  return layer.type === "bag_vector";
};

export class PvLayerBAGVector extends PvLayer implements IPvLayerMap {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT {
    throw new Error(`Not implemented for type ${this.type}`);
  }
}
