import { AjaxResponse } from "./ajaxresponse";
import { Ajax, MethodTypes } from "./ajax";
import { Config } from "../app/config";

export const BASE_URL = Config.ADMINPANEL_API_BASE_URL;

export interface IConnector {
  list(ajaxResponse: AjaxResponse, filters?: [string, unknown][]): void;
  create(props: Record<string, unknown>, ajaxResponse: AjaxResponse): void;
  read(id: number, ajaxResponse: AjaxResponse): void;
  update(
    id: number,
    props: Record<string, unknown>,
    ajaxResponse: AjaxResponse
  ): void;
  del(id: number, ajaxResponse: AjaxResponse): void;
}

export class Connector implements IConnector {
  public url: string;
  constructor(url: string) {
    this.url = url;
  }
  generateUrl(
    variant: "list" | "create" | "read" | "update" | "delete",
    filter?: number | [string, unknown][],
    addTrailingSlash = false
  ): [string, MethodTypes] {
    let url = this.url;
    const trailingSlash = addTrailingSlash ? "/" : "";
    switch (variant) {
      case "list":
        if (filter !== undefined && typeof filter !== "number") {
          for (const kv of filter) {
            const [key, value] = kv;
            const valstr = `${value}`;
            url += `/${encodeURIComponent(key)}/${encodeURIComponent(valstr)}`;
          }
        }
        return [url, "GET"];
      //break;
      case "create":
        return [`${url}${trailingSlash}`, "POST"];
      //break;
      case "read":
        if (filter === undefined || typeof filter !== "number") {
          throw new Error("missing id");
        }
        return [`${url}/${filter}${trailingSlash}`, "GET"];
      case "update":
        if (filter === undefined || typeof filter !== "number") {
          throw new Error("missing id");
        }
        return [`${url}/${filter}${trailingSlash}`, "PATCH"];
      case "delete":
        if (filter === undefined || typeof filter !== "number") {
          throw new Error("missing id");
        }
        return [`${url}/${filter}${trailingSlash}`, "DELETE"];
    }
    throw new Error(`Invalid crud method: ${variant}`);
  }

  list(ajaxResponse: AjaxResponse, filters?: [string, unknown][]): void {
    const [url, method] = this.generateUrl("list", filters);
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    //todo check filter for safety
    ajax.exec();
  }

  create(props: Record<string, unknown>, ajaxResponse: AjaxResponse): void {
    const [url, method] = this.generateUrl("create");
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    //todo check props for safety
    ajax.exec(props);
  }

  read(id: number, ajaxResponse: AjaxResponse): void {
    const [url, method] = this.generateUrl("read", id);
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec();
  }

  save(
    id: number | undefined,
    props: Record<string, unknown>,
    ajaxResponse: AjaxResponse
  ): void {
    if (id === undefined) {
      this.create(props, ajaxResponse);
    } else {
      this.update(id, props, ajaxResponse);
    }
  }

  update(
    id: number,
    props: Record<string, unknown>,
    ajaxResponse: AjaxResponse
  ): void {
    const [url, method] = this.generateUrl("update", id);
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders());
    ajax.exec(props);
  }

  del(id: number, ajaxResponse: AjaxResponse): void {
    const [url, method] = this.generateUrl("delete", id);
    const ajax = new Ajax(url, ajaxResponse, method, this.getHeaders(), [200, 204]);
    ajax.exec();
  }

  protected getHeaders() {
    return getCustomerPanelAjaxTokenHeaders();
  }
}

function getDjangoCsrfToken(): string | undefined {
  return (document.querySelector(
    "[name=csrfmiddlewaretoken]"
  ) as HTMLInputElement | null)?.value;
}

export function getCustomerPanelAjaxTokenHeaders(): Record<string, string> {
  const headers: Record<string, string> = {};

  const djangoCsrfToken = getDjangoCsrfToken();
  if (djangoCsrfToken) {
    headers["X-CSRFToken"] = djangoCsrfToken;
  }

  const customerPanelAjaxToken = Config.CUSTOMER_PANEL_AJAX_TOKEN;
  if (customerPanelAjaxToken) {
    headers["Authorization"] = `Token ${customerPanelAjaxToken}`;
  }

  return headers;
}
