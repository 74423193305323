import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";

import {
  LayerIdentifierType,
  PlanViewerLayerDetails,
  PlanViewerViewerDetails,
  ViewerIdentifierType,
} from "../planviewer";

export type PvLayerTypeDescT =
  | "empty"
  | "osm"
  | "aerial"
  | "dkk"
  | "dkk_vector"
  | "bag"
  | "bag_vector"
  | "imro"
  | "outline"
  | "proxy"
  | "wms"
  | "wmts"
  | "wfs"
  | "vector"
  | "imrogml";
export type OlLayerTypeImplT = TileLayer | VectorLayer;

export function isBaseLayer(layer: PlanViewerLayerDetails): boolean {
  const basemaptypes = ["osm", "aerial", "dkk", "dkk_vector"];
  return layer.base || basemaptypes.indexOf(layer.type) !== -1;
}

export function isDkkLayer(layer: PlanViewerLayerDetails): boolean {
  return ["dkk", "dkk_vector"].indexOf(layer.type) !== -1;
}

export function isVectorLayer(layer: PlanViewerLayerDetails): boolean {
  return ["vector"].indexOf(layer.type) !== -1;
}

export function isOutlineLayer(layer: PlanViewerLayerDetails): boolean {
  return ["outline"].indexOf(layer.type) !== -1;
}

export function isProxyLayer(layer: PlanViewerLayerDetails): boolean {
  return ["proxy"].indexOf(layer.type) !== -1;
}

export function createTemporaryDkkLayer(
  name: string,
  identifier: string
): PlanViewerLayerDetails {
  return {
    name: name,
    id: 0,
    base: true,
    type: "dkk",
    viewer: {
      created_at: "",
      identifier: identifier,
      outline: undefined,
      name: "",
      updated_at: "",
    },
    sort_order: 1,
    consultable: false,
    created_at: "",
    filter_fields: [],
    resolved: undefined,
    self_url: "",
    show_layer: true,
    translations: [],
    updated_at: "",
  };
}

export interface IPvLayerMap extends PlanViewerLayerDetails {
  getOlLayer(options: Record<string, unknown>): OlLayerTypeImplT;
  viewer_identifier: ViewerIdentifierType;
  layer_id: LayerIdentifierType;
}

export class PvLayer implements PlanViewerLayerDetails {
  viewer: PlanViewerViewerDetails;
  id: LayerIdentifierType;
  type: PvLayerTypeDescT;
  name: string;
  sort_order: number;
  base: boolean;
  consultable: boolean;
  show_layer: boolean;
  filter_fields: unknown[];
  translations: unknown[];
  created_at: string;
  updated_at: string;
  self_url: string;

  constructor(
    viewer: PlanViewerViewerDetails,
    id: LayerIdentifierType,
    type: PvLayerTypeDescT,
    name: string,
    sort_order: number,
    base: boolean,
    consultable: boolean,
    show_layer: boolean,
    filter_fields: unknown[],
    translations: unknown[],
    created_at: string,
    updated_at: string,
    self_url: string
  ) {
    this.viewer = viewer;
    this.id = id;
    this.type = type;
    this.name = name;
    this.sort_order = sort_order;
    this.base = base;
    this.consultable = consultable;
    this.show_layer = show_layer;
    this.filter_fields = filter_fields;
    this.translations = translations;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.self_url = self_url;
  }

  get viewer_identifier(): ViewerIdentifierType {
    return this.viewer.identifier;
  }

  get layer_id(): LayerIdentifierType {
    return this.id;
  }
}
