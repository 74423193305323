import * as React from "react";

import _ from "lodash";

import Overlay from "ol/Overlay";

import "./ShowLayers.scss";
import { Modal } from "./Modal";
import { EmbeddedMap } from "../embeddedmap";
import { PlanViewerLayerDetails } from "../planviewer";
import { isBaseLayer, isDkkLayer } from "../layers/pvlayer";
import { faMagnet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// @TODO: if there is a layer with this name, snapping will be flakey..., should go to layer.id
export const OutlineConst = "outline";

const OpacityRange = [0.1, 1.0];

interface ShowLayersProps {
  layers: PlanViewerLayerDetails[];
  embeddedMap: EmbeddedMap;
  overlay: Overlay;
  snapLayerNames: string[];
}

interface ShowLayersState {
  layersToShow: Record<"minimap" | "mainmap", Record<string, boolean>>;
  snapping: Record<string, boolean>;
  [OutlineConst]: boolean;
  opacity: Record<string, number>;
}

export class ShowLayers extends React.Component<
  ShowLayersProps,
  ShowLayersState
> {
  private baseLayers: PlanViewerLayerDetails[];
  private functionLayers: PlanViewerLayerDetails[];

  constructor(props: ShowLayersProps) {
    super(props);

    this.baseLayers = this.props.layers.filter((l) => isBaseLayer(l));
    this.functionLayers = this.props.layers.filter((l) => !isBaseLayer(l));

    const state: ShowLayersState = {
      layersToShow: { minimap: {}, mainmap: {} },
      snapping: {},
      [OutlineConst]: true,
      opacity: {},
    };
    for (const layer of props.layers) {
      const active = layer.show_layer;
      if (isDkkLayer(layer)) {
        state.layersToShow.minimap[layer.name] = false;
      } else {
        state.layersToShow.minimap[layer.name] = active;
      }
      state.layersToShow.mainmap[layer.name] = active;

      const snapOn = this.props.snapLayerNames.indexOf(layer.name) !== -1;
      state.snapping[layer.name] = snapOn;
    }
    for (const olLayer of this.props.embeddedMap.mainmapLayers) {
      const currOpacity = olLayer.getOpacity();
      const layerName = olLayer.get("name");
      state.opacity[layerName] = currOpacity;
    }
    this.state = state;

    this.onToggleLayerClick = this.onToggleLayerClick.bind(this);
    this.onOpacityClick = this.onOpacityClick.bind(this);
    this.onToggleSnappingClick = this.onToggleSnappingClick.bind(this);
  }

    onOpacityClick(event: React.ChangeEvent<HTMLInputElement>): void {
    const layerName = (event.target as any).dataset.layername;
    const value = parseFloat(event.target.value) || 0.7;
    this.setState((oldState) => {
      const opacity = oldState.opacity;
      opacity[layerName] = value;
      return Object.assign(oldState, { opacity });
    });
    this.props.embeddedMap.onSetLayerOpacity(layerName, value);
  }

  onToggleSnappingClick(event: React.ChangeEvent<HTMLInputElement>): void {
    const layerName = (event.target as any).dataset.layername;
    const active = event.target.checked;
    this.setState((prevState) => {
      if (layerName === OutlineConst) {
        return Object.assign(prevState, { [OutlineConst]: active });
      }
      const { layersToShow, snapping, ...rest } = prevState;
      if (layersToShow["mainmap"][layerName]) {
        snapping[layerName] = active;
      }
      return { ...prevState, snapping };
    });
    this.props.embeddedMap.onSetLayerSnap(layerName, active);
  }

  onToggleLayerClick(event: React.ChangeEvent<HTMLInputElement>): void {
    const layerName = (event.target as any).dataset.layername;
    const active = event.target.checked;
    this.setState((prevState) => {
      const { layersToShow, snapping, ...rest } = prevState;
      if (!active) {
        snapping[layerName] = false;
      }
      layersToShow["mainmap"][layerName] = active;
      layersToShow["minimap"][layerName] = active;
      return Object.assign(prevState, { layersToShow, snapping });
    });
    this.props.embeddedMap.onSetLayerVisible("mainmap", layerName, active);
    this.props.embeddedMap.onSetLayerVisible("minimap", layerName, active);
    this.props.embeddedMap.onSetLayerSnap(layerName, active);
  }

  render(): JSX.Element {
    const translate = this.props.embeddedMap.stratopoMapViewer.translate;
    const trShowLayers = translate.go("Select show layers");
    const trBaseLayers = translate.go("Base Layers");
    const trFunctionLayers = translate.go("Function Layers");
    const trOnMainMap = translate.go("On Mainmap");
    const trSnapping = translate.go("Snap active");
    const trOutline = translate.go("Outline");
    const trOpacity = translate.go("Opacity");

    const Content = () => {
      const onMain = (layer: PlanViewerLayerDetails) => {
        return (
          <input
            className={"styledCheckbox"}
            type={"checkbox"}
            name={layer.name + "_mainmap"}
            id={layer.name + "_mainmap"}
            defaultChecked={this.state.layersToShow.mainmap[layer.name]}
            data-maptype={"mainmap"}
            data-layername={layer.name}
            onChange={this.onToggleLayerClick}
          />
        );
      };
      const canSnap = (layer: PlanViewerLayerDetails) => {
        if (!this.props.embeddedMap.snapDefaults(layer.type, true)) {
          // Can't snap to raster stuff
          // (even if these layers would have features.....)
          return <span>&nbsp;</span>;
        }
        return (
          <input
            className={"styledCheckbox"}
            type={"checkbox"}
            name={layer.name + "_snapping"}
            id={layer.name + "_snapping"}
            disabled={
              this.state.layersToShow.mainmap[layer.name] &&
              !this.props.embeddedMap.snapDefaults(layer.type, true)
            }
            checked={this.state.snapping[layer.name]}
            data-layername={layer.name}
            onChange={this.onToggleSnappingClick}
          />
        );
      };
      const opacitySlider = (layer: PlanViewerLayerDetails) => {
        return (
          <input
            type={"range"}
            name={layer.name + "_opacity"}
            id={layer.name + "_opacity"}
            min={OpacityRange[0]}
            max={OpacityRange[1]}
            step={0.1}
            data-layername={layer.name}
            value={this.state.opacity[layer.name]}
            onChange={this.onOpacityClick}
            disabled={!this.state.layersToShow.mainmap[layer.name]}
          />
        );
      };
      return (
        <div className={"show-layers-content"}>
          <table className={"table table-condensed"}>
            <thead>
              <tr>
                <th className={"smallth"} style={{width: "50%"}}>{trFunctionLayers}</th>
                <th className={"smallth"} style={{width: "25%"}}><FontAwesomeIcon icon={faMagnet} /> {trSnapping}</th>
                <th className={"smallth"} style={{width: "25%"}}>{trOpacity}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={"smalltdwords"}>
                  <input
                    className={"styledCheckbox"}
                    type={"checkbox"}
                    name={OutlineConst + "_mainmap"}
                    id={OutlineConst + "_mainmap"}
                    disabled={true}
                    defaultChecked={true}
                    data-maptype={"mainmap"}
                    data-layername={OutlineConst}
                    onChange={this.onToggleLayerClick}
                  />&nbsp;
                  <label htmlFor={OutlineConst + "_mainmap"}>{trOutline}</label>
                </td>
                <td className={"smalltd"}>
                  <input
                    className={"styledCheckbox"}
                    type={"checkbox"}
                    name={OutlineConst + "_snapping"}
                    id={OutlineConst + "_snapping"}
                    disabled={false}
                    checked={this.state.outline}
                    data-layername={OutlineConst}
                    onChange={this.onToggleSnappingClick}
                  />
                </td>
              </tr>
              {_.map(this.functionLayers, (layer) => {
                return (
                  <tr key={layer.name}>
                    <td className={"smalltdwords"}>{onMain(layer)}&nbsp;<label htmlFor={layer.name + "_mainmap"}>{layer.name}</label></td>
                    <td className={"smalltd"}>{canSnap(layer)}</td>
                    <td className={"smalltd"}>{opacitySlider(layer)}</td>
                  </tr>
                );
              })}
            </tbody>
            <thead>
              <tr>
                <th className={"smallth"} style={{width: "50%"}}>{trBaseLayers}</th>
                <th className={"smallth"} style={{width: "25%"}}><FontAwesomeIcon icon={faMagnet} /> {trSnapping}</th>
                <th className={"smallth"} style={{width: "25%"}}>{trOpacity}</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.baseLayers, (layer) => {
                return (
                  <tr key={layer.name}>
                    <td className={"smalltdwords"}>{onMain(layer)}&nbsp;<label htmlFor={layer.name + "_mainmap"}>{layer.name}</label></td>
                    <td className={"smalltd"}>{canSnap(layer)}</td>
                    <td className={"smalltd"}>{opacitySlider(layer)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <Modal
        className={"showlayers__contents"}
        title={trShowLayers}
        content={Content()}
        modal={this}
      />
    );
  }
}
