import * as React from "react";

import _ from "lodash";

import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import "./FeatureBox.scss";

import { FeatureInfo } from "./FeatureInfo";
import { StratopoMapViewer } from "../stratopomapviewer";
import {
  FeatureIdentifierType,
  LayerIdentifierType,
  PlanViewerLayerProperty,
} from "../planviewer";
import {
  getPlanviewerGeometryFromFeature,
  OL_PLANVIEWER_PROPERTY_KEY,
} from "../gis";
import { EditFeatureToolbar } from "./Toolbar";
import { EditFeatureController } from "../editFeatureController";

interface FeatureBoxProps {
  stratopoMapViewer: StratopoMapViewer;
  layerId: LayerIdentifierType;
  layer: VectorLayer;
  source: VectorSource;
  editable: boolean;
  feature: Feature<Geometry>;
  newFeatureContoller?: EditFeatureController;
  updateFeatureController?: EditFeatureController;
}

interface FeatureBoxState {
  properties: Record<string, any>;
  id?: FeatureIdentifierType;
}

export class FeatureBox extends React.Component<
  FeatureBoxProps,
  FeatureBoxState
> {
  constructor(props: FeatureBoxProps) {
    super(props);
    const property_:
      | PlanViewerLayerProperty
      | undefined = props.feature.getProperties()[OL_PLANVIEWER_PROPERTY_KEY];

    const properties = property_?.properties ?? {};
    const id = property_ && "id" in property_ ? property_.id : undefined;
    this.state = { properties, id };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount(): void {
    this.props.newFeatureContoller?.activateDrawPolygonAndMerge();
    this.props.updateFeatureController?.activateDrawPolygonAndMerge();
    this.props.stratopoMapViewer.renderSidePane();
  }

  changeProperty(key: string, value: any): void {
    this.setState((oldState) => {
      const properties = oldState.properties;
      properties[key] = value;
      return { ...oldState, properties };
    });

    this.props.feature.set(key, value);
    this.props.stratopoMapViewer.style(this.props.feature, { [key]: value });
  }

  onClickDelete(
    event: React.MouseEvent<HTMLButtonElement>,
    feature: Feature<Geometry>
  ): void {
    if (!this.props.editable) {
      return;
    }

    const trAreYouSureYouWantToDelete = this.props.stratopoMapViewer.translate.go(
      "Confirm delete feature"
    );
    if (window.confirm(trAreYouSureYouWantToDelete)) {
      if (this.isFeatureCreated()) {
        this.props.stratopoMapViewer.deleteFeature(feature);
      } else {
        // TODO delete new feature that hasn't been created
      }
    }
  }

  onCancel() {
    this.props.stratopoMapViewer.embeddedMap.activateSelect();
  }

  isFeatureCreated(): boolean {
    return this.props.feature.getId() !== undefined;
  }

  onClickSave(): void {
    if (!this.props.editable) {
      return;
    }
    const layerId = this.props.feature.get("layer_id")
      ? this.props.feature.get("layer_id")
      : this.props.layerId;
    if (this.state.id !== undefined) {
      this.props.stratopoMapViewer.planviewer
        .updateFeature(
          this.props.stratopoMapViewer.identifier,
          layerId,
          this.state.id,
          getPlanviewerGeometryFromFeature(this.props.feature),
          this.state.properties
        )
        .then(() => {
          this.props.feature.setProperties(_.clone(this.state.properties));
          this.props.stratopoMapViewer.setLastSavedGeometry(this.props.feature);
          this.props.stratopoMapViewer.setLastSavedProperties(
            this.props.feature
          );
          this.props.stratopoMapViewer.renderSidePane();
        });
    } else {
      this.props.stratopoMapViewer.createFeature(this.props.feature);
    }
  }

  render(): JSX.Element {
    const stratopoMapViewer = this.props.stratopoMapViewer;
    const translate = stratopoMapViewer.translate;
    const trFeature = translate.go("Feature");

    return (
      <div className={"infobox"}>
        <div className={"propertyList"}>
          <FeatureInfo
            stratopoMapViewer={stratopoMapViewer}
            featureBox={this}
          />

          {this.props.editable && this.props.newFeatureContoller ? (
            <EditFeatureToolbar
              stratopoMapViewer={this.props.stratopoMapViewer}
              feature={this.props.feature}
              controller={this.props.newFeatureContoller}
            />
          ) : undefined}

          {this.props.editable && this.props.updateFeatureController ? (
            <EditFeatureToolbar
              stratopoMapViewer={this.props.stratopoMapViewer}
              feature={this.props.feature}
              controller={this.props.updateFeatureController}
            />
          ) : undefined}

          <div className={"buttons"}>
          <button
            className={"btn-primary btn"}
            onClick={this.onClickSave}
            disabled={!this.props.editable}
          >
            {translate.go("Save")}
          </button>

          <button className={"btn-secondary btn"} onClick={this.onCancel}>
            {translate.go("Cancel")}
          </button>

              <br/><br/>

              <button
                className={"btn-danger btn"}
                disabled={!this.props.editable}
                onClick={(event) => {
                  this.onClickDelete(event, this.props.feature);
                }}
              >
                {translate.go("Delete")} <span className="glyphicon glyphicon-trash"/>
              </button>
          </div>
        </div>
      </div>
    );
  }
}
