import Draw from "ol/interaction/Draw";
import { Coordinate } from "ol/coordinate";

import { EmbeddedMap } from "../embeddedmap";

export const KeyActionLabels = {
  RESET : 'reset',
  CENTER : 'center',
  CENTER_MOUSE : 'center around the mouse',
  PAN_LEFT: 'pan left',
  PAN_RIGHT : 'pan right',
  PAN_UP : 'pan up',
  PAN_DOWN: 'pan down',
  ZOOM_IN : 'Zoom in',
  ZOOM_OUT : 'Zoom out',
}

export const Keys = {
  Escape: 27,
  CursorUp: 38,
  CursorDown: 40,
  CursorLeft: 37,
  CursorRight: 39,
  a: 65,
  d: 68,
  h: 72,
  j: 74,
  k: 75,
  l: 76,
  s: 83,
  w: 87,
  PageUp: 33,
  PageDown: 34,
  Home: 36,
  Plus: 107,
  Minus: 109,
  Space: 32,
}

export const KeyActions = {
    [KeyActionLabels.RESET]: [Keys.Escape],
    [KeyActionLabels.CENTER]: [Keys.Home],
    [KeyActionLabels.CENTER_MOUSE]: [Keys.Space],
    [KeyActionLabels.PAN_LEFT]: [Keys.CursorLeft, Keys.a, Keys.h],
    [KeyActionLabels.PAN_RIGHT]: [Keys.CursorRight, Keys.d, Keys.l],
    [KeyActionLabels.PAN_UP]: [Keys.CursorUp, Keys.PageUp, Keys.w, Keys.k] ,
    [KeyActionLabels.PAN_DOWN]: [Keys.CursorDown, Keys.PageDown, Keys.s, Keys.j],
    [KeyActionLabels.ZOOM_IN]: [Keys.Plus],
    [KeyActionLabels.ZOOM_OUT]: [Keys.Minus],
}

export const registerKeyboardEvents = function (draw: Draw, embeddedMap: EmbeddedMap) {
  // todo findout why the normal keyboard interactions do not work as advertised:
  // https://openlayers.org/en/latest/apidoc/module-ol_interaction_KeyboardPan-KeyboardPan.html
  const map = embeddedMap.getMap();
  const center = map.getView()?.getCenter() as Coordinate;
  let coord = center;
  map.on('pointermove', (evt) => {
    coord = evt.coordinate;
  });
  const keydown = function(evt: KeyboardEvent){
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    const delta = [Math.abs(coord[0] - center[0]), Math.abs(coord[1]- center[1])];
    let found = null;
    for(const [action, keys] of Object.entries(KeyActions)) {
      if (keys.indexOf(charCode) !== -1) {
        found = action;
        break;
      }
    }
    switch (found) {
      case KeyActionLabels.RESET: {
          const tool = embeddedMap.getActiveTool();
          embeddedMap.activateInitialTool(tool);
        }
        break;
    case KeyActionLabels.CENTER:
      embeddedMap.centerMap();
      break;
    case KeyActionLabels.CENTER_MOUSE:
      if (coord) {
        map.getView()?.setCenter(coord);
      }
      break;
    case KeyActionLabels.ZOOM_IN:
      embeddedMap.zoomIn();
      break;
    case KeyActionLabels.ZOOM_OUT:
      embeddedMap.zoomOut();
      break;
    case KeyActionLabels.PAN_UP:
      map.getView()?.adjustCenter([0, +0.5 * delta[0]]);
      break;
    case KeyActionLabels.PAN_DOWN:
      map.getView()?.adjustCenter([0, -0.5 * delta[0]]);
      break;
    case KeyActionLabels.PAN_LEFT:
      map.getView()?.adjustCenter([-0.5 * delta[0], 0]);
      break;
    case KeyActionLabels.PAN_RIGHT:
      map.getView()?.adjustCenter([+0.5 * delta[0], 0]);
      break;
    default:
      console.warn("key pressed:", charCode);
    }
  };
  document.addEventListener('keydown', keydown, false);
}
