import { Circle, Fill, Stroke, Style } from "ol/style";

/** Copied from https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html */
export const olDefaultStyle: Style[] = (() => {
  const fill = new Fill({
    color: "rgba(255,255,255,0.4)",
  });

  const stroke = new Stroke({
    color: "#3399CC",
    width: 1.25,
  });

  return [
    new Style({
      image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 5,
      }),
      fill: fill,
      stroke: stroke,
    }),
  ];
})();
