import * as ol_geom from "ol/geom";
import { LineString, MultiPolygon, Polygon } from "ol/geom";
import * as turf_geom from "@turf/helpers";
import GeometryType from "ol/geom/GeometryType";

export function toTurfGeometry(
  geometry: ol_geom.LineString | ol_geom.Polygon | ol_geom.MultiPolygon
): turf_geom.LineString | turf_geom.Polygon | turf_geom.MultiPolygon {
  if (geometry.getType() === GeometryType.LINE_STRING) {
    return {
      type: "LineString",
      coordinates: (geometry as LineString).getCoordinates(),
    } as turf_geom.LineString;
  } else if (geometry.getType() === GeometryType.POLYGON) {
    return {
      type: "Polygon",
      coordinates: (geometry as Polygon).getCoordinates(),
    } as turf_geom.Polygon;
  } else if (geometry.getType() === GeometryType.MULTI_POLYGON) {
    return {
      type: "MultiPolygon",
      coordinates: (geometry as MultiPolygon).getCoordinates(),
    } as turf_geom.MultiPolygon;
  } else {
    throw new TypeError("Unknown geometry type");
  }
}
