import { IsLayerOutline, PvLayerOutline } from "./outline";
import { IsLayerProxy, PvLayerProxy } from "./proxy";
import { IsLayerWMS, PvLayerWMS } from "./wms";
import { IsLayerWMTS, PvLayerWMTS } from "./wmts";
import { IsLayerWFS, PvLayerWFS } from "./wfs";
import { IsLayerVector, PvLayerVector } from "./vector";
import { IsLayerEmpty, PvLayerEmpty } from "./empty";
import { IsLayerOSM, PvLayerOSM } from "./osm";
import { IsLayerAerial, PvLayerAerial } from "./aerial";
import { IsLayerIMRO, PvLayerIMRO } from "./imro";
import { IsLayerIMROGML, PvLayerIMROGML } from "./imrogml";
import { IsLayerDKK, PvLayerDKK } from "./dkk";
import { IsLayerDKKVector, PvLayerDKKVector } from "./dkk_vector";
import { IsLayerBAG, PvLayerBAG } from "./bag";
import { IsLayerBAGVector, PvLayerBAGVector } from "./bag_vector";
import { PlanViewerLayerDetails } from "../planviewer";

export type PvLayerImplT =
  | PvLayerOutline
  | PvLayerProxy
  | PvLayerWMS
  | PvLayerWMTS
  | PvLayerWFS
  | PvLayerVector
  | PvLayerEmpty
  | PvLayerOSM
  | PvLayerAerial
  | PvLayerDKK
  | PvLayerIMRO
  | PvLayerIMROGML
  | PvLayerDKKVector
  | PvLayerBAG
  | PvLayerBAGVector;

export const castLayer = (layer: PlanViewerLayerDetails): PvLayerImplT => {
  // @TODO: for performance reasons, put this in the most used sequence
  if (IsLayerOutline(layer)) {
    return new PvLayerOutline(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.outline_target_viewer,
      layer.outline_clickable,
      layer.resolved
    );
  }
  if (IsLayerOSM(layer)) {
    return new PvLayerOSM(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerAerial(layer)) {
    return new PvLayerAerial(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerProxy(layer)) {
    return new PvLayerProxy(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.proxy_target_layer,
      layer.resolved
    );
  }
  if (IsLayerWMS(layer)) {
    return new PvLayerWMS(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.wms_url,
      layer.wms_layer_name
    );
  }
  if (IsLayerWMTS(layer)) {
    return new PvLayerWMTS(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.wmts_url,
      layer.wmts_layer_name
    );
  }
  if (IsLayerWFS(layer)) {
    return new PvLayerWFS(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.wfs_url,
      layer.wfs_layer_name
    );
  }
  if (IsLayerVector(layer)) {
    return new PvLayerVector(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url,
      layer.label_layer,
      layer.label_fields,
      layer.as_wms,
      layer.vector_uploadable,
      layer.vector_type,
      layer.vector_drawable
    );
  }
  if (IsLayerEmpty(layer)) {
    return new PvLayerEmpty(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerIMRO(layer)) {
    return new PvLayerIMRO(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerIMROGML(layer)) {
    return new PvLayerIMROGML(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerDKK(layer)) {
    return new PvLayerDKK(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerDKKVector(layer)) {
    return new PvLayerDKKVector(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerBAGVector(layer)) {
    return new PvLayerBAGVector(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  if (IsLayerBAG(layer)) {
    return new PvLayerBAG(
      layer.viewer,
      layer.id,
      layer.type,
      layer.name,
      layer.sort_order,
      layer.base,
      layer.consultable,
      layer.show_layer,
      layer.filter_fields,
      layer.translations,
      layer.created_at,
      layer.updated_at,
      layer.self_url
    );
  }
  console.log(layer);
  throw new Error(`Not implemented for type ${layer.type}`);
};
