/**
 * @see https://openlayers.org/en/latest/examples/measure.html
 */

import { Feature, Map, MapBrowserEvent, Overlay } from "ol";
import VectorSource from "ol/source/Vector";
import Draw from "ol/interaction/Draw";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import { Coordinate } from "ol/coordinate";
import { unByKey } from "ol/Observable";
import { Translate } from "../../core/translate";
import { loadDictionary } from "../translations";
import { EmbeddedMap } from "../embeddedmap";
import "./measure.css";
import { registerKeyboardEvents } from "./keyboard";
import { Interaction } from "ol/interaction";
import OverlayPositioning from "ol/OverlayPositioning";
import GeometryType from "ol/geom/GeometryType";
import {
  reprojectLineStringInPlace3857to28992,
  reprojectPolygonInPlace3857to28992,
} from "../gis";
import { MultiPolygon } from "ol/geom";

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
export const formatLength = function (line: LineString): string {
  let length: number = line.getLength();
  length = Math.round(length * 100) / 100;
  return length.toLocaleString("nl-NL") + " " + "m";
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
export const formatArea = function (polygon: Polygon | MultiPolygon): string {
  let area = polygon.getArea();
  area = Math.round(area * 100) / 100;
  return area.toLocaleString("nl-NL") + " " + "m<sup>2</sup>";
};

export function addMeasureInteraction(
  map: Map,
  source: VectorSource,
  embeddedMap: EmbeddedMap,
  type: string
): Interaction {
  const translate = new Translate(loadDictionary(), "Dutch");
  let geomType: GeometryType;
  switch (type) {
    case "area":
      geomType = GeometryType.POLYGON;
      break;
    case "line":
      geomType = GeometryType.LINE_STRING;
      break;
    default:
      geomType = GeometryType.LINE_STRING;
      break;
  }

  /**
   * Currently drawn feature.
   * @type {import("../src/ol/Feature.js").default}
   */
  let sketch: Feature | null;

  /**
   * The help tooltip element.
   * @type {HTMLElement}
   */
  let helpTooltipElement: HTMLDivElement;

  /**
   * Overlay to show the help messages.
   * @type {Overlay}
   */
  let helpTooltip: Overlay;

  /**
   * The measure tooltip element.
   * @type {HTMLElement}
   */
  let measureTooltipElement: HTMLDivElement | null;

  /**
   * Overlay to show the measurement.
   * @type {Overlay}
   */
  let measureTooltip: Overlay;

  /**
   * Message to show when the user is drawing a polygon.
   * @type {string}
   */
  const continuePolygonMsg: string = translate.go(
    "Click to continue drawing the polygon"
  );

  /**
   * Message to show when the user is drawing a line.
   * @type {string}
   */
  const continueLineMsg: string = translate.go(
    "Click to continue drawing the line"
  );

  /**
   * Handle pointer move.
   * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
   */
  const pointerMoveHandler = function (evt: MapBrowserEvent) {
    if (evt.dragging) {
      return;
    }

    /** @type {string} */
    let helpMsg: string = translate.go("Click to start drawing");

    if (sketch) {
      const geom = sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = continueLineMsg;
      }
    }

    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);

    helpTooltipElement.classList.remove("hidden");
  };

  map.on("pointermove", pointerMoveHandler);

  map.getViewport().addEventListener("mouseout", function () {
    helpTooltipElement.classList.add("hidden");
  });

  // var typeSelect = document.getElementById('type');

  let draw: Draw; // global so we can remove it later

  function addInteraction() {
    draw = new Draw({
      source: source,
      type: geomType,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
    map.addInteraction(draw);

    createMeasureTooltip();
    createHelpTooltip();

    let listener: any;
    draw.on("drawstart", function (evt) {
      // set sketch
      sketch = evt.feature;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      let tooltipCoord: Coordinate = (evt as any).coordinate;

      if (sketch) {
        listener = sketch.getGeometry()?.on("change", function (evt) {
          const geom = evt.target;
          let output;
          if (geom instanceof Polygon) {
            const geomClone = geom.clone() as Polygon;
            reprojectPolygonInPlace3857to28992(geomClone);
            output = formatArea(geomClone);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            const geomClone = geom.clone() as LineString;
            reprojectLineStringInPlace3857to28992(geomClone);
            output = formatLength(geomClone);
            tooltipCoord = geom.getLastCoordinate();
          }
          if (measureTooltipElement && typeof output === "string") {
            measureTooltipElement.innerHTML = output;
          }
          measureTooltip.setPosition(tooltipCoord);
        });
      }
    });

    draw.on("drawend", function () {
      if (measureTooltipElement) {
        measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      }
      measureTooltip.setOffset([0, -7]);
      // unset sketch
      sketch = null;
      // unset tooltip so that a new one can be created
      measureTooltipElement = null;
      createMeasureTooltip();
      unByKey(listener);
    });
    registerKeyboardEvents(draw, embeddedMap);

    return draw;
  }

  /**
   * Creates a new help tooltip
   */
  function createHelpTooltip() {
    helpTooltipElement?.parentNode?.removeChild(helpTooltipElement);
    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = "ol-tooltip hidden";
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    });
    map.addOverlay(helpTooltip);
  }

  /**
   * Creates a new measure tooltip
   */
  function createMeasureTooltip() {
    measureTooltipElement?.parentNode?.removeChild(measureTooltipElement);

    measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER,
    });
    map.addOverlay(measureTooltip);
  }

  /**
   * Let user change the geometry type.
   */
  // typeSelect.onchange = function () {
  //   map.removeInteraction(draw);
  //   addInteraction();
  // };

  return addInteraction();
}
