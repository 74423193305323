import { Feature, Map } from "ol";
import { Vector as VectorSource } from "ol/source";
import Draw from "ol/interaction/Draw";
import GeometryType from "ol/geom/GeometryType";
import { Interaction } from "ol/interaction";

import { EmbeddedMap } from "../embeddedmap";
import { registerKeyboardEvents } from "./keyboard";

export function addDrawPolygonInteraction(
  map: Map,
  source: VectorSource,
  embeddedMap: EmbeddedMap,
  onFeatureAdded?: (feature: Feature) => void
): Interaction {
  const interaction = new Draw({
    source: source,
    type: GeometryType.POLYGON,
  });
  registerKeyboardEvents(interaction, embeddedMap);
  map.addInteraction(interaction);

  interaction.on("drawend", (event) => {
    onFeatureAdded?.(event.feature);
  });

  return interaction;
}
