import * as React from "react";

import _ from "lodash";

import {
  faArrowsAlt,
  faAsterisk,
  faBook,
  faCrosshairs,
  faDrawPolygon,
  faGripLines,
  faKeyboard,
  faLayerGroup,
  faMagnet,
  faMousePointer,
  faObjectUngroup,
  faPrint,
  faRuler,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faTrash,
  faUndo,
  faUpload,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./RenderTutorial.scss";
import { Translate } from "../../core/translate";
import { KeyActions } from "../interactions/keyboard";

interface ITutorial {
  title: string;
  text: string;
  icon: IconDefinition;
  seeAlso: string[];
}

const keyboardTutorial = (translate: Translate): string => {
  let out = "";
  for (const [action, keys] of Object.entries(KeyActions)) {
    const trAction = translate.go(action);
    const withKeys = [];
    for (const keyCode of keys) {
      withKeys.push(translate.go(`KEY_${keyCode}`));
    }
    out += `\n* ${trAction}: ${withKeys.join(", ")}`;
  }
  return out;
};

const Tutorial = (translate: Translate): Record<string, ITutorial> => {
  return {
    keyboard: {
      title: "Keyboard",
      text:
        "Als de kaart module in de de 'meet' of 'teken' modus is, kan er genavigeerd worden doormiddel van de toetsen." +
        keyboardTutorial(translate),
      icon: faKeyboard,
      seeAlso: [
        "zoom_in",
        "zoom_out",
        "center",
        "pan",
        "ruler",
        "area",
        "polygon",
        "griplines",
        "ungroup",
      ],
    },
    zoom_in: {
      title: "Zoom in",
      text:
        "Zoom in, zodat een kleiner gebied in de kaart wordt weergegeven. Er worden meer details zichtbaar. De minimap verandert automatisch mee.",
      icon: faSearchPlus,
      seeAlso: ["zoom_in", "zoom_out", "minimap", "keyboard"],
    },
    zoom_out: {
      title: "Zoom out",
      text:
        "Zoom uit, zodat een groter gebied in de kaart wordt weergegeven. Er worden echter minder details zichtbaar. De minimap verandert automatisch mee.",
      icon: faSearchMinus,
      seeAlso: ["zoom_in", "zoom_out", "minimap", "keyboard"],
    },
    center: {
      title: "Center Map",
      text:
        "Centreert en her-orienteert de kaart rondom het grensgebied. De minimap verandert automatisch mee",
      icon: faCrosshairs,
      seeAlso: ["pan", "center", "minimap", "search", "keyboard"],
    },
    tutorial: {
      title: "Tutorial",
      text: "Deze handleiding.",
      icon: faBook,
      seeAlso: [],
    },
    search: {
      title: "Search",
      text: "Navigeert naar een adres op de kaart",
      icon: faSearch,
      seeAlso: ["center", "search", "pan", "minimap"],
    },
    pan: {
      title: "Pan",
      text:
        "Verschuift de kaart waarbij de zoom factor gelijkblijft. Door tegelijkertijd de shift-toets in te houden kan de orientatie veranderd worden. De minimap verandert automatisch mee.",
      icon: faArrowsAlt,
      seeAlso: ["center", "search"],
    },
    select: {
      title: "Select",
      text: "Selecteert een feature op de kaart.",
      icon: faMousePointer,
      seeAlso: ["center", "layers", "info"],
    },
    ruler: {
      title: "Measure line",
      text:
        "Meet een afstand over een lijn. De snapping mode heeft invloed op het plaatsen van de punten.",
      icon: faRuler,
      seeAlso: ["ruler", "area", "snap", "keyboard"],
    },
    area: {
      title: "Measure area",
      text:
        "Berekent de oppervlakte van een polygoon. Er zijn tenminste 3 punten nodig. De snapping mode heeft invloed op het plaatsen van de punten.",
      icon: faRulerCombined,
      seeAlso: ["ruler", "area", "snap", "keyboard"],
    },
    layers: {
      title: "Show Layers",
      text: "Geeft de aanwezige lagen weer.",
      icon: faLayerGroup,
      seeAlso: ["minimap", "layers", "info"],
    },
    griplines: {
      title: "Outline Multiselect Line",
      text:
        "Teken of wijzig een polygon door een lijn te tekenen en zo de onderliggende percelen. De snapping mode heeft invloed op het plaatsen van de punten. Als het grensgebied wordt bewerkt, zijn er nog 2 extra buttons: Een met een '+' icoon (om de selectie uit te breiden) en een met een '-' icoon (om de selectie te verkleinen). Er zijn 3 varianten: Union (+), Intersect (-).",
      icon: faGripLines,
      seeAlso: [
        "snap",
        "undo",
        "save",
        "upload",
        "polygon",
        "griplines",
        "ungroup",
        "keyboard",
      ],
    },
    ungroup: {
      title: "Outline Multiselect Polygon",
      text:
        "Teken of wijzig een polygon door een vlak te tekenen en zo de onderliggende percelen. De snapping mode heeft invloed op het plaatsen van de punten. Als het grensgebied wordt bewerkt, zijn er nog 2 extra buttons: Een met een '+' icoon (om de selectie uit te breiden) en een met een '-' icoon (om de selectie te verkleinen). Er zijn 3 varianten: Union (+), Intersect (-).",
      icon: faObjectUngroup,
      seeAlso: [
        "snap",
        "undo",
        "save",
        "upload",
        "polygon",
        "griplines",
        "ungroup",
        "keyboard",
      ],
    },
    polygon: {
      title: "Draw Polygon",
      text:
        "Teken of wijzig een polygoon. De snapping mode heeft invloed op het plaatsen van de punten. Als het grensgebied wordt bewerkt, zijn er nog 2 extra buttons: Een met een '+' icoon (om de selectie uit te breiden) en een met een '-' icoon (om de selectie te verkleinen). Er zijn 3 varianten: Union (+), Intersect (-), en wijzig (o).",
      icon: faDrawPolygon,
      seeAlso: [
        "snap",
        "undo",
        "save",
        "upload",
        "polygon",
        "griplines",
        "ungroup",
        "keyboard",
      ],
    },
    upload: {
      title: "Upload file",
      text: `Upload een bestand voor grensgebieden of functiegebieden.
Voor grensgebieden wordt het bestaande grensgebied vervangen door alle features van alle lagen in het bestand.
Voor functiegebieden worden de bestaande functie gebieden vervangen door alle features van alle lagen in het bestand.

Als er eigenschappen aan features zijn toe gekend, worden deze als read-only meegenomen.
De GrexManager is op dit moment zo in gesteld dat alleen de eigenschap 'Functie' is middels een drop down wijzigbaar is.

Het bestand moet in het 28992 coordinaten systeem opgemaakt zijn.

De volgende bestand types zijn toegestaan:

- GeoJson ('.json', '.geojson')
- GeoPackage (.gpkg)
- Shapefile (.shp, .zip)
  De bestanden (.shp, shx, .dbf, cpg, prj) in het zip bestanden dienen dezelfde basis naam hebben.
  Als alleen het .shp bestand beschikbaar is, kunnen de volgende zaken niet goed verwerkt worden:
  - Het coordinaten systeem is niet beschikbaar.
  - De eigenschappen per feature zijn niet beschikbaar.`,
      icon: faUpload,
      seeAlso: ["polygon", "undo", "save", "upload"],
    },
    delete: {
      title: "Delete",
      text: "Verwijdert een feature",
      icon: faTrash,
      seeAlso: ["polygon", "select", "delete", "undo"],
    },
    undo: {
      title: "Undo",
      text: "Undo",
      icon: faUndo,
      seeAlso: ["polygon", "select", "delete", "undo", "save"],
    },
    save: {
      title: "Save",
      text: "Save",
      icon: faSave,
      seeAlso: ["polygon", "select", "delete", "undo", "save"],
    },
    print: {
      title: "Print",
      text: "Generate PDF of the map",
      icon: faPrint,
      seeAlso: [],
    },
    snap: {
      title: "Snap",
      text:
        "Als de snap modus aan staat, zal een het punt gekozen worden na bij een lijnstuk van de kadastrale kaart. In de normale modus, zal het exacte punt genomen worden.",
      icon: faMagnet,
      seeAlso: ["ruler", "area", "polygon", "snap"],
    },
    notes: {
      title: "Overige opmerkingen",
      text:
        "Na aanpassing deelgebied bij de algemene gegevens wordt de kaart niet verschoven. Dit gebeurt alleen als de daadwerkelijke geometrie wordt aangepast.",
      icon: faAsterisk,
      seeAlso: ["save"],
    },
  };
};

interface RenderTutorialProps {
  translate: Translate;
}

export class RenderTutorial extends React.Component<
  RenderTutorialProps,
  unknown
> {
  constructor(props: RenderTutorialProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const translate = this.props.translate;
    const trSeeAlso = translate.go("See also");
    const trTutorial = translate.go("Tutorial");
    const trPreamble = translate.go("GrexmanagerMapviewerTutorialPreamble");
    const tutorial = Tutorial(translate);
    return (
      <div id="tutorial">
        {_.map(tutorial, (topic, topicKey) => {
          return (
            <div id={topicKey} key={topicKey} className={"whiteBackground"}>
              <span>
                <h2 className={"tutorialTopicTitle h4"} id={topic.title}>
                  <span className={"icon"}>
                    <FontAwesomeIcon icon={topic.icon} />
                  </span>
                  &nbsp;{" "}
                  {this.props.translate.go(topic.title) || " " || topic.title}
                </h2>
              </span>
              <div className={"panel panel-default"}>
                <div className={"panel-body"}>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {topic.text.trim()}
                  </div>
                </div>
              </div>
              <div>
                <div className={"tutorialSeeAlso"}>
                  <div className={"tutorialSeeAlsoText"}>{trSeeAlso}:</div>
                  <ul>
                    {_.map(topic.seeAlso, (see) => {
                      if (see in tutorial) {
                        const reference = tutorial[see];
                        if (reference.title !== topic.title) {
                          return (
                            <li key={topic.title + "-" + see} data-see={see}>
                              <a href={"#" + see}>
                                <span className={"icon"}>
                                  <FontAwesomeIcon
                                    icon={reference.icon}
                                    className={"icon"}
                                  />
                                </span>
                                &nbsp;{" "}
                                {this.props.translate.go(reference.title) ||
                                  reference.title}
                              </a>
                            </li>
                          );
                        }
                      }
                    })}
                  </ul>
                </div>
              </div>
              <br />
            </div>
          );
        })}
      </div>
    );
  }
}
