import Select, { SelectEvent } from "ol/interaction/Select";
import { altKeyOnly, click, pointerMove } from "ol/events/condition";
import { Map } from "ol";
import VectorLayer from "ol/layer/Vector";
import forEach from "lodash/forEach";

import { EmbeddedMap } from "../embeddedmap";

/**
 *
 * @param map {ol.Map}
 * @param layer {ol.layer.VectorLayer}
 * @returns {ol.interaction.Select}
 */
export const addDeleteInteraction = (
  map: Map,
  layer: VectorLayer,
  _embeddedMap: EmbeddedMap
): Select => {
  let select: Select; // ref to currently selected interaction

  // select interaction working on "singleclick"
  const selectSingleClick = new Select({
    layers: [layer],
    multi: false,
  });

  // select interaction working on "click"
  const selectClick = new Select({
    condition: click,
  });

  // select interaction working on "pointermove"
  const selectPointerMove = new Select({
    condition: pointerMove,
  });

  const selectAltClick = new Select({
    condition: (mapBrowserEvent) => {
      return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
    },
  });

  const selectElement = { value: "singleclick" };

  const changeInteraction = () => {
    const value = selectElement.value;
    if (value == "singleclick") {
      select = selectSingleClick;
    } else if (value == "click") {
      select = selectClick;
    } else if (value == "pointermove") {
      select = selectPointerMove;
    } else if (value == "altclick") {
      select = selectAltClick;
    }

    /**
     * @param event {SelectEvent}
     */
    const onSelectHandler = (event: SelectEvent) => {
      /** @type Feature<Geometry>[] */
      const features = event.selected;

      forEach(features, (feature) => {
        layer.getSource().removeFeature(feature);
      });
    };

    if (select) {
      select.on("select", onSelectHandler);
    }
  };

  changeInteraction();

  map.addInteraction(select!);

  return select!;
};
