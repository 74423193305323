import { LanguageKeyType, PhraseKeyType } from "../core/translate";
import { KeyActionLabels, Keys } from "./interactions/keyboard";

const GrexmanagerMapviewerTutorialPreambleDutch = `Beknopte handleiding GrexManager Kaart module

`

const register = (
  dict: Record<PhraseKeyType, Record<LanguageKeyType, string>>,
  key: string,
  english: string | null,
  dutch: string
) => {
  dict[key] = {} as Record<LanguageKeyType, string>;

  dict[key]["English"] = english || key;
  dict[key]["Dutch"] = dutch;
};

export const loadDictionary = (): Record<
  PhraseKeyType,
  Record<LanguageKeyType, string>
> => {
  const dict = {} as Record<PhraseKeyType, Record<LanguageKeyType, string>>;

  register(
    dict,
    "28992 Hint",
    "The coordinates of the file should be in the 28992 coordinate system.",
    "Het Rijksdriehoekstelsel wordt als coördinatensysteem gehanteerd (EPSG: 28992)."
  );

  register(
      dict,
      "28992 No check",
      "File must be explicity EPSG:28992",
      "Bestand moet expliciet het Rijksdriehoekstelsel (EPSG:28992) hebben"
  );

   register(
    dict,
    "Function Hint",
    "Make sure the column name is 'Functie' for recognizing values in a functiegebieden layer.",
    "Voor het herkennen van waarden bij functiegebieden hanteer de kolomnaam 'Functie'."
  );
  register(dict, "Activate minimap", null, "Activeer minikaart");
  register(dict, "Admin menu", null, "Admin menu");
  register(
    dict,
    "Cannot read file or does not contain usable layers. Make sure it's a valid Shapefile/GeoPackage/GeoJSON using SRID 28992.",
    null,
    "Het bestand kan niet worden uitgelezen of bevat geen bruikbare lagen. Zorg ervoor dat het een geldige ESRI Shapefile/GeoPackage/GeoJSON is met SRID 28892."
  );
  register(dict, "Center Map", null, "Centreer kaart");
  register(
    dict,
    "Click to continue drawing the line",
    null,
    "Click om verder te gaan met het tekenen van de lijn"
  );
  register(
    dict,
    "Click to continue drawing the polygon",
    null,
    "Click om verder te gaan met het tekenen van de polygoon"
  );
  register(
    dict,
    "Click to start drawing",
    null,
    "Click om te starten met tekenen"
  );
  register(
    dict,
    "Confirm delete feature",
    "Are you sure that you want to delete this feature?",
    "Weet u zeker dat u deze feature wilt verwijderen?"
  );
  register(dict, "Customers", null, "Klanten");
  register(dict, "Delete Outline", null, "Verwijder");
  register(dict, "Delete", null, "Verwijderen");
  register(
    dict,
    "Outline Multiselect Line",
    null,
    "Grensgebied multiselectie via lijnen bewerken"
  );
  register(
    dict,
    "Outline Multiselect Line Add",
    null,
    "Grensgebied multiselectie via lijnen en voeg samen"
  );
  register(
    dict,
    "Outline Multiselect Line Subtract",
    null,
    "Grensgebied multiselectie via lijnen en verwijder"
  );
  register(
    dict,
    "Outline Multiselect Polygon Add",
    null,
    "Grensgebied multiselectie via polygonen en voeg samen"
  );
  register(
    dict,
    "Outline Multiselect Polygon",
    null,
    "Grensgebied multiselectie via polygonen bewerken"
  );
  register(
    dict,
    "Outline Multiselect Polygon Subtract",
    null,
    "Grensgebied multiselectie via polygonen en verwijder"
  );
  register(
    dict,
    "Draw Polygon",
    null,
    "Polygoon tekenen"
  );
  register(
    dict,
    "Draw Polygon And Merge",
    null,
    "Vrije hand polygoon toevoegen"
  );
  register(
    dict,
    "Draw Polygon And Subtract",
    null,
    "Vrije hand polygoon verwijderen"
  );
  register(dict, "Draw Polygon", null, "Polygoon tekenen");
  register(dict, "Draw", null, "Tekenen");
  register(dict, "Ended", "Ending", "Gereed");
  register(dict, "Editable", null, "Wijzigbaar");
  register(dict, "Feature", null, "Editor: Functiegebieden");
  register(dict, "For feature", null, "functiegebied");
  register(dict, "For outline", null, "tbv grens");
  register(dict, "Invalid extension", null, "Ongeldige bestands extensie");
  register(dict, "Invalid layer", null, "Ongeldige laag");
  register(dict, "Layer", null, "Laag");
  register(dict, "Layer type", null, "Laag type");
  register(dict, "Layers", null, "Lagen");
  register(dict, "Base Layers", null, "Basislagen");
  register(dict, "Function Layers", null, "Functielagen");
  register(dict, "Keyboard", null, "Toetsenbord");
  register(dict, "Measure area", null, "Meet oppervlakte");
  register(dict, "Measure line", null, "Meet afstand");
  register(dict, "Menu", null, "Menu");
  register(dict, "Modify Polygon", null, "Polygoon wijzigen");
  register(dict, "No SLD available", null, "Geen SLD beschikbaar");
  register(dict, "Ok", null, "Ok");
  register(dict, "On Mainmap", null, "Kaartlaag actief");
  register(dict, "On Minimap", null, "Op minimap");
  register(dict, "Opacity", null, "Transparantie");
  register(dict, "Parcels", null, "Percelen");
  register(dict, "Outline", null, "Grens");
  register(dict, "Pan", null, "Verschuif (pan)");
  register(dict, "Print", null, "Print");
  register(dict, "Redo Edit Outline", null, "Opnieuw doen");
  register(dict, "Readonly", null, "Weergave");
  register(dict, "Scope", null, "Scope");
  register(dict, "Status", null, "Status");
  register(dict, "Save Outline", null, "Opslaan");
  register(dict, "Save", null, "Opslaan");
  register(dict, "Cancel", null, "Annuleren");
  register(dict, "Search", null, "Zoeken");
  register(dict, "See also", null, "Zie ook");
  register(dict, "Select File", null, "Selecteer een bestand in Shapefile, GeoPackage of GeoJSON formaat");
  register(dict, "Select file first", null, "Selecteer eerst een bestand");
  register(
    dict,
    "Select show layers",
    "Select what layers to show",
    "Lagenpaneel"
  );
  register(dict, "Select", null, "Selecteer");
  register(dict, "Show Layer Info", null, "Laag informatie");
  register(dict, "Show Layers", null, "Laag selectie");
  register(dict, "Show Layers", null, "Laag selectie");
  register(dict, "Show Upload", null, "Upload File");
  register(dict, "Snap active", "Snap", "Snapping");
  register(dict, "Snap", null, "Snap");
  register(dict, "Start Upload", null, "Start upload");
  register(dict, "Started", "Starting", "Gestart");
  register(dict, "Surface area", "Surface area", "Oppervlakte");
  register(dict, "Tutorial", null, "Handleiding");
  register(dict, "Reference", null, "Referentie");
  register(dict, "Undo Edit Outline", null, "Ongedaan maken");
  register(dict, "Undo", null, "Ongedaan maken");
  register(dict, "Upload file", null, "Bestand uploaden");
  register(dict, "Zoom in", null, "Zoom in");
  register(dict, "Zoom out", null, "Zoom uit");
  register(dict, "Zoom In", null, "Zoom in");
  register(dict, "Zoom Out", null, "Zoom uit");

  register(dict, "Create Feature", null, "Nieuw Functiegebied"); // prettier-ignore
  register(dict, "Multiselect Line Add", null, "Voeg percelen toe via lijn"); // prettier-ignore
  register(dict, "Multiselect Line Subtract", null, "Verwijder percelen via lijn"); // prettier-ignore
  register(dict, "Multiselect Polygon Add", null, "Voeg percelen toe via polygoon"); // prettier-ignore
  register(dict, "Multiselect Polygon Subtract", null, "Verwijder percelen via polygoon"); // prettier-ignore

  register(dict, "Customer", null, "Klant");
  register(dict, "Customer list", null, "Klant");
  register(dict, "Add a customer", null, "Klant toevoegen");
  register(dict, "Remove a customer", null, "Klant verwijderen");
  register(dict, "Are you sure you want to delete this customer?", null, "Weet u zeker dat u deze klant wilt verwijderen?");
  register(dict, "Not saved, check the input fields", null, "Niet opgeslagen, controleer de invoer");
  register(dict, "Name", null, "Naam");
  register(dict, "Base settings", null, "Basis gegevens");
  register(dict, "SLD File", null, "SLD Bestand");
  register(dict, "Go back", null, "Terug");
  register(dict, "API Key", null, "API Key");
  register(dict, "API Key ID", null, "API Key ID");
  register(dict, "New api_id and api_key", null, "Er is een nieuwe api_id en api_key paar. Deze worden in de volgende 2 popups getoont. Daarna nooit mee. De applicatie zal niet werken tot dat deze beiden in GrexManager backend zijn geactiveerd.");
  register(dict, "Confirm new api_key generation", null, "Weet u zeker dat u een nieuwe api_key wilt genereren?");
  register(dict, "api_key generated", null, "Totdat de nieuwe api_key in de backend is geactiveerd zal de applicatie niet meer werken.");
  register(dict, "Basemaps", null, "Achtergrondkaarten");
  register(dict, "Type", null, "Type");
  register(dict, "Show Layer", null, "Toon Laag");
  register(dict, "Use Transparancy", null, "Gebruik Transparantie");
  register(dict, "Wfs Url", null, "Wfs Url");
  register(dict, "Wfs Layer Name", null, "Wfs Laag Naam");
  register(dict, "Wms Url", null, "Wms Url");
  register(dict, "Wms Layer Name", null, "Wms Laag Naam");
  register(dict, "Wmts Url", null, "Wmts Url");
  register(dict, "Wmts Layer Name", null, "Wmts Laag Naam");
  register(dict, "Sort Order", null, "Sorteervolgorde");
  register(dict, "Add Basemap", null, "Nieuwe Achtergrondkaart");
  register(dict, "Please enter a unique name.", null, "Voer een unieke naam in.");
  register(dict, "Another basemap with that name already exists.", null, "Er bestaat al een achtergrondkaart met die naam.");

  register(dict, "Oauth", null, "Oauth");
  register(dict, "ClientID", null, "ClientID");
  register(dict, "Secret", null, "Secret");
  register(dict, "Authorization URL", null, "Authorization URL");
  register(dict, "Access Token URL", null, "Access token URL");
  register(dict, "Resource URL", null, "Resource URL");
  register(dict, "Generate new api_key", null, "Genereer nieuwe api_key");
  register(dict, "Viewers list", null, "Lijst met viewers");
  register(dict, "Identifier", null, "Identificatie");
  register(dict, "Map", null, "Kaart");
  register(dict, "Viewer", null, "Kaart viewer");
  register(dict, "Edit outline", null, "Grens bewerken");
  register(dict, "Created at", null, "Gemaakt op");
  register(dict, "Updated at", null, "Gewijzigd op");
  register(dict, "Close", null, "Sluiten");
  register(dict, "GrexmanagerMapviewerTutorialPreamble", "GrexManager tutorial", GrexmanagerMapviewerTutorialPreambleDutch);

  register(dict, `KEY_${Keys.Escape}`, 'ESC', 'ESC');
  register(dict, `KEY_${Keys.CursorUp}`, "UP", 'UP');
  register(dict, `KEY_${Keys.CursorDown}`, 'DOWN', 'DOWN');
  register(dict, `KEY_${Keys.CursorLeft}`, 'LEFT', 'LEFT');
  register(dict, `KEY_${Keys.CursorRight}`, "RIGHT", 'RIGHT');
  register(dict, `KEY_${Keys.a}`, 'a', 'a');
  register(dict, `KEY_${Keys.d}`, 'd', 'd');
  register(dict, `KEY_${Keys.h}`, 'h', 'h');
  register(dict, `KEY_${Keys.j}`, 'j', 'j');
  register(dict, `KEY_${Keys.k}`, 'k', 'k');
  register(dict, `KEY_${Keys.l}`, 'l', 'l');
  register(dict, `KEY_${Keys.s}`, 's', 's');
  register(dict, `KEY_${Keys.w}`, 'w', 'w');
  register(dict, `KEY_${Keys.PageUp}`, "PAGE UP", 'PAGE UP');
  register(dict, `KEY_${Keys.PageDown}`, "PAGE DOWN", 'PAGE DOWN');
  register(dict, `KEY_${Keys.Home}`, "HOME", 'HOME');
  register(dict, `KEY_${Keys.Plus}`, "+", '+');
  register(dict, `KEY_${Keys.Minus}`, '-1', '-');
  register(dict, `KEY_${Keys.Space}`, 'SPACE', 'SPATIE');

  register(dict, KeyActionLabels.RESET, 'Reset action', 'Herstart de actie');
  register(dict, KeyActionLabels.CENTER, 'Center', 'Center');
  register(dict, KeyActionLabels.CENTER_MOUSE, 'Center around the mouse', 'Centreer rond de muis positie');
  register(dict, KeyActionLabels.PAN_LEFT, 'Pan left', 'Pan links');
  register(dict, KeyActionLabels.PAN_RIGHT, 'Pan right', 'Pan rechts');
  register(dict, KeyActionLabels.PAN_UP, 'Pan up', 'Pan omhoog');
  register(dict, KeyActionLabels.PAN_DOWN, 'Pan down', 'Pan omlaag');
  register(dict, KeyActionLabels.ZOOM_IN, 'Zoom in', 'Zoom in');
  register(dict, KeyActionLabels.ZOOM_OUT, 'Zoom out', 'Zoom uit');

  return dict;
};
