import VectorSource from "ol/source/Vector";
import { LineString, MultiPolygon, Polygon } from "ol/geom";
import { Feature } from "ol";
import booleanIntersects from "@turf/boolean-intersects";
import { toTurfGeometry } from "./juggle";

export function getAllIntersectingFeatures(
  sources: VectorSource<Polygon | MultiPolygon>[],
  intersectsWith: LineString | Polygon | MultiPolygon
) {
  const features: Feature<Polygon | MultiPolygon>[] = [];

  for (const source of sources) {
    const filtered = source.getFeatures().filter((feature) => {
      const geometry = feature.getGeometry();

      if (geometry) {
        return booleanIntersects(
          toTurfGeometry(intersectsWith),
          toTurfGeometry(geometry)
        );
      }
    });

    features.push(...filtered);
  }

  return features;
}
